import { colors } from "styles/theme";

const smsOrEmailSettingsRoles = ["ADMIN", "MANAGER", "WEBSITE"];

export const excludedNotificationTabs = [
  "event_proposal_notification_settings",
  "email_ha_invoices_notification_settings",
  "anniversary_occasion_notification_settings",
  "birthday_occasion_notification_settings",
];

export const RestrictedCatalogPageFilters = (Localise, messages) => [
  {
    label: Localise(messages, "Enabled"),
    value: "enabled",
  },
  {
    label: Localise(messages, "Not Enabled"),
    value: "notEnabled",
  },
];

export const getColorCodes = (input) => {
  let bgColor = colors.ftd,
    textColor = colors.secondary;

  if (input === "local") {
    bgColor = "#3A7D8D";
  } else if (input === "reserved") {
    bgColor = colors.highlighter;
  } else if (input === "inactive") {
    bgColor = "#F2F2F2";
    textColor = colors.primary;
  }

  return {
    bgColor,
    textColor,
  };
};

export const canEditEmailORSMSSettings = (roles = []) => {
  return roles.some((role) => smsOrEmailSettingsRoles?.includes(role));
};

export const customFindHelpDocNames = {
  ["Email House Account Invoice"]: "Email House Account Invoice",
};
