import React, { useContext, useEffect } from "react";
import { View, Platform, Text, Image, PermissionsAndroid } from "react-native";
import * as ImagePicker from "react-native-image-picker";
import { Button } from "react-native-elements";
import { fonts, theme } from "styles/theme";
import { ToasterHandler } from "components/elements";
import tw from "tailwind-rn";
import * as DocumentPicker from "expo-document-picker";
import I18NContext from "library/contexts/i18N";
import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { selectRecordData } from "library/sagas/ongoing/current-orders/selector";
import { AppSettingsContext } from "library/contexts/appSettings";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { scrollToEndOfPage } from "library/utils/common";
import Environment from "library/utils/environment";
import {
  imageSize,
  imagePickerOptions,
  cameraOptions,
  actionSheetOptions,
} from "./helper";

// eslint-disable-next-line react/display-name
const UploadImageButton = React.memo(
  ({
    index,
    recordId,
    scrollViewRef,
    tabDetailScrollRef,
    selectedActionRef,
    triggerAction,
  }) => {
    const { permissions } = useContext(AppSettingsContext);
    const orderDetailResponse = useSelector(selectRecordData);
    const orderDetails = orderDetailResponse.orderItems[index];
    const { messages: locMessages, Localise } = useContext(I18NContext);

    const {
      orderItemId,
      status: orderStatus = "",
      deliveryInfo: { deliveryMethod = "" } = {},
      designedImageUrls: uploaddesignedImageUrls = [],
    } = orderDetails;

    const designedImageDefaultValue = uploaddesignedImageUrls[0] || null;
    const [standardDesignImageResponse, setStandardDesignImageResponse] =
      useStateIfMounted(designedImageDefaultValue);

    const hideUploadImageButton = [
      "FORFEITED",
      "CANCELLED",
      "REJECTED",
      "NEW",
    ].includes(orderStatus);

    const fillerMemberCode = get(
      orderDetails,
      "receivingMember.memberCode",
      ""
    );
    const showUploadDesignedImage = Environment.get(
      "SHOW_UPLOAD_DESIGNED_IMAGE",
      false
    );
    const hasDesignCenter =
      hasDesignCenterFeature(permissions, fillerMemberCode) &&
      showUploadDesignedImage;

    const hasDesignedImage = !isEmpty(standardDesignImageResponse);
    const designedImageLabel = !hideUploadImageButton || hasDesignedImage;
    const showUploadButton =
      hasDesignCenter &&
      !hideUploadImageButton &&
      (orderStatus !== "DELIVERED" || !hasDesignedImage);

    const { showActionSheetWithOptions } = useActionSheet();

    const updateSheetOptions = (setter) => {
      showActionSheetWithOptions(actionSheetOptions, (buttonIndex) => {
        if (buttonIndex === 0) {
          onCameraPress(setter);
        } else if (buttonIndex === 1) {
          onImageLibraryPress(setter);
        }
      });
    };

    const uploadDesignedImage = (base64Image, setter) => {
      const reqObj = {
        imageType: "DESIGNED",
        deliveryMethod,
        isReplaceImage: hasDesignedImage,
        images: [
          {
            imageContentType: "image/jpeg",
            imageName: "designedimage",
            imageContent: base64Image,
          },
        ],
      };

      request("upload-designed-image", {
        orderId: orderItemId,
        shopCode: fillerMemberCode,
        deliveryMethod,
        body: reqObj,
      })
        .then(() => {
          const successMessage = hasDesignedImage
            ? Localise(locMessages, "Designed Image was replaced successfully")
            : Localise(locMessages, "Designed Image was uploaded successfully");
          ToasterHandler("success", successMessage);
          triggerAction({ action: "upload-designed-image", recordId });
        })
        .catch(() => {
          setter(designedImageDefaultValue);
          ToasterHandler(
            "error",
            Localise(locMessages, "Upload failed. Please try again.")
          );
        });
    };

    const handleImagePicker = (response, setter) => {
      if (response.didCancel) {
        console.log("User cancelled image picker");
      } else if (response.error) {
        console.log("ImagePicker Error: ", response.error);
      } else {
        const uri = response.assets[0].uri; // Get the URI of the selected image
        const base64Image = response.assets[0].base64;
        setter(uri);
        uploadDesignedImage(base64Image, setter);
      }
    };

    const onImageLibraryPress = (setter) => {
      ImagePicker.launchImageLibrary(imagePickerOptions, (response) =>
        handleImagePicker(response, setter)
      );
    };

    const onCameraPress = async (setter) => {
      if (Platform.OS === "ios") {
        launchCamera(setter);
      } else if (Platform.OS === "android") {
        try {
          const permissionGranted = await PermissionsAndroid.check(
            PermissionsAndroid.PERMISSIONS.CAMERA
          );

          if (permissionGranted) {
            launchCamera(setter);
          } else {
            const result = await PermissionsAndroid.request(
              PermissionsAndroid.PERMISSIONS.CAMERA,
              {
                title: Localise(locMessages, "Camera Permission Required"),
                message: Localise(
                  locMessages,
                  "Mercury HQ would like to use your camera."
                ),
                buttonPositive: Localise(locMessages, "OK"),
              }
            );

            if (result === PermissionsAndroid.RESULTS.GRANTED) {
              launchCamera(setter);
            } else {
              ToasterHandler(
                "error",
                Localise(
                  locMessages,
                  "Camera permission not granted, please give permission."
                )
              );
            }
          }
        } catch (err) {
          ToasterHandler(
            "error",
            Localise(
              locMessages,
              "An error occurred while capturing the design image please try again."
            )
          );
        }
      }
    };

    const launchCamera = (setter) => {
      ImagePicker.launchCamera(cameraOptions, (response) => {
        handleImagePicker(response, setter);
      });
    };

    const scrollRefs = [scrollViewRef, tabDetailScrollRef];

    useEffect(() => {
      // scrolling to end of the page after uploading designed image
      if (selectedActionRef.current === "upload-designed-image") {
        scrollToEndOfPage({ scrollRefs });
        selectedActionRef.current = "";
      }
    }, [selectedActionRef.current]);

    const pickDocument = async (setter) => {
      let result = await DocumentPicker.getDocumentAsync({
        type: "image/*",
      });

      // Image size should be less than 3MB
      if (result.size < imageSize) {
        const designImageBase64 = result.uri.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        );
        setter(result.uri);
        uploadDesignedImage(designImageBase64, setter);
      } else {
        ToasterHandler(
          "error",
          Localise(locMessages, "Image size should be less than 3MB.")
        );
      }
    };

    return (
      <>
        {hasDesignCenter || hasDesignedImage ? (
          <View>
            <View style={tw("flex flex-row flex-wrap my-1 items-center")}>
              {designedImageLabel && (
                <Text style={{ ...fonts.heading5, width: 125 }}>
                  {Localise(locMessages, "Designed Image")}
                </Text>
              )}
              <View style={tw("flex-row items-center")}>
                {showUploadButton ? (
                  <Button
                    title={Localise(
                      locMessages,
                      !hasDesignedImage ? "Upload Photo" : "Replace Photo"
                    )}
                    testID="upload_designed_image"
                    titleStyle={theme.Button.secondaryTitleStyle}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 7,
                      paddingHorizontal: 10,
                    }}
                    containerStyle={{
                      margin: 4,
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                    onPress={() =>
                      Platform.OS === "web"
                        ? pickDocument(setStandardDesignImageResponse)
                        : updateSheetOptions(setStandardDesignImageResponse)
                    }
                  />
                ) : null}
              </View>

              {hasDesignedImage && (
                <View style={{ width: 100 }}>
                  <Image
                    style={{
                      width: 104,
                      height: 104,
                      marginTop: 10,
                    }}
                    source={{ uri: standardDesignImageResponse }}
                  />
                </View>
              )}
            </View>
          </View>
        ) : null}
      </>
    );
  }
);

UploadImageButton.displayName = UploadImageButton;
export default UploadImageButton;
