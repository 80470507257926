import React from "react";
import { View } from "react-native";
import { Accordion } from "components/elements";
import { backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";

const SettingsHOC = (props) => {
  const { title = "", Child, childProps } = props;
  const testID = title?.replace(/\s+/g, "_");
  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
        },
        tw("my-3"),
      ]}
      pointerEvents={"auto"}
    >
      <Accordion
        title={title}
        defaultOpen={true}
        handleOnPress={() => {}}
        labelStyle={{
          marginTop: 0,
          height: 50,
          backgroundColor: backgroundColors.navBar,
          borderBottomWidth: 1,
          borderColor: colors.grayScaleLight,
          paddingHorizontal: 12,
        }}
        titleStyle={{
          color: colors.primary,
          paddingLeft: 10,
        }}
        testID={testID}
      >
        <View
          style={{
            backgroundColor: backgroundColors.secondary,
            padding: 10,
            paddingHorizontal: 15,
          }}
        >
          <View style={[tw("flex flex-row items-center p-2")]}>
            <View
              style={{
                width: "100%",
              }}
            >
              <Child {...childProps} />
            </View>
          </View>
        </View>
      </Accordion>
    </View>
  );
};

export default SettingsHOC;
