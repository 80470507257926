/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useContext, useMemo } from "react";
import { View, Platform } from "react-native";
import { Text, Image } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import useStateIfMounted from "library/utils/useStateIfMounted";
import tw from "tailwind-rn";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import get from "lodash/get";
import isNil from "lodash/isNil";

import { setFocusField } from "library/sagas/views/home/drawer/delivery/slice";
import { DeviceContext } from "library/contexts/appSettings";
import {
  setQrScanVariant,
  setOpenScanner,
} from "library/sagas/ongoing/global-data/slice";
import { selectQrScanVariant } from "library/sagas/ongoing/global-data/selector";
import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";
import IMAGES from "static/assets/images";
import {
  Form,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { ToasterHandler, Tooltip } from "components/elements";
import RouteShopDetails from "./route-shop-details";

import styles from "../styles";
import { convertToTimeZone } from "library/utils/deliveryService";
import { dateTimeFormat } from "library/utils/datetime";
import { areShopLocationsEqual } from "./../helper";

// eslint-disable-next-line react/display-name
const RouteDetailForm = React.memo(
  ({
    shopCode,
    setShopCode,
    routeName,
    routeDeliveryDate,
    setRouteDeliveryDate,
    filteredShopCodes,
    multiSelectedShopCodes,
    setMultiSelectedShopCodes,
    memberQuery,
    setMemberQuery,
    driversData,
    startLocationCode,
    setStartLocationCode,
    endLocationCode,
    setEndLocationCode,
    RouteDetailsFormRef,
    triggerSaveRoute,
    localPermissions,
    routeData = {},
    isPastRoute,
    isEditRoute,
    autoRouteData,
    ordersList,
    setOrdersList,
    shopLocations,
    driverQuery,
    setDriverQuery,
    locationFieldQuery,
    setLocationFieldQuery,
    driver,
    setDriver,
    setShowShopRemovedModal,
    setShopRemoved,
    loading,
    hasLimitedRouteAccess,
    setEraseMap,
    getDriversAndSaveRoute,
    buildPickupStop,
    expectedStartTime,
    setExpectedStartTime,
    focusFieldName,
    routeHasEdits,
    hasPickupStopWithSameAddress,
  }) => {
    const dispatch = useDispatch();
    const shopNames = UserProfileStorage.getAllShopNames();
    const { messages, Localise } = useContext(I18NContext);
    const { isDesktop, isMobile } = useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const [validationOnChange, setValidationOnChange] =
      useStateIfMounted(false);
    const qrScanVariant = useSelector(selectQrScanVariant);

    const {
      addressLine1: startAddressLine1 = "",
      city: startCity = "",
      state: startState = "",
      zip: startZip = "",
    } = shopLocations[startLocationCode] || {};
    const {
      addressLine1: endAddressLine1 = "",
      city: endCity = "",
      state: endState = "",
      zip: endZip = "",
    } = shopLocations[endLocationCode] || {};

    const selectedShops = filteredShopCodes?.filter((code) =>
      multiSelectedShopCodes.includes(code)
    );
    const notSelectedShops = filteredShopCodes?.filter(
      (code) => !multiSelectedShopCodes.includes(code)
    );
    const sendingOriginData = [...selectedShops, ...notSelectedShops].map(
      (each) => {
        const shopName = shopNames[each] || "";
        return {
          label: `${each + " " + shopName}`,
          value: each,
          shopName: shopName,
        };
      }
    );

    const startEndLocationData = filteredShopCodes
      ?.filter((eachCode) => multiSelectedShopCodes?.includes(eachCode))
      ?.map((eachCode) => {
        const {
          addressLine1 = "",
          city = "",
          state = "",
          zip = "",
        } = shopLocations[eachCode] || {};

        return {
          label: `${
            eachCode + " " + `${[addressLine1, city, state, zip].join(" ")}`
          }`,
          code: eachCode,
        };
      });

    const allLabel = Localise(messages, "All");

    const isNonWebQrScan = useMemo(() => {
      return Platform.OS !== "web" && qrScanVariant === "order-route-scan";
    }, [qrScanVariant]);

    const isLoadingOrPastRoute = useMemo(() => {
      return loading || isPastRoute;
    }, [loading, isPastRoute]);

    const disableRouteEdit = useMemo(() => {
      return (
        (isEditRoute &&
          (isLoadingOrPastRoute ||
            !["DRAFT", "PLANNED"].includes(routeData.status))) ||
        isNonWebQrScan
      );
    }, [isEditRoute, isLoadingOrPastRoute, routeData.status, isNonWebQrScan]);

    const disableDriverEdit = useMemo(() => {
      return (
        (isEditRoute &&
          (isLoadingOrPastRoute ||
            !["DRAFT", "PLANNED"].includes(routeData.status))) ||
        isNonWebQrScan
      );
    }, [isEditRoute, isLoadingOrPastRoute, routeData.status, isNonWebQrScan]);

    const handlePickupStop = (
      needToAddPickupStop,
      addPickupCode,
      removePickupCode
    ) => {
      const pickupStop = buildPickupStop(addPickupCode);
      const updatedOrdersInRoute = ordersList.ordersInRoute?.filter((ord) => {
        const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
        return !(
          areShopLocationsEqual(removePickupCode, siteId) &&
          ord.stopType === "PICKUP"
        );
      });
      const needToRemovePickupStop =
        ordersList.ordersInRoute?.filter((ord) => {
          const { receivingMember: { memberCode: siteId = "" } = {} } = ord;
          return (
            areShopLocationsEqual(removePickupCode, siteId) &&
            ord.stopType === "PICKUP"
          );
        })?.length > 0;
      setOrdersList({
        ...ordersList,
        ordersInRoute: needToAddPickupStop
          ? [...updatedOrdersInRoute, pickupStop]
          : [...updatedOrdersInRoute],
      });
      const message = `${Localise(messages, `Start Location changed.`)} ${
        needToAddPickupStop
          ? `${Localise(messages, `Added Pickup stop for`)} ${
              pickupStop.stopName
            }.`
          : ``
      } ${
        needToRemovePickupStop
          ? `${Localise(
              messages,
              `Removed Pickup stop for`
            )} ${removePickupCode} ${shopNames[removePickupCode]}`
          : ``
      }. ${Localise(messages, `Please review.`)}`;

      if (needToAddPickupStop || needToRemovePickupStop)
        ToasterHandler("success", Localise(messages, message));
    };

    const floristTimeZone =
      UserProfileStorage.getShopTimeZone(
        multiSelectedShopCodes[0] || shopCode
      ) || "America/Chicago";

    const floristDateTime = moment(
      convertToTimeZone(new Date(), floristTimeZone)
    );

    const routeDate = moment(routeDeliveryDate).format("YYYY-MM-DD");

    const isSameDay = moment(floristDateTime).isSame(moment(routeDate), "day");
    const expectedStartTimeDefault = isSameDay
      ? floristDateTime
      : routeDate + `T09:00:00Z`;

    return (
      <Form
        initialValues={{
          routeDeliveryDate,
          routeName: "",
          driver: { ...driver },
          optimize: false,
          allShopCodes: [
            {
              label: allLabel,
              value: [...filteredShopCodes],
            },
            ...sendingOriginData,
          ],
          selectedMemberCodes: multiSelectedShopCodes?.length
            ? multiSelectedShopCodes
            : [],
          startLocation: startLocationCode
            ? startEndLocationData?.find((each) =>
                areShopLocationsEqual(each.code, startLocationCode)
              )?.label || ""
            : "",
          endLocation: endLocationCode
            ? startEndLocationData?.find((each) =>
                areShopLocationsEqual(each.code, endLocationCode)
              )?.label || ""
            : "",
          expectedStartTime: expectedStartTime
            ? `${moment
                .parseZone(expectedStartTime)
                .format("YYYY-MM-DDTHH:mm:ss")}`
            : `${moment
                .parseZone(expectedStartTimeDefault)
                .format("YYYY-MM-DDTHH:mm:ss")}`,
          expectedTimeDidConfirmed: false,
        }}
        innerRef={RouteDetailsFormRef}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        onSubmit={(values, formikBag) => {
          dispatch(setQrScanVariant(""));
          dispatch(setOpenScanner(false));
          triggerSaveRoute(values, formikBag);
        }}
        render={(props) => {
          const { values, setValues, submitCount, setFieldValue } = props;
          const {
            selectedMemberCodes = [],
            startLocation = "",
            endLocation = "",
            expectedStartTime: fieldValExpectedStartTime,
            expectedTimeDidConfirmed,
          } = values;
          const finalValues = cloneDeep(values);

          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          useEffect(() => {
            set(finalValues, "driver.name", "");
            set(finalValues, "driver.id", "");
            setValues(finalValues);
            setMultiSelectedShopCodes([shopCode]);
          }, [shopCode]);

          useEffect(() => {
            setMultiSelectedShopCodes([...selectedMemberCodes]);
            UserProfileStorage.setRouteShopList([...selectedMemberCodes]);
            if (selectedMemberCodes.length === 0) {
              setStartLocationCode("");
              setEndLocationCode("");
            } else if (selectedMemberCodes.length === 1) {
              setStartLocationCode(selectedMemberCodes[0]);
              setEndLocationCode(selectedMemberCodes[0]);
            }
            if (!startLocationCode) {
              setStartLocationCode(selectedMemberCodes[0]);
            }
            if (!endLocationCode) {
              setEndLocationCode(selectedMemberCodes[0]);
            }
          }, [JSON.stringify(selectedMemberCodes)]);

          useEffect(() => {
            if (Platform.OS === "android") return;
            if (fieldValExpectedStartTime) {
              const updatedTime =
                routeDate + "T" + fieldValExpectedStartTime.split("T")[1];
              setExpectedStartTime(updatedTime);
            }

            if (
              isEditRoute &&
              expectedTimeDidConfirmed &&
              moment.parseZone(expectedStartTime).format(dateTimeFormat) !==
                moment
                  .parseZone(fieldValExpectedStartTime)
                  .format(dateTimeFormat)
            ) {
              RouteDetailsFormRef?.current?.handleSubmit();
            }
            setFieldValue("expectedTimeDidConfirmed", false);
          }, [expectedTimeDidConfirmed]);

          const handleAndroidPlannedStartChange = (date) => {
            if (!isEditRoute) return;
            let updatedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
            if (updatedDate) {
              const updatedTime = routeDate + "T" + updatedDate.split("T")[1];
              setExpectedStartTime(updatedTime);
            }

            //save Route
            RouteDetailsFormRef?.current?.handleSubmit();
          };

          return (
            <>
              {/* Shop Selection */}
              <RouteShopDetails
                {...{
                  shopCode,
                  setShopCode,
                  filteredShopCodes,
                  memberQuery,
                  setMemberQuery,
                  startLocationCode,
                  setStartLocationCode,
                  endLocationCode,
                  setEndLocationCode,
                  localPermissions,
                  routeData,
                  isEditRoute,
                  ordersList,
                  setOrdersList,
                  setShowShopRemovedModal,
                  setShopRemoved,
                  setEraseMap,
                  getDriversAndSaveRoute,
                  hasPickupStopWithSameAddress,
                  disableRouteEdit,
                }}
              />

              {/* Route Delivery Date */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 50,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text
                    style={{
                      ...styles.labelText,
                      paddingRight: 15,
                    }}
                  >
                    {Localise(messages, "Route Delivery Date")}:
                  </Text>
                </View>
                <View style={styles.valueText}>
                  {isEmpty(routeData) ? (
                    <FormFieldDatePicker
                      dateValueFormat="YYYY-MM-DD"
                      name="routeDeliveryDate"
                      placeholder="MM/DD/YYYY"
                      containerStyle={{
                        zIndex: 40,
                        paddingLeft: 0,
                        marginLeft: -5,
                        marginTop: 0,
                        width: isSmallScreen ? "100%" : 230,
                        maxWidth: 800,
                        height: Platform.OS !== "web" ? 40 : "auto",
                      }}
                      alignIconRight={true}
                      iconName="calendar"
                      iconType="font-awesome"
                      onValueChange={(val) => {
                        if (!isNil(val)) {
                          const selectedRouteDeliveryDate =
                            moment(val).format("YYYY-MM-DD");
                          setRouteDeliveryDate(selectedRouteDeliveryDate);
                          UserProfileStorage.setRouteDeliveryDate(
                            selectedRouteDeliveryDate
                          );
                        }
                      }}
                      errorStyle={{ paddingBottom: 0 }}
                    />
                  ) : (
                    <Text>
                      {moment(routeData.deliveryDate).format("MM/DD/YYYY")}
                    </Text>
                  )}
                </View>
              </View>

              {/* Route Name */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 35,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text
                    style={{
                      ...styles.labelText,
                      paddingRight: 15,
                    }}
                  >
                    {Localise(messages, "Route Name")}:
                  </Text>
                </View>
                <View style={styles.valueText}>
                  <Text>
                    {isEmpty(routeData) ? routeName : routeData.routeName}
                  </Text>
                </View>
              </View>

              {/* Driver */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 40,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text
                    style={{
                      ...styles.labelText,
                      paddingRight: 15,
                    }}
                  >
                    {Localise(messages, "Driver")}:
                  </Text>
                </View>
                <View
                  style={{ ...styles.valueText }}
                  pointerEvents={disableDriverEdit ? "none" : "auto"}
                >
                  {isPastRoute ||
                  hasLimitedRouteAccess ||
                  !localPermissions.allowEditDriver ? (
                    <Text>{routeData.driver?.name || driver.name || "-"}</Text>
                  ) : (
                    <FormFieldAutoComplete
                      data={
                        (driversData.length &&
                          driversData.filter((each) => {
                            const { firstName = "", lastName = "" } = each;
                            return (
                              firstName
                                .toLowerCase()
                                .includes(driverQuery.toLowerCase()) ||
                              lastName
                                .toLowerCase()
                                .includes(driverQuery.toLowerCase())
                            );
                          })) ||
                        []
                      }
                      showOnFocus={true}
                      clearTextOnBackTab={true}
                      onChangeText={(text) => {
                        setDriverQuery(text);
                      }}
                      placeholder={"Enter Driver Name"}
                      listDisplayValues={["firstName", "lastName"]}
                      outerContainerStyle={{
                        zIndex: 1,
                        marginLeft: -5,
                        width: isSmallScreen ? "100%" : "80%",
                        maxWidth: 800,
                      }}
                      name="driver.name"
                      isMultiSelect={false}
                      isFormatData={true}
                      onSelect={(selectedValue) => {
                        const { firstName, lastName, id } = selectedValue;
                        const driverName = `${firstName} ${lastName}`;
                        set(finalValues, "driver.name", driverName);
                        set(finalValues, "driver.id", `${id}`);
                        setValues(finalValues);
                        setDriverQuery("");
                        setDriver({ ...driver, name: driverName, id: `${id}` });
                      }}
                      onClear={() => {
                        set(finalValues, "driver.name", "");
                        set(finalValues, "driver.id", "");
                        setValues(finalValues);
                      }}
                      popperPlacement={"bottom"}
                    />
                  )}
                </View>
              </View>

              {/* Capacity */}
              {autoRouteData?.autoRouteId && (
                <View
                  style={{
                    ...tw("flex-row my-1 items-center"),
                    zIndex: 35,
                  }}
                >
                  <View style={styles.labelTextContainer}>
                    <Text
                      style={{
                        ...styles.labelText,
                        paddingRight: 15,
                      }}
                    >
                      {Localise(messages, "Capacity")}:
                    </Text>
                  </View>
                  <View style={styles.valueText}>
                    <Text>
                      {isEmpty(autoRouteData)
                        ? ""
                        : `${
                            ordersList?.ordersInRoute?.length
                              ? ordersList?.ordersInRoute?.length
                              : 0
                          }/${autoRouteData?.capacity} `}
                    </Text>
                  </View>
                </View>
              )}

              {/* Status */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 35,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text
                    style={{
                      ...styles.labelText,
                      paddingRight: 15,
                    }}
                  >
                    {Localise(messages, "Status")}:
                  </Text>
                </View>
                <View style={styles.valueText}>
                  <Text>
                    {`${routeData?.completed ? routeData?.completed : 0}/${
                      ordersList.ordersInRoute.length
                    } ${Localise(messages, "Completed")}`}
                  </Text>
                </View>
              </View>

              {/* Start Location */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 31,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "Start Location")}:
                  </Text>
                </View>
                <View
                  style={{ ...styles.valueText }}
                  pointerEvents={disableRouteEdit ? "none" : "auto"}
                >
                  {localPermissions.multiShopRoute &&
                  !hasLimitedRouteAccess &&
                  multiSelectedShopCodes?.length ? (
                    <>
                      {multiSelectedShopCodes?.length === 1 ? (
                        <Text>{startLocation}</Text>
                      ) : (
                        <FormFieldAutoComplete
                          name="startLocation"
                          placeholder={"Select Start Location"}
                          data={
                            (startEndLocationData?.length &&
                              startEndLocationData?.filter((eachLocation) => {
                                const { label } = eachLocation;
                                return label
                                  .toLowerCase()
                                  .includes(locationFieldQuery.toLowerCase());
                              })) ||
                            []
                          }
                          showOnFocus={true}
                          clearTextOnBackTab={true}
                          onChangeText={(text) => {
                            setLocationFieldQuery(text);
                          }}
                          listDisplayValues={["label"]}
                          outerContainerStyle={{
                            zIndex: 1,
                            marginLeft: -5,
                            width: isSmallScreen ? "100%" : "80%",
                            maxWidth: 800,
                          }}
                          isMultiSelect={false}
                          isFormatData={true}
                          popperPlacement={"bottom"}
                          onSelect={(selectedValue) => {
                            const { label, code } = selectedValue;
                            set(finalValues, "startLocation", label);
                            setValues(finalValues);
                            setLocationFieldQuery("");
                            setStartLocationCode(code);

                            if (isEditRoute) {
                              if (
                                !areShopLocationsEqual(code, startLocationCode)
                              ) {
                                const hasOrderWithPreviousStartLocation =
                                  ordersList.ordersInRoute.some((ord) => {
                                    const {
                                      receivingMember: {
                                        memberCode: siteId = "",
                                      } = {},
                                    } = ord;
                                    return areShopLocationsEqual(
                                      siteId,
                                      startLocationCode
                                    );
                                  });
                                const hasPickupStopForPreviousStartLocation =
                                  ordersList.ordersInRoute.some((ord) => {
                                    const {
                                      receivingMember: {
                                        memberCode: siteId = "",
                                      } = {},
                                    } = ord;
                                    return (
                                      ord.stopType === "PICKUP" &&
                                      siteId &&
                                      areShopLocationsEqual(
                                        siteId,
                                        startLocationCode
                                      )
                                    );
                                  });

                                const needToAddPickupStop =
                                  hasOrderWithPreviousStartLocation &&
                                  !hasPickupStopForPreviousStartLocation;

                                handlePickupStop(
                                  needToAddPickupStop,
                                  startLocationCode,
                                  code
                                );
                              }
                              RouteDetailsFormRef?.current?.handleSubmit();
                            }
                          }}
                          handleOnBlur={() => {
                            setLocationFieldQuery("");
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text>
                      {isEmpty(routeData)
                        ? `${[
                            startAddressLine1,
                            startCity,
                            startState,
                            startZip,
                          ].join(" ")}`
                        : `${
                            routeData.startLocation.addressLine1 +
                            " " +
                            routeData.startLocation.city +
                            " " +
                            routeData.startLocation.state +
                            " " +
                            routeData.startLocation.zip
                          }`}
                    </Text>
                  )}
                </View>
              </View>

              {/* End Location */}
              <View
                style={{
                  ...tw("flex-row my-1 items-center"),
                  zIndex: 30,
                }}
              >
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "End Location")}:
                  </Text>
                </View>
                <View
                  style={{ ...styles.valueText }}
                  pointerEvents={disableRouteEdit ? "none" : "auto"}
                >
                  {localPermissions.multiShopRoute &&
                  !hasLimitedRouteAccess &&
                  multiSelectedShopCodes?.length ? (
                    <>
                      {multiSelectedShopCodes?.length === 1 ? (
                        <Text>{endLocation}</Text>
                      ) : (
                        <FormFieldAutoComplete
                          name="endLocation"
                          placeholder={"Select End Location"}
                          data={
                            (startEndLocationData?.length &&
                              startEndLocationData?.filter((eachLocation) => {
                                const { label } = eachLocation;
                                return label
                                  .toLowerCase()
                                  .includes(locationFieldQuery.toLowerCase());
                              })) ||
                            []
                          }
                          showOnFocus={true}
                          clearTextOnBackTab={true}
                          onChangeText={(text) => {
                            setLocationFieldQuery(text);
                          }}
                          listDisplayValues={["label"]}
                          outerContainerStyle={{
                            zIndex: 1,
                            marginLeft: -5,
                            width: isSmallScreen ? "100%" : "80%",
                            maxWidth: 800,
                          }}
                          isMultiSelect={false}
                          isFormatData={true}
                          popperPlacement={"bottom"}
                          onSelect={(selectedValue) => {
                            const { label, code } = selectedValue;
                            set(finalValues, "endLocation", label);
                            setValues(finalValues);
                            setLocationFieldQuery("");
                            setEndLocationCode(code);

                            if (isEditRoute)
                              RouteDetailsFormRef?.current?.handleSubmit();
                          }}
                          handleOnBlur={() => {
                            setLocationFieldQuery("");
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text>
                      {isEmpty(routeData)
                        ? `${[endAddressLine1, endCity, endState, endZip].join(
                            " "
                          )}`
                        : `${
                            routeData.endLocation.addressLine1 +
                            " " +
                            routeData.endLocation.city +
                            " " +
                            routeData.endLocation.state +
                            " " +
                            routeData.endLocation.zip
                          }`}
                    </Text>
                  )}
                </View>
              </View>

              {/* Expected Start Time */}
              {localPermissions.isOptimizeEnabled &&
                localPermissions.isCFROptimiseEnabled && (
                  <View
                    style={{
                      ...tw("flex-row my-1 items-center"),
                      zIndex: 29,
                    }}
                    pointerEvents={disableRouteEdit ? "none" : "auto"}
                  >
                    <View style={styles.labelTextContainer}>
                      <Text style={styles.labelText}>
                        {Localise(messages, "Planned Start Time")}:
                      </Text>
                    </View>
                    <View
                      style={{ ...styles.valueText, ...tw("flex flex-row") }}
                    >
                      <FormFieldDatePicker
                        dateFormat="h:mm a"
                        name="expectedStartTime"
                        placeholder="Select Expected Start Time"
                        containerStyle={{
                          marginLeft: -5,
                          width: isSmallScreen ? "90%" : 230,
                        }}
                        labelStyle={{
                          fontSize: 14,
                          fontWeight: "normal",
                        }}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeIntervals={1}
                        {...(Platform.OS !== "web"
                          ? {
                              dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
                              onValueChange: (date) => {
                                if (Platform.OS === "android") {
                                  handleAndroidPlannedStartChange(date);
                                } else {
                                  setFieldValue(
                                    "expectedTimeDidConfirmed",
                                    true
                                  );
                                }
                              },
                            }
                          : {
                              onCalendarClose: () => {
                                setFieldValue("expectedTimeDidConfirmed", true);
                              },
                            })}
                        disabled={!!loading}
                        editable={true}
                        focusTo={focusFieldName === "expectedStartTime"}
                        focusCallBack={() => {
                          dispatch(setFocusField(""));
                        }}
                      />
                      <Tooltip
                        text={Localise(
                          messages,
                          "Planned Start time to consider traffic conditions"
                        )}
                        height={isMobile ? null : 40}
                      >
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                            marginTop: isMobile ? 5 : 4,
                          }}
                          resizeMode="contain"
                          source={IMAGES["help"]}
                        />
                      </Tooltip>
                    </View>
                  </View>
                )}

              {/* Suggested Start Time */}
              {localPermissions.isOptimizeEnabled && (
                <View style={tw("flex-row my-1 items-center")}>
                  <View style={styles.labelTextContainer}>
                    <Text style={styles.labelText}>
                      {Localise(messages, "Suggested Start Time")}:
                    </Text>
                  </View>
                  <View style={{ ...styles.valueText }}>
                    <Text>
                      {(isEditRoute &&
                        routeData.recommendedStartTime &&
                        moment(
                          moment.utc(routeData.recommendedStartTime).toDate()
                        ).format("h:mm A")) ||
                        "-"}
                    </Text>
                  </View>
                </View>
              )}

              {/* Departure Time */}
              <View style={tw("flex-row my-1 items-center")}>
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "Departure Time")}:
                  </Text>
                </View>
                <View style={{ ...styles.valueText }}>
                  <Text>
                    {(isEditRoute &&
                      routeData.departureTime &&
                      moment(
                        moment.utc(routeData.departureTime).toDate()
                      ).format("h:mm A")) ||
                      "-"}
                  </Text>
                </View>
              </View>

              {/* End Time Estimate */}
              <View style={tw("flex-row my-1 items-center")}>
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "End Time Estimate")}:
                  </Text>
                </View>
                <View style={{ ...styles.valueText }}>
                  <Text>
                    {(isEditRoute &&
                      routeData.estimatedReturnTime &&
                      moment(
                        moment.utc(routeData.estimatedReturnTime).toDate()
                      ).format("h:mm A")) ||
                      "-"}
                  </Text>
                </View>
              </View>

              {/* Actual End Time */}
              <View style={tw("flex-row my-1 items-center")}>
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "Actual End Time")}:
                  </Text>
                </View>
                <View style={{ ...styles.valueText }}>
                  <Text>
                    {(isEditRoute &&
                      routeData.returnTime &&
                      moment(moment.utc(routeData.returnTime).toDate()).format(
                        "h:mm A"
                      )) ||
                      "-"}
                  </Text>
                </View>
              </View>

              {/* Total Miles */}
              <View style={tw("flex-row my-1 items-center")}>
                <View style={styles.labelTextContainer}>
                  <Text style={styles.labelText}>
                    {Localise(messages, "Total Miles")}:
                  </Text>
                </View>
                <View style={{ ...styles.valueText }}>
                  <Text>
                    {get(routeData, "estimatedDriveDistance.value", "") &&
                    !routeHasEdits
                      ? get(routeData, "estimatedDriveDistance.text", "")
                      : "-"}
                  </Text>
                </View>
              </View>
            </>
          );
        }}
      />
    );
  }
);

export default RouteDetailForm;
