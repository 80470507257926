import React from "react";
import { View, Text, Platform } from "react-native";

import I18NContext from "library/contexts/i18N";
import { FormField } from "components/elements/forms";
import { ToasterHandler } from "components/elements";
import { formatPrice } from "library/utils/formatter";

import styles from "components/containers/listing-new/styles";
import { fonts } from "styles/theme";

import tw from "tailwind-rn";

const ApplyPayment = ({
  openItemUnpaidOrders,
  viewMoreCounts,
  setFieldValue,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  return (
    <View>
      {openItemUnpaidOrders
        ?.slice(0, viewMoreCounts)
        ?.map((lineItem, index) => {
          const {
            orderId = "",
            amountReceived = 0,
            orderTotalAmount = 0,
            refunds = [],
            isDebitMemo = false,
            isLateFee = false,
          } = lineItem;

          let refundedAmount = 0;
          for (let i = 0; i < refunds?.length; i++) {
            refundedAmount = refundedAmount + refunds[i]?.refundedAmount;
          }

          const totalAmountReceived = amountReceived + refundedAmount;
          const lineItemName = Localise(
            messages,
            isDebitMemo
              ? Localise(messages, "Debit Memo")
              : isLateFee
              ? Localise(messages, "Late Fee")
              : Localise(messages, "Order")
          );

          return (
            <View key={index}>
              <View
                style={[
                  tw(
                    `flex flex-row justify-start pb-2 items-${
                      Platform.OS !== "web" ? "center" : "baseline"
                    }`
                  ),
                ]}
              >
                <Text
                  style={{
                    ...fonts.default,
                    ...styles.labelText,
                    paddingBottom: 5,
                    width: "40%",
                  }}
                >
                  {`${lineItemName} #${orderId}`}
                </Text>
                <View
                  style={[
                    tw(
                      `flex flex-row pb-2 items-${
                        Platform.OS !== "web" ? "center" : "baseline"
                      }`
                    ),
                    { width: "60%" },
                  ]}
                >
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    name={"paymentAmount"}
                    placeholder=""
                    containerStyle={{ width: 100 }}
                    isPrice={true}
                    keyboardType="numeric"
                    iconType="material-community"
                    iconName="currency-usd"
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 20 }}
                    path={`ordersInfo.${index}`}
                    testID="ordersInfo"
                    handleOnBlur={(balAmount = "") => {
                      if (balAmount?.length) {
                        setFieldValue(`ordersInfo.${index}.orderID`, orderId);
                        if (
                          Number(balAmount) >
                          Number(
                            formatPrice(orderTotalAmount - totalAmountReceived)
                          )
                        ) {
                          ToasterHandler(
                            "uh oh",
                            Localise(
                              messages,
                              `Amount should not be greater than $${formatPrice(
                                orderTotalAmount - totalAmountReceived
                              )}`
                            )
                          );
                        }
                      } else {
                        setFieldValue(`ordersInfo.${index}.orderID`, ``);
                        setFieldValue(`ordersInfo.${index}.paymentAmount`, ``);
                      }
                    }}
                  />
                  <Text
                    style={{
                      ...fonts.default,
                      ...styles.labelText,
                      paddingBottom: 5,
                      paddingLeft: 10,
                    }}
                  >
                    {Localise(
                      messages,
                      `Balance $${formatPrice(
                        orderTotalAmount - totalAmountReceived
                      )}`
                    )}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
    </View>
  );
};

export default ApplyPayment;
