import React from "react";
import { View, ScrollView, Platform, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { useDispatch } from "react-redux";

import styles from "./styles";
import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { getMultiOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { showPrintToaster } from "components/views/drawer/order-details/order-head/take-action/helper";

import { PrintIframeAction, Tooltip } from "components/elements";
import { request } from "library/utils/request";
import moment from "moment";
import UserProfileContext from "library/contexts/userProfile";
import UserProfileStorage from "library/storage/userProfile";
import groupBy from "lodash/groupBy";
import Environment from "library/utils/environment";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";

const OrderActionWidget = ({
  widgetResponse,
  shopCodes,
  shopNames,
  isSmallScreen,
  isTablet,
  onPress,
  isBulkOrderPrint,
  onBulkHirePrint,
  allShopCodes,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [displayScroll, setDisplayScroll] = useStateIfMounted(false);
  const [unprintedOrders, setUnprintedOrders] = useStateIfMounted(false);
  const { memberCodes = [], userProfile: { firstName: operator } = {} } =
    React.useContext(UserProfileContext);
  const shopsPreferences = UserProfileStorage.getAllShopPreferences();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const isPrintManagementOn = Environment.get(
    "PRINT_MANAGEMENT_ENABLED",
    false
  );

  const handleOnPress = (item) => {
    if (item === "unPrintedOrders") {
      if (widgetResponse.unPrintedOrders === 0 || unprintedOrders) {
        return null;
      }
      setUnprintedOrders(true);

      request("get-bulk-print-orders", {
        memberCodes: shopCodes === "all" ? allShopCodes : [shopCodes],
        startDate: moment().format("YYYY-MM-DD"),
      })
        .then((res) => {
          // order details bulk print handle
          const orderDetailBulkPrint = () => {
            if (isElectronApp && isPrintManagementOn) {
              const memberCodeData = groupBy(
                res,
                (each) => each?.receivingMember?.memberCode
              );

              const selectedMemberCodes =
                shopCodes === "all"
                  ? memberCodeData
                  : memberCodeData[shopCodes];

              const macAddress =
                (isElectronApp &&
                  document.getElementById("macAddress").value) ||
                "";

              Object.keys(selectedMemberCodes).map((key) => {
                const iframeId = `iframeForOrder_${key}`;
                const preferences = shopsPreferences[key];
                const orderPreferencesArray =
                  preferences["order_details_print_settings"] || [];

                const orderPreferences =
                  orderPreferencesArray.find(
                    (each) => each.macAddress === macAddress
                  ) || {};

                const updatePrintStatus = () => {
                  showPrintToaster({
                    remotePrint: false,
                    printerName: orderPreferences?.printer,
                    artifactName: "Order Details",
                    Localise,
                    messages,
                  });
                };

                PrintIframeAction(
                  getMultiOrderPrintHTML({
                    data: memberCodeData[key],
                    memberCodes,
                    operatorName: operator,
                    messages,
                    Localise,
                    iframeId,
                    qrImagesData,
                  }),
                  {
                    printType: "orderDetails",
                    preferences: orderPreferences,
                  },
                  () => {},
                  iframeId,
                  updatePrintStatus
                );
              });
            } else {
              PrintIframeAction(
                getMultiOrderPrintHTML({
                  data: res,
                  memberCodes,
                  operatorName: operator,
                  messages,
                  Localise,
                  iframeId: "iframe",
                  qrImagesData,
                })
              );
            }
          };

          const qrPayload = [];
          res.map((each) => {
            const {
              direction,
              receivingMember,
              sendingMember,
              orderItemId,
              deliveryInfo: { deliveryMethod },
            } = each;
            const sourceMemberCode =
              direction === "INBOUND"
                ? receivingMember?.memberCode
                : sendingMember?.memberCode;

            const { qr_code_global_enabled = "" } =
              UserProfileStorage.getShopPreferences(sourceMemberCode);
            const showQrCode = qr_code_global_enabled === "true";
            const { qr_code_order_details_enabled = "" } =
              UserProfileStorage.getShopPreferences(sourceMemberCode);
            const enableOrderDetailQrCode =
              qr_code_order_details_enabled === "true";
            const showOrderDetailQrCode = showQrCode
              ? enableOrderDetailQrCode
              : false;

            if (showOrderDetailQrCode) {
              qrPayload.push({
                oid: orderItemId || "", // orderItemId
                sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
                dm: deliveryMethod || "", // order deliveryMethod
              });
            }
          });
          let qrImagesData = "";

          if (qrPayload.length > 0) {
            dispatch(
              generateQRCode({
                params: { qrPayload: qrPayload },
                type: "BULK",
                resolve: (resp) => {
                  qrImagesData = resp;
                  orderDetailBulkPrint();
                },
                reject: () => {
                  orderDetailBulkPrint();
                },
              })
            );
          } else {
            orderDetailBulkPrint();
          }

          const orderItemIds = res.map((order) => order.orderItemId);
          request("bulk-print", { orderItemIds })
            .then((res) => {
              onBulkHirePrint();
            })
            .catch(function (error) {
              console.log("error", error);
            });
          setUnprintedOrders(false);
        })
        .catch(function (error) {
          setUnprintedOrders(false);
          console.log("error", error);
        });
    } else {
      const filterMapping = {
        needsAction: {},
        acceptOrder: { title: "Incoming", value: "regular:incoming" },
        approveRequests: {
          title: "Approve Requests",
          value: "approve-request",
        },
        viewChats: {
          title: "View Chats",
          value: "view-chats",
        },
        viewResponses: {
          title: "View Responses",
          value: "view-responses",
        },
        newPickups: {
          title: "New Pickup",
          value: "new-pickup:incoming",
        },
        hurryPickups: {
          title: "Hurry Pickup!",
          value: "hurry-pickup:incoming",
        },
      };

      const statusFilter = filterMapping[item];

      const filters = [
        {
          section: "Categories",
          title: "Needs Action",
          value: "needsAction",
        },
      ];
      const shops = [];

      statusFilter &&
        statusFilter.title &&
        filters.push({
          section: "Order Status",
          ...statusFilter,
        });

      shopCodes !== "all" &&
        shops.push({
          section: "Shop Codes",
          title: `${shopCodes} ${shopNames[shopCodes]}`,
          value: shopCodes,
        });

      onPress();
      navigation.dispatch(
        CommonActions.navigate({
          name: "orders",
          params: {
            filters,
            shops,
          },
        })
      );
    }
  };

  const config = {
    hurryPickups: {
      displayStatus: "Hurry Pickup!",
      iconExists: true,
      iconName: "expiring",
      styleConfig: {
        labelSize: 20,
        labelColor: "#114258",
        borderColor: "#e5175e",
        blockBgColor: "#e5175e1a",
        iconWidth: 32,
        iconHeight: 32,
      },
    },
    newPickups: {
      displayStatus: "New Pickup",
      iconExists: true,
      iconName: "new-pickups",
      styleConfig: {
        labelSize: 20,
        labelColor: "#114258",
        borderColor: "#aadec6",
        blockBgColor: "#AADEC633",
        iconWidth: 32,
        iconHeight: 32,
      },
    },
    acceptOrder: {
      displayStatus: "Accept Orders",
      iconExists: true,
      iconName: "accepted",
      styleConfig: {
        labelSize: 20,
        labelColor: "#114258",
        borderColor: "#aadec6",
        blockBgColor: "#AADEC633",
        iconWidth: 32,
        iconHeight: 32,
      },
    },
    unPrintedOrders: {
      displayStatus: "Unprinted Orders",
      iconExists: true,
      iconName: "print",
      styleConfig: {
        labelSize: 20,
        labelColor: "#114258",
        borderColor: "#aadec6",
        blockBgColor: "#AADEC633",
        iconWidth: 32,
        iconHeight: 32,
      },
    },
    viewChats: {
      displayStatus: "View Chats",
      iconExists: true,
      iconName: "chat",
      styleConfig: {
        labelColor: "#114258",
        borderColor: "#11838e",
        blockBgColor: "#11838E0C",
        iconWidth: 34,
        iconHeight: 30,
      },
    },
    approveRequests: {
      displayStatus: "Approve Requests",
      iconExists: true,
      iconName: "approve-requests",
      styleConfig: {
        labelColor: "#114258",
        borderColor: "#b50d81",
        blockBgColor: "#B50D810C",
        iconWidth: 35,
        iconHeight: 35,
      },
    },
    viewResponses: {
      displayStatus: "View Responses",
      iconExists: true,
      iconName: "view-responses",
      styleConfig: {
        labelColor: "#114258",
        borderColor: "#800067",
        blockBgColor: "#8000670C",
        iconWidth: 35,
        iconHeight: 35,
      },
    },
  };

  const scrollLayout = (
    <ScrollView
      contentContainerStyle={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: Platform.OS === "web" ? "100%" : "auto",
      }}
      style={{
        paddingVertical: 25,
        ...(Platform.OS !== "web" && { alignSelf: "center" }),
      }}
      horizontal={true}
      showsHorizontalScrollIndicator={!isSmallScreen ? displayScroll : true}
    >
      {Object.keys(widgetResponse).map((item, index) => {
        const {
          displayStatus,
          iconExists,
          iconName,
          styleConfig = {},
        } = config[item] || {};
        if (
          (item === "unPrintedOrders" &&
            (isSmallScreen || !isBulkOrderPrint)) ||
          !displayStatus ||
          (["hurryPickups", "newPickups"].includes(item) &&
            widgetResponse[item] === 0)
        )
          return null;

        return (
          <View key={index} style={styles.orderActionBlockContainer}>
            <TouchableOpacity
              style={{
                ...styles.needsActionContentContainer,
                ...styles.orderActionEachBlock,
                backgroundColor: styleConfig.blockBgColor,
                borderColor: styleConfig.borderColor,
              }}
              onPress={() => {
                handleOnPress(item);
              }}
              testID={displayStatus}
              accessibilityLabel={displayStatus}
            >
              {iconExists && IMAGES[iconName] && (
                <Tooltip
                  text={Localise(messages, displayStatus)}
                  renderForWebOnly={true}
                >
                  <Image
                    style={{
                      width: styleConfig.iconWidth,
                      height: styleConfig.iconHeight,
                      marginBottom: 15,
                    }}
                    source={IMAGES[iconName]}
                  />
                </Tooltip>
              )}
              <Text
                style={{
                  ...styles.orderActionCountText,
                  color: styleConfig.labelColor,
                }}
              >
                {widgetResponse[item]}
              </Text>
              <Text
                style={{
                  ...styles.orderActionCountLabel,
                  color: styleConfig.labelColor,
                }}
                numberOfLines={2}
              >
                {Localise(messages, displayStatus)}
              </Text>
            </TouchableOpacity>
          </View>
        );
      })}
    </ScrollView>
  );

  return (
    <>
      <View
        style={{
          ...styles.orderActionContainer,
          ...((Platform.OS === "ios" || Platform.OS === "android") && {
            borderColor: "#CECECE",
            borderWidth: 1,
            alignItems: "center",
            backgroundColor: "white",
          }),
        }}
        onMouseEnter={() => !isSmallScreen && setDisplayScroll(true)}
        onMouseLeave={() => !isSmallScreen && setDisplayScroll(false)}
      >
        <View style={styles.needsActionCountBlockContainer}>
          <TouchableOpacity
            style={styles.needsActionCountBlock}
            onPress={() => {
              handleOnPress("needsAction");
            }}
            testID="needs_action"
            accessibilityLabel="needs_action"
          >
            <View style={styles.countBlockContent}>
              <Text
                style={{
                  ...styles.countText,
                  ...(isTablet && { fontSize: 35 }),
                }}
              >
                {widgetResponse[`needsAction`]}
              </Text>
              <Text
                style={{
                  ...styles.needsActionTitle,
                  fontWeight: "600",
                  flexShrink: 1,
                }}
              >
                {Localise(messages, "Orders Need Action")}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View
          style={{
            ...styles.needsActionContent,
            paddingVertical: 0,
            paddingHorizontal: isSmallScreen ? 5 : 25,
            flexDirection: "column",
          }}
        >
          {scrollLayout}
          {!isSmallScreen && !displayScroll && <View style={{ height: 6 }} />}
        </View>
      </View>
    </>
  );
};

export default OrderActionWidget;
