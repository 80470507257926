import { createSlice, current } from "@reduxjs/toolkit";

import PageStorage from "library/storage/pageStorage";
import isEmpty from "lodash/isEmpty";
import {
  orderGroupsConfig,
  columnIDs,
} from "components/views/drawer/orders-new/config";

import { SLICE_NAME as sliceName } from "./constants";

export const initialState = {
  loading: false,
  api: [],
  data: [],
  pageLimit: 10,
  actions: {
    searchQuery: "",
    orderGroups: [
      {
        category: "all",
        values: ["all"],
      },
    ],
    gridState: [],
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setPageInitialise: (state) => {
      // Assigning locally stored grid state to redux
      const pageActions = PageStorage.getNewOrdersPageActions(
        "new_orders_page_actions"
      );
      if (!isEmpty(pageActions)) {
        state.actions = pageActions;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetSlice: (state) => {
      Object.assign(state, initialState);
      PageStorage.setNewOrdersPageActions("new_orders_page_actions", {});
    },
    setAPIResponse: (state, action) => {
      state.api = action.payload;
    },
    setPageData: (state, action) => {
      state.data = action.payload;
    },
    setPageActions: (state, action) => {
      const { type = "", value = null, callback = () => {} } = action.payload;

      // Reset the pagination
      state.api = [];
      state.data = [];

      if (type === "searchQuery") {
        state.actions.searchQuery = value;
      } else if (type === "orderGroups") {
        const { category, value: tagValue } = value;

        if (category === "all") {
          state.actions.orderGroups = initialState.actions.orderGroups;
        } else {
          const selectedGroup = current(state).actions.orderGroups.find(
            (e) => e.category === category
          );
          const isValueSelected = selectedGroup?.values.includes(tagValue);
          const remainingGroups = current(state).actions.orderGroups.filter(
            (e) => e.category !== category && e.category !== "all"
          );

          const updatedValues = isValueSelected
            ? selectedGroup.values.filter((e) => e !== tagValue)
            : [...(selectedGroup?.values ?? []), tagValue];

          if (!remainingGroups.length && !updatedValues.length) {
            state.actions.orderGroups = initialState.actions.orderGroups;
          } else {
            if (updatedValues.length) {
              state.actions.orderGroups = [
                ...remainingGroups,
                { category, values: updatedValues },
              ];
            } else {
              state.actions.orderGroups = remainingGroups;
            }
          }

          // Updating the gridState to reflect the same in date filters
          if (category !== "date") return;
          const gridState = current(state).actions.gridState;
          const dateTabs =
            orderGroupsConfig.find((e) => e.category === "date").tabs || [];
          const dayName =
            dateTabs.find((e) => e.value === tagValue).label || "";

          const existingFilterEntry = gridState.find(
            (e) => e.colId === "delivery_date"
          );
          const currentDeliveryDate =
            existingFilterEntry?.appliedFilter?.delivery_date || [];

          const updatedFilters = currentDeliveryDate.includes(dayName)
            ? currentDeliveryDate.filter((e) => e !== dayName)
            : [...currentDeliveryDate, dayName];

          const updatedGridState = existingFilterEntry
            ? gridState.map((e) =>
                e.colId === "delivery_date"
                  ? {
                      ...e,
                      appliedFilter: {
                        ...e.appliedFilter,
                        delivery_date: updatedFilters,
                      },
                    }
                  : e
              )
            : [
                ...gridState,
                {
                  colId: "delivery_date",
                  appliedFilter: {
                    delivery_date: [dayName],
                  },
                },
              ];

          state.actions.gridState = updatedGridState;
        }
      } else if (type === "saveGridState") {
        state.actions.gridState = value;
      } else if (type === "saveColumnFilter") {
        const gridCurrentState = current(state).actions.gridState;
        const [columnId = "", rowKey = "", filterValue = "", ...restArray] =
          value.split("::");

        // Updating order action to match the date filters
        const orderGroupDateFilters =
          orderGroupsConfig.find((e) => e.category === "date").tabs || [];
        const dateTabs = orderGroupDateFilters.map((e) => e.label);
        if (dateTabs.includes(filterValue)) {
          const dayOfTheWeek = orderGroupDateFilters.find(
            (e) => e.label === filterValue
          ).value;

          slice.caseReducers.setPageActions(state, {
            payload: {
              type: "orderGroups",
              value: { category: "date", value: dayOfTheWeek },
            },
          });
        }

        if (gridCurrentState?.length ?? 0) {
          state.actions.gridState = gridCurrentState.map((column) => {
            if (column.colId === columnId) {
              const existingFilters = column.appliedFilter?.[rowKey] || [];
              let updatedFilters;
              let appliedFilter;

              if (restArray.length) {
                // To handle date picker selection
                if (columnId === "delivery_date") {
                  const [start, end] = restArray;
                  updatedFilters = existingFilters;
                  appliedFilter = {
                    ...column.appliedFilter,
                    [rowKey]: updatedFilters,
                    startDate: start,
                    endDate: end,
                  };
                }
              } else {
                updatedFilters = existingFilters.includes(filterValue)
                  ? existingFilters.filter((value) => value !== filterValue)
                  : [...existingFilters, filterValue];
                appliedFilter = {
                  ...column.appliedFilter,
                  [rowKey]: updatedFilters,
                };
              }

              return {
                ...column,
                appliedFilter,
              };
            }
            return column;
          });
        } else {
          state.actions.gridState = Object.keys(columnIDs).map((key) => {
            const colId = columnIDs[key];
            const appliedFilter =
              colId === columnId
                ? {
                    [rowKey]: [filterValue],
                  }
                : {};

            return {
              colId,
              appliedFilter,
            };
          });
        }

        callback(filterValue !== "CustomDatePicker" || restArray.length);
      } else if (type === "saveColumnSort") {
        const gridCurrentState = current(state).actions.gridState;
        const [columnId = "", rowKey = "", sortValue = ""] = value.split("::");

        if (gridCurrentState?.length ?? 0) {
          state.actions.gridState = gridCurrentState?.map((column) => {
            if (column.colId === columnId) {
              const existingSort = column.appliedSort || "";
              const appliedSort =
                existingSort === `${columnId}::${rowKey}::${sortValue}`
                  ? existingSort
                  : value;

              return {
                ...column,
                appliedSort,
              };
            }
            return column;
          });
        } else {
          state.actions.gridState = Object.keys(columnIDs).map((key) => {
            const colId = columnIDs[key];
            const appliedSort = colId === columnId ? value : "";

            return {
              colId,
              appliedSort,
            };
          });
        }
        callback();
      }

      PageStorage.setNewOrdersPageActions(
        "new_orders_page_actions",
        current(state).actions
      );
    },

    fetchOrders: () => {},
    filterOrders: () => {},
    fetchPrintDetails: () => {},
    triggerMeetBallAction: () => {},
  },
});

export const {
  setPageInitialise,
  setLoading,
  resetSlice,
  setAPIResponse,
  setPageData,
  setPageSearch,
  setOrderGroups,
  setGridState,
  setPageActions,

  fetchOrders,
  filterOrders,
  fetchPrintDetails,
  triggerMeetBallAction,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
