import React, { useContext, memo } from "react";
import { View, Text } from "react-native";

import I18NContext from "library/contexts/i18N";

import tw from "tailwind-rn";
import moment from "moment";

const SubscriptionDates = ({ featureEnabled, startDate, endDate }) => {
  const { Localise, messages } = useContext(I18NContext);

  return (
    <View style={[tw(`flex flex-row flex-wrap items-center my-2`)]}>
      {startDate ? (
        <Text style={[tw("mr-5")]}>
          {Localise(messages, "Subscription Start")}:{" "}
          {moment(moment.utc(startDate).toDate()).format("MM/DD/YYYY")}
        </Text>
      ) : null}
      {!featureEnabled && endDate ? (
        <Text style={[tw("mr-1")]}>
          {Localise(messages, "Subscription End")}:{" "}
          {moment(moment.utc(endDate).toDate()).format("MM/DD/YYYY")}
        </Text>
      ) : null}
    </View>
  );
};

export default memo(SubscriptionDates);
