import { createStyles } from "react-native-media-queries";

export default createStyles({
  container: {
    flex: 1,
    zIndex: 300,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: 4,
    zIndex: 200,
    position: "relative",
  },

  dropdownContainer: {
    maxHeight: 300,
    minHeight: 5,
    backgroundColor: "#fff",
    borderColor: "#ddd",
    borderWidth: 1,
    position: "absolute",
    top: 60,
    left: 0,
    width: "100%",
    zIndex: 500,
    overflow: "hidden",
  },
  selectedItemContainer: {
    padding: 8,
    backgroundColor: "#f0f0f0",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectedItem: {
    fontSize: 12,
    fontWeight: "bold",
  },
  parentName: {
    color: "#3A7D8D",
    fontSize: 12,
    fontWeight: "bold",
  },
  nodeContainer: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    marginTop: 5,
  },
  nodeTouchable: {
    flexDirection: "row",
    alignItems: "center",
  },
  nodeText: {
    fontSize: 12,
  },
  selectedNodeText: {
    color: "#3A7D8D",
  },
});
