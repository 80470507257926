import * as Yup from "yup";
import get from "lodash/get";

export const PermissionsForPageAccess = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: true,
  FTD_CSR: true,
};

export const PermissionsForCreditCardSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
  FTD_CSR: false,
};

export const PermissionsToShowCreditCardSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: true,
  FTD_CSR: false,
};

export const PermissionsForPaymentTerminalSettings = {
  FTD_ADMIN: true,
  FTD_POWER_USER: true,
  FTD_VIEW_ONLY: false,
};

export const ContactSyncInitialValues = {
  userName: "",
  contactSync: true,
};

export const ContactSyncFormFields = [
  {
    name: "userName",
    title: "User Name",
    type: "Text",
    isUpdateOnChange: true,
  },
  { name: "contactSync", title: "Contact Sync", type: "Toggle" },
];

export const getContactSyncFormSchema = (Localise, messages) =>
  Yup.object().shape({
    userName: Yup.string().required(
      Localise(messages, "Please enter User Name")
    ),
  });

export const getContactSyncSettings = (preferences) => {
  const contactSyncPref = preferences?.find(
    (ele) => ele.id === "contact_sync_settings"
  );
  const contactSyncPrefValues = get(contactSyncPref, "values.0", "");

  let contactSyncSettings;
  try {
    contactSyncSettings = contactSyncPrefValues
      ? JSON.parse(contactSyncPrefValues)
      : null;
  } catch (error) {
    console.log("Failed to parse contact sync settings - ", error);
  }

  if (contactSyncSettings) {
    return {
      userName: contactSyncSettings.ctct_accountInfo?.userName || "",
      contactSync: contactSyncSettings.contactSync || false,
    };
  }
  return ContactSyncInitialValues;
};
