import GiftCardInfo from "./giftCard/gift-card-info";
import Activity from "./giftCard/activity";
import { FormField } from "components/elements/forms";
import { useSelector } from "react-redux";
import { selectGiftCardDetails } from "library/sagas/views/home/drawer/gift-cards/selector";

const TextFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  editable: true,
  multiline: false,
  numberOfLines: 1,
};

const GiftCardFields = (Localise, messages) => {
  const giftCardDetails = useSelector(selectGiftCardDetails);
  return {
    section: Localise(messages, "Gift Card Info"),
    fields: [
      {
        title: Localise(messages, "Gift Card #"),
        formFieldElement: FormField,
        formFieldProps: {
          name: "giftCardNumber",
          editable: false,
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Opening Balance"),
        formFieldElement: FormField,
        formFieldProps: {
          editable: false,
          name: "openingBalance",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Opening Balance"),
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Current Balance"),
        formFieldElement: FormField,
        formFieldProps: {
          editable: false,
          name: "currentBalance",
          isPrice: true,
          iconName: "currency-usd",
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
          keyboardType: "numeric",
          placeholder: Localise(messages, "Current Balance"),
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Date Issued"),
        formFieldElement: FormField,
        formFieldProps: {
          editable: false,
          name: "issueDate",
          keyboardType: "numeric",
          iconName: "calendar",
          iconPosition: false,
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Expiration"),
        formFieldElement: FormField,
        formFieldProps: {
          editable: false,
          name: "expirationDate",
          keyboardType: "numeric",
          iconName: "calendar",
          iconPosition: false,
          iconSize: 14,
          iconType: "material-community",
          inputContainerStyle: { padding: 6 },
          leftIconContainerStyle: { paddingRight: 0 },
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Recipient"),
        formFieldElement: FormField,
        formFieldProps: {
          editable: false,
          name: "recipientName",
          placeholder: Localise(messages, "Recipient Name"),
        },
        fieldWidth: "30%",
      },
      {
        title: Localise(messages, "Email"),
        formFieldElement: FormField,
        formFieldProps: {
          ...TextFieldProps,
          name: "recipientEmail",
          placeholder: Localise(messages, "Enter Recipient Email"),
          editable: giftCardDetails?.status === "ACTIVE",
        },
        fieldWidth: "100%",
      },
    ],
  };
};

export const getUIConfig = (Localise, messages) => ({
  sections: [
    {
      section: Localise(messages, "Gift Card Info"),
      component: {
        name: GiftCardInfo,
        props: {
          InputFields: GiftCardFields(Localise, messages),
        },
      },
    },
    {
      section: Localise(messages, "Activity"),
      component: {
        name: Activity,
      },
    },
  ],
});
