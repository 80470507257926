/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Input } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "react-native-elements";

import { DeviceContext } from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import {
  selectLoading,
  selectActions,
  selectPageLimit,
} from "library/sagas/views/home/drawer/orders-new/selector";
import {
  setPageInitialise,
  setPageActions,
  fetchOrders,
  resetSlice,
} from "library/sagas/views/home/drawer/orders-new/slice";
import {
  TabBarNew,
  CustomModal,
  PrintIframeRenderer,
} from "components/elements";
import AGGridContainer from "components/containers/ag-grid";
import OrderDetails from "components/views/drawer/order-details";
import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";

import IMAGES from "static/assets/images";
import { colors, backgroundColors } from "styles/theme";
import { fonts } from "styles/theme";
import PageStorage from "library/storage/pageStorage";

import debounce from "lodash/debounce";
import tw from "tailwind-rn";

import { orderGroupsConfig } from "./config";
import { columnDefs } from "./config";
import "./styles.css";

const OrdersNew = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const shopCode = useSelector(selectDashboardShopCode);
  const pageLimit = useSelector(selectPageLimit);
  const {
    searchQuery = "",
    orderGroups = [],
    gridState = [],
  } = useSelector(selectActions);
  const gridRef = useRef(null);
  const { isDesktop } = useContext(DeviceContext);

  const [isGridReset, setGridReset] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [resetModalVisible, setResetModalVisibile] = useState(false);
  const [columnDefination, setColumnDefination] = useState([]);

  let gridAdditionalProps = {};
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const sendingMember = shopCode === "all" ? memberCodes[0] : shopCode;
  const columns = (
    Object.keys(columnDefination).length === gridState.length
      ? gridState
      : Object.keys(columnDefination)
  ).map((item) => {
    const key = typeof item === "string" ? item : item.colId;
    const col = gridState.find((e) => e.colId === key) || {};

    return {
      ...columnDefination[key],
      hide: col.hide ?? columnDefination[key]?.hide,
      width: col.width ?? columnDefination[key]?.width,
      appliedFilter: col.appliedFilter ?? columnDefination[key]?.appliedFilter,
      appliedSort: col.appliedSort ?? columnDefination[key]?.appliedSort,
    };
  });

  const saveGridState = debounce(() => {
    const gridCurrentState =
      gridRef?.current?.columnApi?.getColumnState() ?? [];
    isGridReset
      ? setGridReset(false)
      : dispatch(
          setPageActions({
            type: "saveGridState",
            value: gridCurrentState.map((e) => {
              const colId = e?.colId ?? "";

              return {
                ...(gridState.find((j) => j.colId === e.colId) || {}),
                colId,
                hide: e?.hide ?? columnDefination[colId]?.hide,
                width: e?.width ?? columnDefination[colId]?.width,
              };
            }),
          })
        );
  }, 100);

  const refreshGrid = () => {
    gridRef?.current?.api?.paginationGoToFirstPage();
    gridRef?.current?.api?.setServerSideDatasource(null);
    setGridDataSource();
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      refreshGrid();
    }, 1000),
    []
  );

  const setGridDataSource = () => {
    const dataSource = {
      getRows: function (params) {
        gridAdditionalProps?.togglePaginationControls(false);
        dispatch(
          fetchOrders({
            sendingMember,
            offset: gridRef?.current?.api?.paginationGetCurrentPage() * 10,
            callback: ({ data = [], totalRows = 0 }) => {
              params.successCallback(data, Number(totalRows));
              gridAdditionalProps?.setLastRowOnPage(params.request.endRow);
              gridAdditionalProps?.togglePaginationControls(true);
            },
          })
        );
      },
    };

    gridRef?.current?.api?.setServerSideDatasource(dataSource);
  };

  const resetModalContent = {
    content: (
      <Text
        style={[
          tw("p-4"),
          { fontSize: 15, color: colors.highlighter, textAlign: "left" },
        ]}
      >
        {
          "Are you sure you want to reset your grid settings? This will: \n\n - Restore all columns to their default view and positions. \n - Remove any filters or sorting currently applied. \n - Clear your saved preferences. \n\n You can always customize your view again after the reset."
        }
      </Text>
    ),
    buttons: [
      { type: "secondary", title: "Cancel" },
      { type: "primary", title: "Reset Settings" },
    ],
  };

  useEffect(() => {
    setColumnDefination(columnDefs(dispatch, gridState, refreshGrid));
  }, [gridState]);

  useEffect(() => {
    dispatch(setPageInitialise());
    setTimeout(() => {
      setGridDataSource();
    }, 0);
  }, []);

  return (
    <View style={tw("flex flex-1 py-1")}>
      <View
        style={tw("flex flex-row flex-wrap items-center justify-between px-2")}
      >
        <View style={tw("flex-row items-center")}>
          <Input
            style={{
              padding: 5,
              height: 30,
              width: 250,
            }}
            errorStyle={{ paddingBottom: 0 }}
            onChangeText={(val) => {
              dispatch(setPageActions({ type: "searchQuery", value: val }));
              debouncedSearch();
            }}
            value={searchQuery}
            placeholder={"Search by any column"}
            rightIcon={
              searchQuery.length > 0 ? (
                <Text
                  style={{
                    paddingRight: 5,
                    fontWeight: "bold",
                  }}
                  onPress={() => {
                    dispatch(
                      setPageActions({ type: "searchQuery", value: "" })
                    );
                    debouncedSearch();
                  }}
                >
                  X
                </Text>
              ) : undefined
            }
          />
        </View>
        <View style={tw("flex flex-row")}>
          {isLoading && (
            <ActivityIndicator
              style={{ marginRight: 5 }}
              color={colors.activityIndicator}
              testID={"loader"}
            />
          )}
          <TabBarNew
            groups={orderGroupsConfig}
            selectedGroups={orderGroups}
            onSelectionChange={(category, value) => {
              dispatch(
                setPageActions({
                  type: "orderGroups",
                  value: { category, value },
                })
              );
              refreshGrid();
            }}
          />
        </View>
        <View style={tw("flex flex-row")}>
          {gridState.length > 0 && (
            <TouchableOpacity
              onPress={() => {
                setResetModalVisibile(true);
              }}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["reset"]}
              />
            </TouchableOpacity>
          )}
          <View style={tw("mx-2")} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              name={"info"}
              type={"simple-line-icon"}
              color={colors.primary}
              size={15}
              style={tw("ml-1")}
            />
            <View style={{ marginRight: 4 }} />
            <TouchableOpacity
              onPress={() => {
                PageStorage.setShowNewOrdersPage(
                  "set_show_new_orders_page",
                  false
                );
                window.location.reload();
              }}
            >
              <Text style={[fonts.link1, { textDecorationLine: "underline" }]}>
                {"Back to old view"}
              </Text>
            </TouchableOpacity>
            <View style={{ marginRight: 10 }} />
            <TouchableOpacity
              onPress={() => {
                gridAdditionalProps?.toggleColumnPanel();
              }}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["pivot_menu"]}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={tw("my-1")} />
      <AGGridContainer
        onGridReady={(instance, props) => {
          gridRef.current = instance;
          gridAdditionalProps = props;
        }}
        paginationPageSize={pageLimit}
        columnDefs={columns}
        onColumnMoved={saveGridState}
        onColumnResized={saveGridState}
        onColumnVisible={saveGridState}
        onFilterChanged={refreshGrid}
        onRowClicked={(event) => {
          const columnId =
            event?.event?.target?.closest(".ag-cell")?.getAttribute("col-id") ??
            "";
          ![
            columnDefination.fulfillment.colId,
            columnDefination.actions.colId,
          ].includes(columnId) && setOrderDetailsData(event.data);
        }}
        getMainMenuItems={(params) => {
          return columnDefination[params.column.colId].filterParams.sortOptions;
        }}
      />
      {orderDetailsData && (
        <div
          className="overlay-wrapper"
          onClick={() => {
            setOrderDetailsData(null);
          }}
        >
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            {orderDetailsData && (
              <OrderDetails
                isSmallScreen={!isDesktop}
                recordId={orderDetailsData.correlation_id}
                deliveryMethod={orderDetailsData.fulfillment}
                sourceMemberCode={sendingMember}
                actionsList={ListOfActions}
                subActionsList={ListOfSubActions}
                onComplete={() => {
                  setOrderDetailsData(null);
                }}
                onAction={() => {}}
                setMenuAction={() => {}}
                recordAction={""}
                menuAction={""}
              />
            )}
          </div>
        </div>
      )}
      <CustomModal
        modalVisible={resetModalVisible}
        modalContent={resetModalContent}
        primaryhandler={() => {
          setGridReset(true);
          setResetModalVisibile(false);
          dispatch(resetSlice());
          refreshGrid();
        }}
        secondaryhandler={() => {
          setResetModalVisibile(false);
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
      ></CustomModal>
      <PrintIframeRenderer />
    </View>
  );
};

export default OrdersNew;
