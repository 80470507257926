import * as Yup from "yup";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { isCvvRequired } from "library/utils/payment-options";

export const ccdTypeRegex = {
  visa: /^(?:4[0-9]{3})$/,
  mastercard:
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|2720|27[01][0-9])$/,
  americanexpress: /^(?:3[47][0-9]+)$/,
  discover: /^(6011)$/,
  diners: /^(?:3(?:0[0-5]|[68][0-9])[0-9])$/,
};

export const ccdRegex = {
  visa: "^(?:4[0-9]{12})(?:[0-9]{3})?$",
  mastercard:
    "^(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})",
  americanexpress: "^(?:3[47][0-9]{13})",
  discover: "^(6011[0-9]{12})",
  diners: "^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})",
};

export const ccdErrors = {
  visa: "Visa requires 13 or 16 digits",
  mastercard: "Mastercard requires 16 digits",
  americanexpress: "AMEX requires 15 digits",
  discover: "Discover requires 15 or 16 digits",
  diners: "Diners cards should have 14 digits",
};

export const getCardType = (value) => {
  const firstFourChar = value.slice(0, 4);
  let ccdType;

  Object.keys(ccdTypeRegex).forEach((key) => {
    if (firstFourChar.match(ccdTypeRegex[key])) {
      ccdType = key;
    }
  });

  return ccdType;
};

const isStorePickup = (value) => value === "STORE_PICKUP";
const isWalkIn = (value) => value === "WALK_IN";
const isDeliveryService = (value = "") =>
  (value && value === "DELIVERY_SERVICE") || false;

export default function getOrderDetailsValidationSchema(
  Localise,
  messages,
  values = {}
) {
  const { customerInfo } = values;

  Yup.addMethod(Yup.string, "isValidCard", function (errorMessage) {
    return this.test(`is-valid-card`, errorMessage, function (value = "") {
      const { actualCardNumber = "" } = this.parent;
      if (!actualCardNumber) return true;
      const trimmedValue = actualCardNumber.split(" ").join("");
      if (trimmedValue.substring(0, 3) === "***") return true;
      if (isNaN(trimmedValue)) return false;
      var cardType = getCardType(trimmedValue);
      if (!cardType) return false;
      const { path, createError } = this;
      var regEx = new RegExp(ccdRegex[cardType]);

      return (
        regEx.test(trimmedValue) ||
        createError({
          path,
          message:
            Localise(messages, ccdErrors[cardType]) ||
            Localise(messages, "Invalid Card Number"),
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "invalidPickupTimeOne", function (errorMessage) {
    return this.test("invalid-pickup-time-one", errorMessage, function () {
      const { path, createError, parent } = this;
      const { pickUpDateTime, shopDayTimings } = parent;

      if (!pickUpDateTime) return true;

      let pickUpTimeWithDate = moment(pickUpDateTime);
      let openingTime = moment(shopDayTimings.open);
      let closingTime = moment(shopDayTimings.close);

      const isValid =
        moment().isBefore(pickUpTimeWithDate) &&
        openingTime.isSameOrBefore(pickUpTimeWithDate) &&
        closingTime.isSameOrAfter(pickUpTimeWithDate);

      return isValid || createError({ path, message: errorMessage });
    });
  });

  Yup.addMethod(Yup.string, "isValidAmount", function (errorMessage) {
    return this.test(`is-valid-amount`, errorMessage, function (value) {
      const { tenderedAmount, orderTotal } = this.parent;

      return !(
        isNaN(tenderedAmount) || parseFloat(tenderedAmount) < orderTotal
      );
    });
  });

  Yup.addMethod(Yup.string, "isValidExpDate", function (errorMessage) {
    return this.test("is-valid-expDate", errorMessage, function (value) {
      const { path, createError } = this;

      if (!value) {
        return createError({ path, message: errorMessage });
      }
      const [expMonth, expYear] = value.split("/") || [];
      const month = parseInt(expMonth, 10);
      const year =
        expYear?.length === 2
          ? 2000 + parseInt(expYear, 10)
          : parseInt(expYear, 10);
      if (
        !expMonth ||
        !expYear ||
        isNaN(month) ||
        month < 1 ||
        month > 12 ||
        isNaN(year)
      ) {
        return createError({ path, message: errorMessage });
      }
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1;
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.string, "isValidRefundAmount", function (errorMessage) {
    return this.test(`is-valid-refund-amount`, errorMessage, function (value) {
      const { path, createError, parent } = this;
      const { isRefundDue, newTotalAmount, refundAvailable } = parent;

      if (!value || isRefundDue === undefined) return true;

      if (
        isRefundDue &&
        parseFloat(refundAvailable) < parseFloat(newTotalAmount)
      ) {
        return createError({ path, message: errorMessage });
      }

      return true;
    });
  });

  Yup.addMethod(
    Yup.string,
    "isPaymentTypeSelectedOne",
    function (errorMessage) {
      return this.test(
        "is-payment-type-selected-one",
        errorMessage,
        function (paymentType) {
          const { path, createError, parent } = this;
          const { newTotalAmount, isRefundDue, hasSettlementError } = parent;

          if (
            paymentType === undefined &&
            parseFloat(newTotalAmount) > 0 &&
            ((isRefundDue !== undefined && !isRefundDue) || hasSettlementError)
          ) {
            return createError({ path, message: errorMessage });
          }

          return true;
        }
      );
    }
  );

  const creditCardObject = {
    name: Yup.string()
      .matches(
        new RegExp("^[-\\sa-zA-Z.]+$"),
        Localise(messages, "Invalid Name")
      )
      .required(Localise(messages, "Enter Name")),
    cardNumber: Yup.string()
      .required(Localise(messages, "Please enter Card Number"))
      .isValidCard(Localise(messages, "Invalid Card Number")),
    expDate: Yup.string()
      .matches(
        new RegExp("^(0[1-9]|1[0-2])(/)?([0-9]{4}|[0-9]{2})$"),
        Localise(messages, "Invalid Expiration Date")
      )
      .required(Localise(messages, "Enter Expiration Date"))
      .isValidExpDate(Localise(messages, "Invalid Expiration Date")),
    cvv: Yup.string()
      .matches(new RegExp("^[0-9]{3,4}$"), Localise(messages, "Invalid CVV"))
      .when("isSwipedCard", {
        is: (value) => !value,
        then: Yup.string().test(
          "is-cvv-required",
          Localise(messages, "Enter CVV"),
          function (cvv) {
            const paymentMethodType = get(this, "from.1.value.paymentType", "");
            return isCvvRequired(paymentMethodType, cvv);
          }
        ),
      }),
    zip: Yup.string().when("country", {
      is: (value) => value && value === "US",
      then: Yup.string()
        .matches(
          new RegExp("^[0-9]{5}([-][0-9]{4})?\\s*$"),
          Localise(messages, "Invalid Billing Zip")
        )
        .required(Localise(messages, "Enter Billing Zip")),
      otherwise: Yup.string().when("country", {
        is: (value) => value && value === "CA",
        then: Yup.string()
          .matches(
            new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
            Localise(messages, "Invalid Billing Zip")
          )
          .required(Localise(messages, "Enter Billing Zip")),
        otherwise: Yup.string().required(
          Localise(messages, "Enter Billing Zip")
        ),
      }),
    }),
  };

  const billingInfoValidations = {
    addressLine1: Yup.string()
      .matches(
        new RegExp("^[-\\sa-zA-Z0-9./]+$"),
        Localise(messages, "Invalid Billing Address")
      )
      .required(Localise(messages, "Enter Billing Address")),
    city: Yup.string().required(Localise(messages, "Enter City name")),
    state: Yup.string().when("country", {
      is: (value) => (value && value === "US") || value === "CA",
      then: Yup.string()
        .matches(
          new RegExp("^[a-zA-Z]{2}$"),
          Localise(messages, "Invalid State")
        )
        .required(Localise(messages, "State required")),
    }),
    country: Yup.string().required(Localise(messages, "Please select country")),
  };

  return Yup.object().shape({
    orderItems: Yup.array().of(
      Yup.object().shape({
        deliveryInfo: Yup.object().shape({
          deliveryDate: Yup.date().required(
            Localise(messages, "Enter Delivery Date")
          ),
          occasion: Yup.string().required(
            Localise(messages, "Please select occasion")
          ),
          pickupDate: Yup.date().when("deliveryMethod", {
            is: (value) => value && value === "STORE_PICKUP",
            then: Yup.date()
              .required(Localise(messages, "Please enter a Pickup Date"))
              .nullable(),
          }),
          pickUpDateTime: Yup.string().when("deliveryMethod", {
            is: (value) => value && value === "STORE_PICKUP",
            then: Yup.string().required(
              Localise(messages, "Please enter a Pickup Time")
            ),
            // .invalidPickupTimeOne(
            //   Localise(messages, "Please enter a valid Pickup Time")
            // ),
          }),
          pickUpBy: Yup.string().when("deliveryMethod", {
            is: (value) => value && value === "STORE_PICKUP",
            then: Yup.string().required(
              Localise(messages, "Please enter Pickup By")
            ),
          }),
          recipientInfo: Yup.object().when("deliveryMethod", {
            is: (value) => value && value !== "WALK_IN",
            then: Yup.object().shape({
              firstName: Yup.string()
                .min(
                  2,
                  Localise(
                    messages,
                    "Recipient First Name must have at least 2 characters"
                  )
                )
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "Enter Recipient First Name")
                  ),
                }),
              lastName: Yup.string()
                .min(
                  2,
                  Localise(
                    messages,
                    "Recipient Last Name must have at least 2 characters"
                  )
                )
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "Enter Recipient Last Name")
                  ),
                }),
              addressLine1: Yup.string()
                .min(2, Localise(messages, "Invalid Street Address"))
                .max(1000, Localise(messages, "Invalid Street Address"))
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "Enter Street Address")
                  ),
                }),
              addressLine2: Yup.string().min(
                1,
                Localise(messages, "Invalid suite")
              ),
              country: Yup.string().when("deliveryMethod", {
                is: (value) => value && !isStorePickup(value),
                then: Yup.string().required(
                  Localise(messages, "Country required")
                ),
              }),
              state: Yup.string()
                .when("country", {
                  is: (value) => (value && value === "US") || value === "CA",
                  then: Yup.string().matches(
                    new RegExp("^[a-zA-Z]{2}$"),
                    Localise(messages, "Invalid State")
                  ),
                })
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "State required")
                  ),
                }),
              city: Yup.string()
                .min(2, Localise(messages, "Invalid City Name"))
                .max(256, Localise(messages, "Invalid City Name"))
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "Enter City name")
                  ),
                }),
              zip: Yup.string()
                .when("country", {
                  is: (value) => value && value === "US",
                  then: Yup.string().matches(
                    new RegExp("^[0-9]{5}$"),
                    Localise(messages, "Invalid Postal Code")
                  ),
                  otherwise: Yup.string().when("country", {
                    is: (value) => value && value === "CA",
                    then: Yup.string().matches(
                      new RegExp(
                        "^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"
                      ),
                      Localise(messages, "Invalid Postal Code")
                    ),
                  }),
                })
                .when("deliveryMethod", {
                  is: (value) => value && !isStorePickup(value),
                  then: Yup.string().required(
                    Localise(messages, "Postal Code required")
                  ),
                }),
              locationType: Yup.string().when("deliveryMethod", {
                is: isDeliveryService,
                then: Yup.string().required(
                  Localise(messages, "Select Location Type")
                ),
              }),
              locationName: Yup.string()
                .min(
                  3,
                  Localise(
                    messages,
                    "Location Name must have at least 3 characters"
                  )
                )
                .when("deliveryMethod", {
                  is: (value) =>
                    value && !isStorePickup(value) && !isWalkIn(value),
                  then: Yup.string().when(["deliveryMethod", "locationType"], {
                    is: (deliveryMethod, locationType) =>
                      deliveryMethod &&
                      locationType !== undefined &&
                      locationType !== "Residence",
                    then: Yup.string().required(
                      Localise(messages, "Enter Location Name")
                    ),
                  }),
                }),
            }),
          }),
        }),
        productInfo: Yup.object().shape({
          updatedLineItems: Yup.array().of(
            Yup.object().shape({
              price: Yup.string()
                //.required(Localise(messages, "Invalid Price"))
                .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price")),
              newPrice: Yup.string().matches(
                /^\d+\.?\d*$/,
                Localise(messages, "Invalid Price")
              ),
              refundAmount: Yup.string().matches(
                /^\d+\.?\d*$/,
                Localise(messages, "Invalid Price")
              ),
              refundDeliveryFee: Yup.string().matches(
                /^\d+\.?\d*$/,
                Localise(messages, "Invalid Price")
              ),
            })
          ),
        }),
      })
    ),
    ...(!isEmpty(customerInfo) && {
      customerInfo: Yup.object().shape(
        {
          customerType: Yup.string().required(
            Localise(messages, "Please select Customer Type")
          ),
          firstName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter First Name")
            ),
          }),
          lastName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter Last Name")
            ),
          }),
          businessName: Yup.string().when("customerType", {
            is: (value) => value === "Business",
            then: Yup.string().required(
              Localise(messages, "Please enter Business Name")
            ),
          }),
          phone: Yup.string().when("email", {
            is: (val) => !val || val.length === 0,
            then: Yup.string()
              .matches(
                new RegExp(
                  "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                ),
                Localise(messages, "Enter valid Phone")
              )
              .required(Localise(messages, "Please enter phone number")),
          }),
          email: Yup.string()
            .email("Enter valid email")
            .matches(
              /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
              "Invalid Email address"
            )
            .when("phone", {
              is: (val) => !val || val.length === 0,
              then: Yup.string().required(
                Localise(messages, "Please enter Email")
              ),
            }),
        },
        ["phone", "email"]
      ),
    }),
    paymentInfo: Yup.object().shape({
      paymentType: Yup.string().isPaymentTypeSelectedOne(
        Localise(messages, "Select Payment Type")
      ),
      newDeliveryFee: Yup.string().matches(
        /^\d+\.?\d*$/,
        Localise(messages, "Invalid Delivery Fee")
      ),
      newRelayFee: Yup.string().matches(
        /^\d+\.?\d*$/,
        Localise(messages, "Invalid Relay Fee")
      ),
      formattedDiscount: Yup.string().matches(
        /^\(?\d+\.?\d*\)?$/,
        Localise(messages, "Invalid Discount")
      ),
      amountDue: Yup.string().isValidRefundAmount(
        Localise(messages, "Refund amount is greater than original total")
      ),
      CREDIT_CARD: Yup.object().when("paymentType", {
        is: (paymentType) =>
          ["CREDIT_CARD", "SAVED_CARD"].includes(paymentType),
        then: Yup.object().when("saveBillingInfo", {
          is: (value) => !!value,
          then: Yup.object({
            ...creditCardObject,
            billingInfo: Yup.object().shape(billingInfoValidations),
          }),
          otherwise: Yup.object({
            ...creditCardObject,
          }),
        }),
        otherwise: Yup.object().when(["paymentType", "saveBillingInfo"], {
          is: (paymentType, saveBillingInfo) =>
            ["PAYMENT_TERMINAL"].includes(paymentType) && saveBillingInfo,
          then: Yup.object({
            zip: Yup.string()
              .required(Localise(messages, "Enter Billing Zip"))
              .matches(
                new RegExp("^[a-zA-Z0-9][a-zA-Z0-9 -]{0,10}[a-zA-Z0-9]$"),
                Localise(messages, "Invalid Billing Zip")
              ),
            billingInfo: Yup.object().shape(billingInfoValidations),
          }),
        }),
      }),
      CASH_OR_CHECK: Yup.object().when("paymentType", {
        is: (value) => value === "CASH_OR_CHECK",
        then: Yup.object({
          tenderedAmount: Yup.string()
            .required(Localise(messages, "Enter Tendered amount"))
            .isValidAmount(Localise(messages, "Invalid Tendered amount")),
        }),
      }),
    }),
  });
}
