import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, View, TouchableOpacity, Platform } from "react-native";
import { Text, Button } from "react-native-elements";

import {
  FormField,
  FormFieldSwitch,
  FormFieldPicker,
  FormFieldDatePicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";

import {
  selectExceptionCities,
  selectExceptionZips,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { fetchZipCodesForExceptions } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { getNewDatesTimesObj } from "./helper";
import { getExceptionsConfig } from "../ui-config";
import styles from "../../../mercury-online/website-status/suspensions/styles";

import { useFormikContext } from "formik";
import get from "lodash/get";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { theme, fonts } from "styles/theme";
import tw from "tailwind-rn";

function ExceptionInput({ criteriaChangeHandler, deleteExceptionHandler, i }) {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values: exception,
    dirty,
  } = useFormikContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const { Localise, messages } = React.useContext(I18NContext);
  const ExceptionsConfig = getExceptionsConfig(Localise, messages);

  const exceptionCities = useSelector(selectExceptionCities);
  const exceptionZips = useSelector(selectExceptionZips);
  const scrollViewRef = useRef();

  const [dayQuery, setDayQuery] = useState("");
  const [zipQuery, setZipQuery] = useState("");

  const {
    status,
    criteria,
    day,
    startDate,
    cityZipInfo = { city: "" },
  } = exception;
  const { isDesktop, isMobile } = React.useContext(DeviceContext);

  const zipCodes = exceptionZips[cityZipInfo.city] || [];
  const zIndex = 100;

  const cityChangeHandler = (val, setFieldValue) => {
    if (val && !Object.keys(exceptionZips).includes(val)) {
      dispatch(fetchZipCodesForExceptions(val));
    }
    setFieldValue("cityZipInfo.zips", []);
  };

  const FieldTitle = ({ title }) => (
    <View style={{ width: 100, marginTop: 16 }}>
      <Text style={fonts.heading4}>{Localise(messages, title)}</Text>
    </View>
  );

  const DateField = ({ name, endDatePath }) => {
    const errorName =
      get(errors, "endDate", "") || get(errors, "startDate", "");
    const showError =
      name === "endDate" &&
      (get(touched, "endDate", "") || get(errors, "startDate", "")) &&
      errorName;

    return (
      <View>
        <FormFieldDatePicker
          dateFormat={Platform.OS === "web" ? "MM/dd/yy" : "MM/DD/YY"}
          dateValueFormat="YYYY-MM-DD"
          name={name}
          placeholder={`${
            name === "endDate"
              ? Localise(messages, "End Date")
              : Localise(messages, "Start Date")
          }`}
          iconName="calendar"
          alignIconRight={true}
          errorStyle={{ display: "none" }}
          onCalendarOpen={() =>
            setTimeout(() => {
              scrollViewRef.current.scrollToEnd({ animated: true });
            }, 0)
          }
          containerStyle={{
            width: Platform.OS === "web" && isDesktop ? 115 : 130,
            height: showError ? "auto" : 50,
          }}
          onValueChange={() => {
            endDatePath && setFieldTouched && setFieldTouched(endDatePath);
          }}
          popperPlacement={"bottom"}
          popperModifiersOverride={{
            offset: {
              enabled: true,
              offset: "0px, -40px",
            },
          }}
        />
        {!!showError && <Text style={styles.dateFieldsError}>{errorName}</Text>}
      </View>
    );
  };

  return (
    <>
      <View>
        <ScrollView
          ref={scrollViewRef}
          contentContainerStyle={tw("flex flex-row flex-wrap items-start")}
          {...(Platform.OS === "android" && {
            nestedScrollEnabled: true,
          })}
        >
          <View style={{ width: "100%" }}>
            <View style={[tw("flex-row items-start"), { marginBottom: 5 }]}>
              <FieldTitle title={"Label"} />
              <View style={{ width: 200 }}>
                <FormField
                  name={`label`}
                  placeholder={Localise(messages, "Add Label")}
                  isUpdateOnChange={true}
                />
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 50 },
              ]}
            >
              <FieldTitle title={"Criteria"} />
              <View style={{ width: 200 }}>
                <>
                  <FormFieldPicker
                    placeholder={ExceptionsConfig.criteriaDropdown.placeholder}
                    containerStyle={{
                      paddingBottom: 10,
                    }}
                    data={ExceptionsConfig.criteriaDropdown.data}
                    name={"criteria"}
                    defaultValue={""}
                    onChange={(val) =>
                      setTimeout(
                        () =>
                          criteriaChangeHandler &&
                          criteriaChangeHandler(val, setFieldValue, exception),
                        0
                      )
                    }
                  />
                  {criteria === "CITY_ZIP_CODE" && (
                    <>
                      <FormFieldPicker
                        placeholder={{
                          label: Localise(messages, "Select City"),
                          value: "",
                        }}
                        containerStyle={{
                          paddingBottom: 10,
                        }}
                        data={exceptionCities}
                        name="cityZipInfo.city"
                        defaultValue={""}
                        onChange={(val) =>
                          setTimeout(
                            () => cityChangeHandler(val, setFieldValue),
                            0
                          )
                        }
                      />
                      <FormFieldAutoComplete
                        name={"cityZipInfo.zips"}
                        labelStyle={{
                          ...fonts.heading4,
                        }}
                        listStyle={{
                          paddingVertical: 5,
                        }}
                        data={zipCodes.filter((e) =>
                          toLower(e.label).includes(toLower(zipQuery))
                        )}
                        initialDataLength={zipCodes.length}
                        showOnFocus={true}
                        setFocusBack={true}
                        placeholder={
                          cityZipInfo?.zips?.length
                            ? cityZipInfo?.zips?.length > 1
                              ? Localise(messages, "Multi")
                              : cityZipInfo?.zips?.join("")
                            : Localise(messages, "All Zips")
                        }
                        listDisplayValues={["label"]}
                        outerContainerStyle={{
                          zIndex: 1,
                        }}
                        isMultiSelect={true}
                        onSelect={(selectedValue) => {
                          const newZips = [...(cityZipInfo?.zips || [])];
                          const index = newZips?.indexOf(selectedValue.value);
                          const unSelected = index >= 0;
                          unSelected
                            ? newZips?.splice(index, 1)
                            : newZips?.push(selectedValue.value);
                          setFieldValue("cityZipInfo.zips", newZips);
                        }}
                        onChangeText={(val) => setZipQuery(val)}
                      />
                    </>
                  )}
                </>
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 45 },
              ]}
            >
              <FieldTitle title={"Cutoff"} />
              <View style={{ width: 200 }}>
                <>
                  <View
                    style={[
                      tw("flex-row items-start"),
                      { marginBottom: 5, zIndex: zIndex + 44 },
                    ]}
                  >
                    <FormFieldDatePicker
                      dateFormat="h:mm a"
                      name="cutOffTime"
                      placeholder={Localise(messages, "Select Time")}
                      iconName="clock"
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      timeIntervals={30}
                      timeCaption=""
                      alignIconRight={true}
                      errorStyle={{ paddingVertical: 0 }}
                      containerStyle={{ width: "100%" }}
                      popperPlacement="bottom"
                      dateValueFormat={"YYYY-MM-DDTHH:mm:ss"}
                      editable={Platform.OS !== "web"}
                      onCalendarOpen={() =>
                        setTimeout(() => {
                          scrollViewRef.current.scrollToEnd({ animated: true });
                        }, 0)
                      }
                    />
                  </View>
                  <View
                    style={[
                      tw("flex-row items-start"),
                      { marginBottom: 5, zIndex: zIndex + 43 },
                    ]}
                  >
                    <FormFieldAutoComplete
                      name={"day"}
                      labelStyle={{
                        ...fonts.heading4,
                      }}
                      listStyle={{
                        paddingVertical: 5,
                      }}
                      data={ExceptionsConfig.cutoffDaysDropdown.data.filter(
                        (e) => toLower(e.label).includes(toLower(dayQuery))
                      )}
                      initialDataLength={
                        ExceptionsConfig.cutoffDaysDropdown.data?.length
                      }
                      showOnFocus={true}
                      setFocusBack={true}
                      placeholder={
                        day?.length
                          ? day?.length > 1
                            ? Localise(messages, "Multi")
                            : startCase(toLower(day?.join("")))
                          : ExceptionsConfig.cutoffDaysDropdown.placeholder
                      }
                      listDisplayValues={["label"]}
                      outerContainerStyle={{
                        zIndex: 1,
                        width: "100%",
                      }}
                      isMultiSelect={true}
                      onSelect={(selectedValue) => {
                        const newDays = [...(day || [])];
                        const index = newDays?.indexOf(selectedValue.value);
                        const unSelected = index >= 0;
                        unSelected
                          ? newDays?.splice(index, 1)
                          : newDays?.push(selectedValue.value);
                        setFieldValue("day", newDays);
                        setDayQuery("");
                      }}
                      onChangeText={(val) => setDayQuery(val)}
                    />
                  </View>
                </>
              </View>
            </View>
            <View
              style={[
                tw("flex-row items-start"),
                { marginBottom: 5, zIndex: zIndex + 40 },
              ]}
            >
              <View style={{ width: 100, marginTop: 16 }}>
                <Text style={fonts.heading4}>
                  {Localise(messages, "Status")}
                </Text>
              </View>
              <View style={{ width: 200 }}>
                <View
                  style={{
                    paddingLeft: 5,
                    paddingBottom: 10,
                    paddingTop: 15,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormFieldSwitch
                    value={status}
                    name="status"
                    onValueChange={() => {
                      setFieldValue("status", !status);
                    }}
                  />
                  <View
                    style={{
                      marginLeft: 10,
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) =>
                        getNewDatesTimesObj(startDate, setFieldValue)
                      }
                      testID={"add_dates_times"}
                      accessibilityLabel={"add_dates_times"}
                    >
                      <View>
                        <Text style={fonts.link1}>
                          {startDate
                            ? Localise(messages, "Clear Dates & Times")
                            : Localise(messages, "Add Dates & Times")}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
            {startDate ? (
              <View
                style={[
                  tw("flex-row items-start"),
                  { marginBottom: 5, zIndex: zIndex + 35 },
                ]}
              >
                <View style={{ width: 100, marginTop: 16 }}>
                  <Text style={fonts.heading4}>
                    {Localise(messages, "Date")}
                  </Text>
                </View>
                <View>
                  <View
                    style={[
                      tw(
                        `flex flex-row justify-between items-${
                          Platform.OS !== "web" ? "start" : "baseline"
                        } pl-3 py-2`
                      ),
                      styles.datesSection,
                      { zIndex: zIndex + 30 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 30 }}>
                      {Localise(messages, "Start")}
                    </Text>
                    <DateField name={`startDate`} endDatePath=" endDate" />
                  </View>
                  <View
                    style={[
                      tw(
                        `flex flex-row justify-between items-${
                          Platform.OS !== "web" ? "start" : "baseline"
                        } pl-3 py-2`
                      ),
                      styles.datesSection,
                      { zIndex: zIndex + 25 },
                    ]}
                  >
                    <Text style={{ ...fonts.heading5, width: 30 }}>
                      {Localise(messages, "End")}
                    </Text>
                    <DateField name={`endDate`} />
                  </View>
                </View>
              </View>
            ) : null}
            <View
              style={[
                tw(
                  `${
                    exception.exceptionId ? "justify-between" : "justify-end"
                  } flex-${isMobile && exception.exceptionId ? "col" : "row"}`
                ),
                { zIndex: zIndex - 2 },
              ]}
            >
              {exception.exceptionId ? (
                <View
                  style={tw(`justify-between flex-${isMobile ? "col" : "row"}`)}
                >
                  <Button
                    testID={"delivery_exception"}
                    accessibilityLabel="delivery_exception"
                    titleStyle={{
                      ...theme.Button.secondaryTitleStyle,
                    }}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                    }}
                    containerStyle={{ marginLeft: 0 }}
                    title={Localise(messages, "Delete")}
                    onPress={(e) => deleteExceptionHandler(i, exception)}
                  />
                </View>
              ) : null}
              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                customContainerStyles={{
                  width: 100,
                  marginLeft: 0,
                }}
                onCancel={() =>
                  !exception.exceptionId && deleteExceptionHandler(i, exception)
                }
                disableOnDirty={!dirty}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

export default ExceptionInput;
