import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text } from "react-native";

import { ToasterHandler, AccordionWithBorder } from "components/elements";
import { TaxRulesFields } from "./ui-config";
import {
  Form,
  FormField,
  FormFieldRadioButtons
} from "components/elements/forms";

import I18NContext from "library/contexts/i18N";
import { selectSiteFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import { saveGlobalFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import tw from "tailwind-rn";
import { formatPrice } from "library/utils/formatter";

import { DeviceContext } from "library/contexts/appSettings";
import { fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";

import { request } from "library/utils/request";
import get from "lodash/get";
import Environment from "library/utils/environment";

import UserProfileStorage from "library/storage/userProfile";

import {
  selectShopCode,
  selectTaxAccordianStatus,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { SaveCancelButtons } from "components/wrappers";

const TaxRules = () => {
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);

  const initialValues = useSelector(selectSiteFees);
  const [taxRate, setTaxRate] = useStateIfMounted("0.00");

  const showRelayFee = Environment.get("SHOW_RELAY_FEE", false);

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveGlobalFees({
        params: { ...values, ...true, showRelayFee },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Your Tax Settings have been updated")
          );

          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const shopCode = useSelector(selectShopCode);
  const isTaxAccordianOpen = useSelector(selectTaxAccordianStatus);

  const shopLocations = UserProfileStorage.getAllShopLocation();

  const {
    city: shopCity = "",
    state: shopState = "",
    zip: shopZipCode = "",
    countryCode: shopCountry = "",
    county: shopCounty = "",
  } = shopLocations[shopCode] || {};

  const getTaxRate = () => {
    const req = {
      requests: [
        {
          siteId: "ftd",
          storeId: null,
          deliveryType: "domestic",
          amount: 0,
          requestId: "Item" + Math.random() * 10000,
          feeType: "Base Fee",
          address: {
            city: shopCity,
            county: shopCounty || null,
            country: shopCountry,
            state: shopState,
            zipCode: shopZipCode,
            isDestination: false,
          },
          product: {
            subType: "",
            taxCategoryId: null,
            type: "",
          },
          productCategoryName: "",
          productId: "",
        },
      ],
    };

    request("get-tax", req)
      .then((res) => {
        const { rate = 0.0 } = get(res, "responses.0.totalTax", 0);
        setTaxRate(formatPrice(rate * 100));
      })
      .catch((err) => {
        if (err !== "AbortError")
          console.log("Failed to get Taxes ---" + JSON.stringify(err));
      });
  };

  useEffect(() => {
    getTaxRate();
  }, []);

  return (
    <AccordionWithBorder
      title={Localise(messages, "Tax Settings")}
      overrideZindex={1}
      defaultOpen={isTaxAccordianOpen}
    >
      <Form
        initialValues={initialValues}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
        render={() => {
          return (
            <>
              <View style={[tw("flex flex-row items-center pb-2")]}>
                <Text
                  style={{
                    width: isMobile ? 150 : 200,
                    ...fonts.heading4,
                  }}
                  testID={"tax_rate"}
                  accessibilityLabel={"tax_rate"}
                >
                  {Localise(messages, "Tax")}
                </Text>

                <FormField
                  name="tax_percentage"
                  label={""}
                  value={taxRate}
                  containerStyle={{ width: 100, paddingHorizontal: 5 }}
                  iconType="font-awesome"
                  iconName="percent"
                  iconPosition={false}
                  iconSize={12}
                  inputContainerStyle={{
                    padding: 6,
                  }}
                  leftIconContainerStyle={{ paddingRight: 0 }}
                  isPrice={true}
                  editable={false}
                  grayedOutOnDisable={true}
                />
              </View>

              {TaxRulesFields().map(({ formFieldProps, name, title }, i) => {
                return (
                  <View style={[tw("flex flex-row items-center pb-2")]} key={i}>
                    {title && (
                      <Text
                        style={{
                          width: isMobile ? 150 : 200,
                          ...fonts.heading4,
                          minWidth: 80,
                        }}
                        testID={title}
                        accessibilityLabel={title}
                      >
                        {Localise(messages, title)}
                      </Text>
                    )}
                    {
                      <FormFieldRadioButtons
                        name={name}
                        label={formFieldProps?.title}
                        options={formFieldProps?.options}
                        labelCustomStyle={{
                          width: 100,
                          fontWeight: "normal",
                          paddingBottom: 0,
                        }}
                        {...(!isMobile && {
                          labelOptionsDirection: "row items-center",
                        })}
                        {...(isMobile && {
                          optionsDirection: "col",
                        })}
                      />
                    }
                  </View>
                );
              })}

              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                cancelTestId={"clear_tax_rules"}
                saveTestId={"save_tax_rules"}
              />
            </>
          );
        }}
      />
    </AccordionWithBorder>
  );
};

export default TaxRules;
