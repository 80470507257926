const MOCKJSON = {
  "get-dashboard-widgets": require("./get-dashboard-widgets.json"),
  "get-florists": require("./get-florists.json"),
  "get-florists-new": require("./get-florists-new.json"),
  "get-member-settings": require("./get-member-settings.json"),
  "get-menus": require("./get-menus.json"),
  "get-notifications": require("./get-notifications.json"),
  "get-occasions": require("./get-occasions.json"),
  "get-order-chat": require("./get-order-chat.json"),
  "get-products": require("./get-products.json"),
  "get-promo-code-report-type": require("./get-promo-code-report-type.json"),
  "get-staff-list": require("./get-staff-list.json"),
  "get-staff-profile": require("./get-staff-profile.json"),
  "get-user-profile": require("./get-user-profile.json"),
  "get-user-roles": require("./get-user-roles.json"),
  "order-details": require("./order-details.json"),
  "order-listing": require("./order-listing.json"),
  "get-audible-notification": require("./get-audible-notification.json"),
  "get-card-messages": require("./get-card-messages.json"),
  login: require("./login.json"),
  "get-ftd-user-accounts": require("./get-ftd-user-accounts.json"),
  "get-shop-details": require("./get-shop-details.json"),
  "get-florist-account": require("./get-florist-account.json"),
  "get-feedback-categories-noauth": require("./get-feedback-categories.json"),
  "get-feedback-categories": require("./get-feedback-categories.json"),
  "create-bulk-accounts-mhq": require("./create-bulk-accounts-mhq.json"),
  "create-bulk-accounts-mcloud": require("./create-bulk-accounts-mcloud.json"),
  "get-report-types": require("./get-report-types.json"),
  "get-customers": require("./get-all-customers.json"),
  "tokenize-credit-card": require("./tokenize-credit-card.json"),
  "get-autoroutes-data": require("./get-autoroutes-data.json"),
  "get-delivery-tasks-list": require("./delivery-tasks-listing.json"),
  "get-shop-basic-info": require("./get-shop-basic-info.json"),
  "get-shared-catalogs": require("./get-shared-catalogs.json"),
  "get-shared-catalog-member-mappings": require("./get-shared-catalog-member-mappings.json"),
  "get-customer-activities": require("./get-all-customer-activities.json"),
  "get-auto-print-orders": require("./get-auto-print-orders.json"),
  "get-gift-cards": require("./get-gift-cards.json"),
  "get-gift-card-details": require("./get-gift-card-details.json"),
  "get-order-status-tracking-details": require("./get-order-status-tracking-details.json"),
  "get-terminal-activation-codes": require("./get-terminal-activation-codes.json"),
  "get-orders-new": require("./get-orders-new.json"),
};

export default MOCKJSON;
