import {
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";
import request from "../request";

import UserProfileStorage from "library/storage/userProfile";
import * as Navigation from "library/utils/navigation.js";
import Environment from "library/utils/environment";
import { Currency } from "components/wrappers";

import get from "lodash/get";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import intersection from "lodash/intersection";
import difference from "lodash/difference";
import moment from "moment";

import {
  setInit,
  setAPISuccess,
  setAPIError,
  setBulkUpdates,
  setAPIResponse,
  navigateScreen,
  fetchProductCatalog,
  fetchSRPPrices,
  fetchCollections,
  fetchCatalogSettings,
  fetchExtendedDataCall,
  saveCatalogSettings,
  setPriceMinimumAction,
  applyPriceAdjustmentRule,
  createScheduledPriceAdjustment,
  updateScheduledPriceAdjustment,
  deleteScheduledPriceAdjustment,
  setProductExtraFilters,
  fetchSharedCatalogs,
  setShopCode,
  createMyCatalog,
} from "./slice";

import {
  handleFetchCatalogSettings,
  handleSaveCatalogSettings,
  handleBulkActions,
  handleScheduledPriceAdjustment,
  handleUpdatePriceAdjustment,
  handleDeletePriceAdjustment,
  handleUIRefresh,
} from "./settings";

import {
  selectEnableSharedCatalogs,
  selectProductLookup,
  selectScreen,
  selectShopCode,
} from "../common/selector";
import { selectAPIResponse, selectScheduledPriceAdjustment } from "./selector";

const sectionApiMap = {
  global: "get-mol-catalog",
  local: "get-florist-catalog",
  addons: "get-mol-and-florist-addons",
  collections: "get-all-collections",
};

function* handleInit() {
  yield put(fetchCollections());
  yield put(fetchProductCatalog("global"));
  yield put(fetchProductCatalog("local"));
  yield put(fetchProductCatalog("addons"));
  yield put(fetchCatalogSettings());
  yield put(fetchSharedCatalogs());
}

function* handleNavigation(action = {}) {
  const { name, params, reduxOnly } = get(action, "payload", {});
  const memberCode = yield select(selectShopCode);

  if (reduxOnly) return;

  yield Navigation.navigate({
    name,
    params: {
      ...params,
      handle: params.handle || "",
      member_code: memberCode,
    },
  });
}

function* handleShopCodeChange() {
  const shopCode = yield select(selectShopCode);
  const { name, params } = yield select(selectScreen);

  yield Navigation.navigate({
    name,
    params: {
      ...params,
      handle: params.handle || "",
      member_code: shopCode,
    },
  });

  yield call(handleInit);
}

function* handleFetchSharedCatalogs() {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (shopCode) =>
    request("get-shared-catalogs", { shopCode });
  const section = "sharedCatalogs";

  try {
    const enableSharedCatalogs = yield select(selectEnableSharedCatalogs);

    let response = [];
    if (enableSharedCatalogs) {
      try {
        response = yield call(serviceRequest, shopCode);
      } catch (error) {
        response = []; // api not deployed to production
      }
    }
    const productLookup = yield select(selectProductLookup);

    let content = [
      // clean up once salsify changes are done
      {
        id: "all_catalogs",
        groupId: "all_catalogs",
        name: "All Catalogs",
        products: Object.keys(productLookup).filter(
          (pid) =>
            productLookup[pid] && productLookup[pid].catalogType !== "addons"
        ),
        isGroupActive: true,
        isRestricted: false,
        groupScope: "reserved",
        colorKey: "reserved",
      },
      {
        id: "addons",
        groupId: "addons",
        name: "Add-ons",
        products: Object.keys(productLookup).filter(
          (pid) =>
            productLookup[pid] && productLookup[pid].catalogType === "addons"
        ),
        isGroupActive: true,
        status: "active",
        isRestricted: false,
        groupScope: "addons",
        colorKey: "reserved",
      },
    ];

    if (response && response.length) {
      content = [
        ...content,
        ...response.map((x) => {
          return {
            id: x.id,
            groupId: x.groupId,
            groupType: x.groupType,
            siteSellable: x.siteSellable,
            siteId: x.siteId,
            name: x.name["en-US"],
            products: x.products,
            isGroupActive: x.isGroupActive,
            isRestricted: x.isRestricted,
            groupScope: x.groupScope,
            status: x.isGroupActive ? "active" : "inactive",
            taxonomy: x.taxonomy || "Group",
            colorKey: x.isGroupActive
              ? x.groupScope === "local"
                ? "local"
                : "global"
              : "inactive",
          };
        }),
      ].sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    if (!content.find((e) => e.groupId === "my_catalog")) {
      yield put(createMyCatalog());

      content.push({
        id: "my_catalog",
        groupId: "my_catalog",
        name: "My Catalog",
        products: Object.keys(productLookup).filter(
          (pid) =>
            productLookup[pid] && productLookup[pid].catalogType === "local"
        ),
        isGroupActive: true,
        status: "active",
        isRestricted: false,
        groupScope: "local",
        colorKey: "local",
      });
    }

    if (!content.find((e) => e.groupId === "global_catalog")) {
      content.push({
        id: "global_catalog",
        groupId: "global_catalog",
        name: "Global Catalog",
        products: Object.keys(productLookup).filter(
          (pid) =>
            productLookup[pid] && productLookup[pid].catalogType === "global"
        ),
        isGroupActive: true,
        status: "active",
        isRestricted: false,
        groupScope: "global",
        colorKey: "global",
      });
    }

    yield put(setAPIResponse({ section, content }));
    yield put(setAPISuccess(section));
  } catch (error) {
    yield put(setAPIError(section));
  }
}

function* handleCreateMyCatalog() {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (shopCode) =>
    request("create-my-catalog", { shopCode });

  try {
    yield call(serviceRequest, shopCode);
  } catch (error) {
    console.log(error);
  }
}

function* handleFetchProductCatalog(action = {}) {
  const section = get(action, "payload", "");
  const serviceRequest = (params) => request(sectionApiMap[section], params);

  try {
    const shopCode = yield select(selectShopCode);
    const response = yield call(serviceRequest, { shopCode });
    if (!response) yield put(setAPIError(section));

    const products = response?.productsList || [];
    const content = processCatalogResponse(response, section, shopCode);

    yield put(setAPIResponse({ section, content }));

    const isGlobalCatalog = section === "global" || section === "addons";
    if (isGlobalCatalog) yield put(fetchSRPPrices(section));

    const productColor = products.reduce((arr, product) => {
      if (product.color) arr.push(product.color);
      return [...new Set(arr.flat())];
    }, []);
    const flowers = products.reduce((arr, prod) => {
      if (prod.classification?.flowerType)
        arr.push(prod.classification.flowerType);
      return [...new Set(arr.flat())];
    }, []);
    const productExtraFilters = {
      colorTypes: productColor,
      flowerTypes: flowers,
    };

    yield put(
      setProductExtraFilters({
        productExtraFilters,
      })
    );
    yield put(setAPISuccess(section));
  } catch (error) {
    yield put(setAPIError(section));
  }
}

function* handleSRPPrices(action = {}) {
  const shopCode = yield select(selectShopCode);
  const section = get(action, "payload", "");

  const serviceRequest = (shopCode) =>
    request(sectionApiMap[section], { queryByGlobal: true, shopCode });
  const productLookup = yield select(selectProductLookup);
  const maxDepreciationAllowed = Environment.get(
    "MAXIMUM_SRP_DEPRECIATION_ALLOWED",
    10
  );

  try {
    const response = yield call(serviceRequest, shopCode);
    const country = Currency(shopCode, "COUNTRY");
    if (response) {
      const products = response.productsList || [];
      const content = products
        .filter((p) => !!productLookup[p.productId])
        .map((product) => {
          const { productId, name, price = [], regionalPrices = [] } = product;
          const regPriceArray = regionalPrices.filter(
            (rp) => rp.country === country
          );
          const { group } = productLookup[productId];
          const priceArray = regPriceArray.length ? regPriceArray : price;
          const [srpPrice, srpMaxPrice] = getPriceRange(priceArray);

          return {
            productId,
            name,
            srpMinPrice:
              group !== "mol" || productId.includes("GIFT_CARD")
                ? ""
                : parseFloat(srpPrice) - parseFloat(maxDepreciationAllowed),
            srpMaxPrice:
              group !== "mol" || productId.includes("GIFT_CARD")
                ? ""
                : parseFloat(srpMaxPrice) - parseFloat(maxDepreciationAllowed),
          };
        });

      yield put(
        setAPIResponse({
          section,
          content,
        })
      );
    }
  } catch (error) {
    yield put(setAPIError(section));
  }
}

function* handleFetchCollections() {
  const section = "collections";
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (offset, collectionsSize) =>
    request(sectionApiMap[section], { offset, collectionsSize, shopCode });

  try {
    const collections = yield call(serviceRequest, 0, 50);
    const totalRecords = collections?.totalElements;
    const content = processCollectionsResponse(collections);
    yield put(setAPIResponse({ section, content }));
    yield fork(fetchExtendedData, totalRecords, serviceRequest, section, 50);
  } catch (error) {
    yield put(setAPIError(section));
  }
}

function* handleFetchExtendedDataCall(action) {
  const { serviceRequest, offset, rows, path, isLast = false } = action.payload;

  try {
    const shopCode = yield select(selectShopCode);
    const response = yield call(serviceRequest, offset, rows);
    let content = {};

    if (path === "collections") content = processCollectionsResponse(response);
    else content = processCatalogResponse(response, path, shopCode);

    yield put(
      setAPIResponse({
        section: path,
        content,
      })
    );
    if (isLast) yield put(setAPISuccess(path));
  } catch (error) {
    yield put(setAPIError(path));
  }
}

/* Helpers */
function* fetchExtendedData(
  totalRecords,
  serviceRequest,
  path,
  callSplitNumber
) {
  const numbersOfRequestsNeeded =
    totalRecords > 50 ? Math.ceil((totalRecords - 50) / callSplitNumber) : 0;

  for (let i = 0; i < numbersOfRequestsNeeded; i++) {
    yield put(
      fetchExtendedDataCall({
        serviceRequest,
        offset: i * callSplitNumber + 50,
        rows: callSplitNumber,
        path,
        isLast: i === numbersOfRequestsNeeded - 1,
      })
    );
  }
}

const getPriceRange = (price = []) => {
  const sortedPrices = price
    .map((e) => parseFloat(e.salePrice || e.price || e.USD, "0.00").toFixed(2))
    .sort((a, b) => a - b) || ["0.00"];

  const highestPrice = sortedPrices[sortedPrices.length - 1] || "0.00";
  const leastPrice = sortedPrices[0] || "0.00";

  return uniq([
    parseFloat(leastPrice || 0).toFixed(2),
    parseFloat(highestPrice || 0).toFixed(2),
  ]);
};

export function processCatalogResponse(response, type, memberCode) {
  const allCodes = UserProfileStorage.getProfileMemberCodes();
  const shopCode = memberCode === "all" ? allCodes[0] : memberCode;
  const products = response?.productsList || [];

  return products.map((product) => {
    const {
      name,
      productId,
      image = "",
      price = [],
      regionalPrices = [],
      siteSellable = [],
      siteId = "",
      collections = {},
      classification = {},
      excludeFromPriceMinimum = false,
      dropShippingProduct = false,
      productGroup = [],
    } = product;

    const currency = Currency(shopCode);
    const country = Currency(shopCode, "COUNTRY");
    const regPriceArray = regionalPrices.filter((rp) => rp.country === country);
    const priceArray = regPriceArray.length ? regPriceArray : price;
    const [minPrice, maxPrice] = getPriceRange(priceArray);
    const displayPrice = maxPrice
      ? `$${minPrice} ${currency} - $${maxPrice} ${currency}`
      : `$${minPrice} ${currency}`;

    const todayDate = moment().format("YYYY-MM-DD");

    const activeStartDate = get(
      product,
      "availability.activeStartDate",
      todayDate
    );
    const activeEndDate = get(product, "availability.activeEndDate", todayDate);
    const isBeforeToday = moment(todayDate).isSameOrAfter(activeStartDate);
    const isAfterToday = moment(todayDate).isSameOrBefore(activeEndDate);
    const statusAsOnDate = isBeforeToday && isAfterToday;

    const isCodified = !!classification.codificationId;
    const isMOLProduct =
      siteSellable.includes("mol") ||
      (siteSellable.includes(shopCode) && siteId === shopCode);

    const colorKey =
      type === "addons" ? (isMOLProduct ? "global" : "local") : type;
    const collectionsArray = get(collections, "floristCollections", []);
    const seoUrl = get(product, "seo.url", "");
    const handle = seoUrl.split("/").pop() || "";
    const addonSubType = get(product, "classification.subType", "");
    const isPersonalTouchAddon = [
      "personal-touch-addon",
      "standard-and-personal-touch",
    ].includes(addonSubType);

    return {
      name,
      group: colorKey.includes("global") ? "mol" : "florist",
      productId,
      image: image.includes("cdn.shopify.com")
        ? image
            .replace(".jpg", "_preset_mol-mx-tile-wide-sv-new_160x.jpg")
            .replace(".jpeg", "_preset_mol-mx-tile-wide-sv-new_160x.jpeg")
        : image,
      description: get(product, "descriptions.long.en-US", ""),
      variationDescription: get(
        product,
        "variationDescriptions.0.long.en-US",
        ""
      ),
      color: get(product, "color", []),
      flowerType: get(product, "classification.flowerType", []),
      addonSubType: get(product, "classification.subType", ""),
      status: statusAsOnDate && product.status ? "active" : "inactive",
      catalogType: type,
      statusAsOnDate,
      isPersonalTouchAddon,
      count:
        collectionsArray.length > 1
          ? "Multi Collection"
          : collectionsArray.length === 1
          ? "1 Collection"
          : "0 Collections",
      displayPrice,
      price: minPrice,
      adjustedPrice: minPrice,
      maxPrice,
      handle,
      callForPrice: get(product, "callForPrice", false),
      inStorePickUp: get(product, "inStore", false),
      soldOut: get(product, "soldOut", false),
      colorKey: statusAsOnDate && product.status ? colorKey : "inactive",
      excludeFromPriceMinimum,
      excludeFromRushDelivery: get(product, "excludeFromRushDelivery", false),
      dropShippingProduct,
      localDelOnly: get(product, "localDelOnly", false),
      codified: isCodified,
      collections: collectionsArray,
      productGroup,
    };
  });
}

function* handlePriceAdjustments(action = {}) {
  const section = action.payload;
  const data = yield select(selectAPIResponse);
  const rules = yield select(selectScheduledPriceAdjustment);
  const priceAdjustmentRule = rules.find((o) => o.ruleId && o.active);

  if (!priceAdjustmentRule) return;
  if (!section) return;

  let content = cloneDeep(data[section]);

  Object.keys(content).forEach((pid) => {
    const product = content[pid];
    const { productId, price, srpMinPrice } = product;
    const {
      products = [],
      operation,
      action,
      fieldValue: value,
    } = priceAdjustmentRule;

    const isRuleApplicable =
      products.includes("*") || products.includes(productId);

    content[pid] = {
      ...product,
      adjustedPrice: isRuleApplicable
        ? calcPrice(price, srpMinPrice, value, operation, action)
        : price,
    };
  });

  yield put(
    setAPIResponse({
      section,
      content: Object.values(content),
      priceAdjustmentsApplied: true,
    })
  );
}

export function processCollectionsResponse(response) {
  const collections = response.content || [];

  return collections
    .filter((c) => c.handle !== "moltax")
    .map((c) => ({
      ...c,
      productIds: c.productIds || [],
      count: `${c.totalProductsCount || 0} Products`,
      colorKey:
        c.status === "inactive"
          ? c.status
          : c.group === "mol"
          ? "global"
          : "local",
      displayCollection:
        c.group === "mol" ? "Global Collection" : "My Collection",
    }));
}

export const calcPrice = (price, srpMinPrice, override, operation, type) => {
  if (!price) return "";
  const maxDepreciationAllowed = Environment.get(
    "MAXIMUM_SRP_DEPRECIATION_ALLOWED",
    10
  );
  if (type === "percentage") {
    if (operation === "increment") {
      return (parseFloat(price) + parseFloat((price * override) / 100)).toFixed(
        2
      );
    } else {
      if (srpMinPrice && parseFloat(srpMinPrice) <= 0) {
        /* Return Original SRP Price incase of negative srpMinPrice */
        return (parseFloat(srpMinPrice) + maxDepreciationAllowed).toFixed(2);
      } else {
        const updatedPrice =
          parseFloat(price) - parseFloat((price * override) / 100) <= 0
            ? parseFloat(price)
            : parseFloat(price) - parseFloat((price * override) / 100);
        return Math.max(
          parseFloat(srpMinPrice || 0).toFixed(2),
          updatedPrice
        ).toFixed(2);
      }
    }
  } else if (type === "dollar") {
    if (operation === "increment") {
      return (parseFloat(price) + parseFloat(override)).toFixed(2);
    } else {
      if (srpMinPrice && parseFloat(srpMinPrice) <= 0) {
        /* Return Original SRP Price incase of negative srpMinPrice */
        return (parseFloat(srpMinPrice) + maxDepreciationAllowed).toFixed(2);
      } else {
        const updatedPrice =
          parseFloat(price) - parseFloat(override) <= 0
            ? parseFloat(price)
            : parseFloat(price) - parseFloat(override);

        return Math.max(
          parseFloat(srpMinPrice || 0).toFixed(2),
          updatedPrice
        ).toFixed(2);
      }
    }
  } else return "";
};

const adjustPrices = (product, override, operation, type, shopCode) => {
  const price = calcPrice(
    product.price,
    product.srpMinPrice,
    override,
    operation,
    type
  );

  const maxPrice = calcPrice(
    product.maxPrice,
    product.srpMaxPrice,
    override,
    operation,
    type
  );

  const currency = Currency(shopCode);
  return {
    price,
    maxPrice,
    adjustedPrice: price,
    displayPrice: maxPrice
      ? `$${price} ${currency} - $${maxPrice} ${currency}`
      : `$${price} ${currency}`,
  };
};

export function* handleBulkCommonDataPatches(action = {}) {
  const { productIds, property, operation, value, type } = get(
    action,
    "payload",
    {}
  );

  const shopCode = yield select(selectShopCode);
  const api = yield select(selectAPIResponse);
  let patch = cloneDeep(api);

  // delete products in bulk
  if (property === "delete") {
    let eligibleProducts = [];

    ["local", "addons"].forEach((ctl) => {
      productIds.forEach((pid) => {
        const product = patch[ctl][pid];
        if (!product) return;
        if (product.group !== "mol") {
          eligibleProducts.push(pid);
          delete patch[ctl][pid];
        }
      });
    });

    Object.keys(patch.collections).forEach((handle) => {
      const collection = patch.collections[handle];
      const match = intersection(eligibleProducts, collection.productIds);
      if (!match.length) return;

      patch.collections[handle] = {
        ...collection,
        productIds: difference(collection.productIds, eligibleProducts),
        ...(collection.productSortType === "Manual" && {
          manualSortProductOrder: difference(
            collection.manualSortProductOrder || [],
            eligibleProducts
          ),
        }),
      };
    });

    patch.sharedCatalogs = patch.sharedCatalogs.map((catalog) => {
      const match = intersection(eligibleProducts, catalog.products);
      if (!match.length) return catalog;

      return {
        ...catalog,
        products: difference(catalog.products, eligibleProducts),
      };
    });

    // status change of products in bulk
  } else if (property === "active") {
    let newlyUpdatedProducts = 0;
    ["global", "local", "addons"].forEach((ctl) => {
      productIds.forEach((pid) => {
        const product = patch[ctl][pid];
        if (!product) return;

        const newStatus =
          product.statusAsOnDate && value ? "active" : "inactive";

        if (product.status !== newStatus) {
          newlyUpdatedProducts++;
          patch[ctl][pid] = {
            ...product,
            status: newStatus,
            colorKey:
              product.statusAsOnDate && value
                ? product.group === "mol"
                  ? "global"
                  : "local"
                : "inactive",
          };
        }
      });
    });

    Object.keys(patch.collections).forEach((handle) => {
      const collection = patch.collections[handle];
      const match = intersection(productIds, collection.productIds);
      if (!match.length) return;

      let totalActiveProductsCount = collection.totalActiveProductsCount;
      if (value)
        totalActiveProductsCount =
          totalActiveProductsCount + newlyUpdatedProducts;
      else
        totalActiveProductsCount =
          totalActiveProductsCount - newlyUpdatedProducts;

      patch.collections[handle] = {
        ...collection,
        totalActiveProductsCount,
      };
    });
  } else if (property === "collections") {
    let activeProducts = [];
    ["global", "local"].forEach((ctl) => {
      productIds.forEach((pid) => {
        const product = patch[ctl][pid];
        if (!product) return;

        const newCollections = operation
          ? operation === "add"
            ? uniq([...product.collections, ...value])
            : product.collections.filter((c) => !value.includes(c))
          : product.collections;

        patch[ctl][pid] = {
          ...product,
          collections: newCollections,
          collection:
            newCollections.length > 1
              ? "Multi Collection"
              : newCollections.length === 1
              ? "1 Collection"
              : "0 Collections",
        };

        if (product.status === "active") activeProducts.push(product);
      });
    });

    Object.keys(patch.collections).forEach((handle) => {
      if (!value.includes(handle)) return;

      const collection = patch.collections[handle];
      let totalActiveProductsCount = collection.totalActiveProductsCount;
      if (operation === "add")
        totalActiveProductsCount =
          totalActiveProductsCount + activeProducts.length;
      else
        totalActiveProductsCount =
          totalActiveProductsCount - activeProducts.length;

      const currentProductIds =
        collection.productSortType === "Manual"
          ? collection.manualSortProductOrder
          : collection.productIds;

      const newProductIds = uniq(
        operation
          ? operation === "add"
            ? [...currentProductIds, ...productIds]
            : currentProductIds.filter((p) => !productIds.includes(p))
          : currentProductIds
      );

      patch.collections[handle] = {
        ...collection,
        totalProductsCount: newProductIds.length,
        totalActiveProductsCount,
        count: `${newProductIds.length} Products`,
        productIds: newProductIds,
        ...(collection.productSortType === "Manual" && {
          manualSortProductOrder: uniq(newProductIds),
        }),
      };
    });

    // price changes of products in bulk
  } else if (property === "prices") {
    let applicableSections = {};
    ["global", "local", "addons"].forEach((ctl) => {
      productIds.forEach((pid) => {
        const product = patch[ctl][pid];
        if (!product) return;

        applicableSections[ctl] = true;

        patch[ctl][pid] = {
          ...product,
          ...adjustPrices(product, value, operation, type, shopCode),
        };
      });
    });

    if (applicableSections["local"])
      yield put(applyPriceAdjustmentRule("local"));
    if (applicableSections["global"])
      yield put(applyPriceAdjustmentRule("global"));

    // mark soldOut/callForPrice/excludeFromPriceMinimum/rushDelivery/localDelivery of products in bulk
  } else if (
    property === "soldOut" ||
    property === "callForPrice" ||
    property === "excludeFromPriceMinimum" ||
    property === "instore" ||
    property === "excludeFromRushDelivery" ||
    property === "localDelOnly"
  ) {
    ["global", "local", "addons"].forEach((ctl) => {
      productIds.forEach((pid) => {
        const product = patch[ctl][pid];
        if (!product) return;

        patch[ctl][pid] = {
          ...product,
          soldOut: property === "soldOut" ? value : product.soldOut,
          callForPrice:
            property === "callForPrice" ? value : product.callForPrice,
          inStorePickUp: property === "instore" ? value : product.inStorePickUp,
          excludeFromRushDelivery:
            property === "excludeFromRushDelivery"
              ? value
              : product.excludeFromRushDelivery,
          localDelOnly:
            property === "localDelOnly" ? value : product.localDelOnly,
          excludeFromPriceMinimum:
            property === "excludeFromPriceMinimum"
              ? value
              : product.excludeFromPriceMinimum,
        };
      });
    });
  }

  yield put(
    setAPIResponse({
      patch: {
        data: patch,
        todo: "bulkUpdate",
      },
    })
  );
}

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(setInit.type, handleInit);
  yield takeLatest(navigateScreen.type, handleNavigation);
  yield takeLatest(setShopCode.type, handleShopCodeChange);
  yield takeEvery(fetchProductCatalog.type, handleFetchProductCatalog);
  yield takeEvery(fetchSharedCatalogs.type, handleFetchSharedCatalogs);
  yield takeEvery(fetchSRPPrices.type, handleSRPPrices);
  yield takeEvery(fetchCollections.type, handleFetchCollections);
  yield takeEvery(createMyCatalog.type, handleCreateMyCatalog);
  yield takeEvery(fetchExtendedDataCall, handleFetchExtendedDataCall);
  yield takeEvery(fetchCatalogSettings.type, handleFetchCatalogSettings);
  yield takeLatest(setAPIResponse.type, handleUIRefresh);
  yield takeLatest(applyPriceAdjustmentRule.type, handlePriceAdjustments);

  yield takeEvery(saveCatalogSettings.type, handleSaveCatalogSettings);
  yield takeLatest(setPriceMinimumAction.type, handleBulkActions);
  yield takeLatest(
    createScheduledPriceAdjustment.type,
    handleScheduledPriceAdjustment
  );
  yield takeLatest(
    updateScheduledPriceAdjustment.type,
    handleUpdatePriceAdjustment
  );
  yield takeLatest(
    deleteScheduledPriceAdjustment.type,
    handleDeletePriceAdjustment
  );
  yield takeEvery(setBulkUpdates.type, handleBulkCommonDataPatches);
}

export default watchSaga;
