import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, TouchableOpacity } from "react-native";
import { Image } from "react-native-elements";

import {
  Form,
  FormField,
  FormFieldSwitch,
  FormFieldDatePicker,
} from "components/elements/forms";
import { ToasterHandler, Spinner, Tooltip } from "components/elements";
import { Section, Currency } from "components/wrappers";

import I18NContext from "library/contexts/i18N";
import {
  saveGlobalFees,
  fetchCitiesFees,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import {
  setActiveTab,
  setTaxAccordian,
} from "library/sagas/views/home/drawer/shop-settings/common/slice";
import { isValidFeeValue } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";
import { selectSiteFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import {
  selectShopDetails,
  selectActiveTab,
  selectShopCode,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import UserProfileStorage from "library/storage/userProfile";
import { memberEntitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { formatPrice } from "library/utils/formatter";
import { DeviceContext } from "library/contexts/appSettings";

import { FeesFields } from "../ui-config";
import FeesCoverageModal from "./alert";
import { getGlobalFeesValidationSchema } from "../yup";
import { SaveCancelButtons } from "components/wrappers";
import { fonts } from "styles/theme";
import IMAGES from "static/assets/images";

import tw from "tailwind-rn";

const FeesInputFields = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(selectSiteFees);
  const shopDetails = useSelector(selectShopDetails);
  const shopCode = useSelector(selectShopCode);
  const activeTab = useSelector(selectActiveTab);

  const currency = Currency(shopCode);
  const isDeliveryPickupTab = activeTab === "delivery-pickup";
  const { isMobile } = React.useContext(DeviceContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const [showModal, setShowModal] = useState({});

  const validationSchema = getGlobalFeesValidationSchema(messages, Localise);
  const isAdminUser = UserProfileStorage.getRole() === "ADMIN";

  const entitlement = shopDetails?.memberEntitlements?.memberEntitlementName;

  const isEntitlementExpired =
    shopDetails?.memberEntitlements?.message?.includes("expired") ?? false;

  const isRushEligible = ![
    memberEntitlements.MHQ_CORE,
    memberEntitlements.MHQ_CONN,
    memberEntitlements.DELIVERY_SERVICE,
  ].includes(entitlement);

  const isNonCoreMember =
    entitlement?.includes(memberEntitlements.MHQ_STANDARD) ||
    entitlement?.includes(memberEntitlements.MHQ_PREMIUM) ||
    entitlement?.includes(memberEntitlements.MHQ_ESSENTIALS);

  const isMOLUser = entitlement?.includes(memberEntitlements.MERCURY_ONLINE);

  const showTaxDeliveryFee =
    isNonCoreMember && !isEntitlementExpired && isAdminUser;
  const showRelayFee =
    isNonCoreMember && Environment.get("SHOW_RELAY_FEE", false);

  useEffect(() => {
    dispatch(fetchCitiesFees());
  }, []);

  if (!initialValues) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveGlobalFees({
        params: { ...values, showTaxDeliveryFee, showRelayFee },
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(
              messages,
              "Your Fee and Coverage Settings have been updated"
            )
          );
          isDeliveryPickupTab && dispatch(fetchCitiesFees());
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  return (
    <>
      <Section
        title={Localise(messages, "Fees")}
        defaultOpen={true}
        titleStyle={{ fontWeight: "800" }}
      >
        <Form
          initialValues={initialValues}
          onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
          validationSchema={validationSchema}
          render={(formikBag) => {
            const { values, setFieldValue, errors } = formikBag;
            const initLocal = values.localFee;
            const showServiceFee =
              isMOLUser &&
              initialValues.serviceFee >= 0 &&
              initialValues?.isActive === "true";
            const showRetailDeliveryFee = initialValues.retailDeliveryFee > 0;

            const { rushDeliveryFee, rush_delivered_by, rushDeliveryInfos } =
              values;
            return (
              <>
                {showModal.title && (
                  <FeesCoverageModal
                    title={Localise(messages, showModal.title)}
                    primaryAction={showModal.primaryAction}
                    secondaryAction={showModal.secondaryAction}
                  />
                )}
                {FeesFields(isDeliveryPickupTab, Localise, messages).map(
                  (
                    {
                      formFieldType,
                      formFieldProps,
                      name,
                      timePickerProps = {},
                      title,
                      customOnChange,
                      customOnChangeTitle,
                      children,
                    },
                    i
                  ) => {
                    if (
                      ["retailDeliveryFee", "taxRetailDeliveryFee"].includes(
                        name
                      ) &&
                      !showRetailDeliveryFee
                    )
                      return null;

                    if (name === "serviceFee" && !showServiceFee) return null;

                    if (name === "relayFee" && !showRelayFee) return null;

                    if (name === "enableRushFee" && !isRushEligible)
                      return null;

                    const state = shopDetails?.address?.state || "";
                    const updatedTitle =
                      name === "retailDeliveryFee" && state
                        ? `${state} ${title}`
                        : title;

                    return (
                      <View style={[tw("flex flex-row pb-2")]} key={i}>
                        {updatedTitle && name !== "rushDeliveryInfos" && (
                          <Text
                            style={{
                              width: isMobile ? "50%" : 200,
                              ...fonts.heading4,
                              paddingTop: 18,
                            }}
                            testID={updatedTitle}
                            accessibilityLabel={updatedTitle}
                          >
                            {Localise(messages, updatedTitle)}
                          </Text>
                        )}
                        {formFieldType === "Switch" ? (
                          <View
                            style={tw("flex flex-row items-center pl-1 pt-1")}
                          >
                            <FormFieldSwitch
                              value={values[name]}
                              onValueChange={() =>
                                setFieldValue(name, !values[name])
                              }
                              {...formFieldProps}
                            />
                            <Text
                              testID={`${formFieldProps.testID}_status`}
                              accessibilityLabel={`${formFieldProps.accessibilityLabel}_status`}
                              style={{
                                ...fonts.default,
                                paddingHorizontal: 5,
                              }}
                            >
                              {Localise(
                                messages,
                                values[name]
                                  ? formFieldProps.labelOn
                                  : formFieldProps.labelOff
                              )}
                            </Text>
                          </View>
                        ) : (
                          <View
                            style={[
                              tw(
                                `flex flex-col ${
                                  name === "rushDeliveryInfos" ? "w-full" : ""
                                }`
                              ),
                            ]}
                          >
                            <View
                              style={[
                                tw(
                                  `flex flex-row flex-wrap ${
                                    name === "rushDeliveryInfos" &&
                                    rushDeliveryInfos.length === 1
                                      ? `items-center`
                                      : ``
                                  } `
                                ),
                              ]}
                            >
                              {name === "rushDeliveryInfos" ? (
                                <>
                                  {updatedTitle && (
                                    <Text
                                      style={{
                                        width: isMobile ? "50%" : 200,
                                        ...fonts.heading4,
                                        paddingTop:
                                          rushDeliveryInfos.length > 1 ? 20 : 0,
                                        marginTop:
                                          rushDeliveryInfos.length > 1 ? 12 : 0,
                                      }}
                                      testID={updatedTitle}
                                      accessibilityLabel={updatedTitle}
                                    >
                                      {Localise(messages, updatedTitle)}
                                    </Text>
                                  )}
                                  <View
                                    style={tw(
                                      `flex flex-col ${isMobile ? "w-1/2" : ""}`
                                    )}
                                  >
                                    {rushDeliveryInfos.map(
                                      (_rushData, rushIndex) => {
                                        return (
                                          <View
                                            style={tw("flex flex-col")}
                                            key={rushIndex}
                                          >
                                            <View
                                              style={tw(
                                                "flex flex-row flex-wrap items-center"
                                              )}
                                            >
                                              <View
                                                style={{ paddingBottom: 20 }}
                                              ></View>
                                              <View style={tw("mt-5")}>
                                                <FormField
                                                  name={`rushDeliveryInfos.${rushIndex}.rushFee`}
                                                  testID={`rushDeliveryInfos.${rushIndex}.rushFee`}
                                                  accessibilityLabel={`rushDeliveryInfos.${rushIndex}.rushFee`}
                                                  handleOnBlur={(newVal) => {
                                                    customOnChange &&
                                                      isValidFeeValue(newVal) &&
                                                      newVal !== initLocal &&
                                                      setShowModal({
                                                        title:
                                                          customOnChangeTitle,
                                                        primaryAction: () => {
                                                          Object.keys(errors)
                                                            .length === 0 &&
                                                            onSubmit(
                                                              values,
                                                              formikBag
                                                            );
                                                          setShowModal({});
                                                        },
                                                        secondaryAction: () => {
                                                          setFieldValue(
                                                            `rushDeliveryInfos.${rushIndex}.rushFee`,
                                                            initLocal
                                                          );
                                                          setShowModal({});
                                                        },
                                                      });
                                                    newVal &&
                                                      setFieldValue(
                                                        `rushDeliveryInfos.${rushIndex}.rushFee`,
                                                        formatPrice(newVal)
                                                      );
                                                  }}
                                                  iconType={
                                                    "material-community"
                                                  }
                                                  iconName={"currency-usd"}
                                                  iconSize={14}
                                                  currency={currency}
                                                  {...formFieldProps}
                                                />
                                              </View>
                                              <FormFieldDatePicker
                                                name={`rushDeliveryInfos.${rushIndex}.submittedBy`}
                                                label={Localise(
                                                  messages,
                                                  "Submitted By"
                                                )}
                                                {...timePickerProps}
                                              />
                                              <FormFieldDatePicker
                                                name={`rushDeliveryInfos.${rushIndex}.deliveredBy`}
                                                label={Localise(
                                                  messages,
                                                  "Delivered By"
                                                )}
                                                {...timePickerProps}
                                              />
                                              <TouchableOpacity
                                                onPress={() => {
                                                  const newData = [
                                                    ...rushDeliveryInfos,
                                                  ];

                                                  rushIndex == 0
                                                    ? newData.push({
                                                        rushFee: "",
                                                        submittedBy: "",
                                                        deliveredBy: "",
                                                      })
                                                    : newData.splice(
                                                        rushIndex,
                                                        1
                                                      );

                                                  setFieldValue(
                                                    "rushDeliveryInfos",
                                                    newData
                                                  );
                                                }}
                                                testID={"Add"}
                                                style={tw("pl-1 mt-3")}
                                              >
                                                <Tooltip
                                                  text={Localise(
                                                    messages,
                                                    "Add"
                                                  )}
                                                  renderForWebOnly={true}
                                                >
                                                  <Image
                                                    style={{
                                                      width: 17,
                                                      height: 17,
                                                      transform: [
                                                        {
                                                          rotate:
                                                            rushIndex === 0
                                                              ? "45deg"
                                                              : "0deg",
                                                        },
                                                      ],
                                                    }}
                                                    resizeMode="cover"
                                                    source={IMAGES["close"]}
                                                  />
                                                </Tooltip>
                                              </TouchableOpacity>
                                            </View>
                                          </View>
                                        );
                                      }
                                    )}
                                    {name === "rushDeliveryInfos" &&
                                      rushDeliveryFee &&
                                      rush_delivered_by && (
                                        <View
                                          style={[
                                            tw("flex flex-row flex-wrap"),
                                            {
                                              paddingHorizontal: 5,
                                              marginTop: 6,
                                              width: isMobile ? "70%" : "100%",
                                            },
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              fonts.style2,
                                              tw("text-justify"),
                                            ]}
                                          >
                                            {Localise(
                                              messages,
                                              `This features applies a delivery fee upcharge to all rushed orders.`
                                            )}
                                          </Text>
                                        </View>
                                      )}
                                  </View>
                                </>
                              ) : null}
                              <View style={{ paddingTop: 5 }}>
                                {name !== "rushDeliveryInfos" &&
                                formFieldType === "Text" ? (
                                  <FormField
                                    name={name}
                                    testID={name}
                                    accessibilityLabel={name}
                                    handleOnBlur={(newVal) => {
                                      customOnChange &&
                                        isValidFeeValue(newVal) &&
                                        newVal !== initLocal &&
                                        setShowModal({
                                          title: customOnChangeTitle,
                                          primaryAction: () => {
                                            Object.keys(errors).length === 0 &&
                                              onSubmit(values, formikBag);
                                            setShowModal({});
                                          },
                                          secondaryAction: () => {
                                            setFieldValue(name, initLocal);
                                            setShowModal({});
                                          },
                                        });
                                      newVal &&
                                        setFieldValue(
                                          name,
                                          formatPrice(newVal)
                                        );
                                    }}
                                    iconType={"material-community"}
                                    iconName={"currency-usd"}
                                    iconSize={14}
                                    currency={currency}
                                    {...formFieldProps}
                                  />
                                ) : formFieldType === "timepicker" ? (
                                  <FormFieldDatePicker
                                    name={formFieldProps?.name ?? ""}
                                    label={Localise(
                                      messages,
                                      formFieldProps?.title ?? ""
                                    )}
                                    {...formFieldProps}
                                  />
                                ) : null}
                              </View>

                              {children &&
                                children?.map((item, index) => {
                                  if (
                                    [
                                      "localDeliveryFeeTax",
                                      "outsideLocalDeliveryFeeTax",
                                      "serviceFeeTax",
                                      "relayFeeTax",
                                    ].includes(item?.name) &&
                                    !showTaxDeliveryFee
                                  )
                                    return null;

                                  return item?.formFieldType ===
                                    "timepicker" ? (
                                    <React.Fragment key={index}>
                                      <FormFieldDatePicker
                                        name={item?.name ?? ""}
                                        label={Localise(
                                          messages,
                                          item?.title ?? ""
                                        )}
                                        {...item.formFieldProps}
                                      />
                                    </React.Fragment>
                                  ) : item?.formFieldType === "Switch" ? (
                                    <React.Fragment key={index}>
                                      <View
                                        style={[
                                          tw(
                                            `${
                                              isMobile ? "flex-col" : "flex-row"
                                            } ml-4  ${
                                              name === "rushDeliveryInfos"
                                                ? rushDeliveryInfos.length === 1
                                                  ? "pb-3"
                                                  : "pt-3 mt-3"
                                                : "pt-3"
                                            }`
                                          ),
                                        ]}
                                      >
                                        <FormFieldSwitch
                                          value={values[item?.name]}
                                          onValueChange={() =>
                                            setFieldValue(
                                              item?.name,
                                              !values[item?.name]
                                            )
                                          }
                                          {...item.formFieldProps}
                                        />
                                        <Text
                                          testID={`${item.formFieldProps.testID}_status`}
                                          accessibilityLabel={`${item.formFieldProps.accessibilityLabel}_status`}
                                          style={{
                                            ...fonts.default,
                                            paddingHorizontal: 5,
                                            paddingTop: 3,
                                          }}
                                        >
                                          {Localise(
                                            messages,
                                            values[item?.name]
                                              ? item.formFieldProps.labelOn
                                              : item.formFieldProps.labelOff
                                          )}
                                        </Text>
                                      </View>
                                    </React.Fragment>
                                  ) : null;
                                })}
                            </View>
                          </View>
                        )}
                      </View>
                    );
                  }
                )}

                <View style={tw("flex flex-row items-center justify-end")}>
                  {showTaxDeliveryFee && (
                    <View style={tw("flex flex-row items-center")}>
                      <Text style={{ ...fonts.default }}>
                        {Localise(messages, `Review all Tax Settings `)}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          dispatch(setActiveTab("shop-details"));
                          dispatch(setTaxAccordian(true));
                        }}
                        testID="review_tax_settings_link"
                        accessibilityLabel="review_tax_settings_link"
                      >
                        <Text style={{ ...fonts.link1, fontWeight: "400" }}>
                          {Localise(messages, "here")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  <SaveCancelButtons
                    buttonTitle={Localise(messages, "Save")}
                    cancelTestId={"clear_fees"}
                    testID={"save_fees"}
                  />
                </View>
              </>
            );
          }}
        />
      </Section>
    </>
  );
};

export default FeesInputFields;
