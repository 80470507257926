import React, { useEffect, useImperativeHandle } from "react";
import { View, Platform, Image } from "react-native";
import { Text } from "react-native-elements";
import IMAGES from "static/assets/images";
import { theme, colors } from "styles/theme";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import useStateIfMounted from "library/utils/useStateIfMounted";

const Accordion = ({
  title,
  children,
  defaultOpen = false,
  handleOnPress,
  labelStyle,
  titleStyle,
  contentStyle,
  overrideZindex = 0,
  actionLabel,
  actionLabelStyle,
  openStyle,
  closeStyle,
  onAction,
  headerContent,
  containerStyle = {},
  type = "primary",
  toggleAccordian = false,
  imageProps,
  isAccessible = false,
  separatorAlways = false,
  errors = {},
  setErrors,
  path = "",
  testID,
  disableAccordion = false,
  parentRef,
}) => {
  const [openAccordion, setOpenAccordion] = useStateIfMounted(defaultOpen);

  useImperativeHandle(parentRef, () => ({
    setAccordion: (enable = false) => {
      setOpenAccordion(enable);
    },
  }));

  let TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableOpacity
      : require("react-native-gesture-handler").TouchableOpacity;

  useEffect(() => {
    openAccordion && setOpenAccordion(!openAccordion);
  }, [toggleAccordian]);

  useEffect(() => {
    setOpenAccordion(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    path !== "" &&
      !isEmpty(errors) &&
      get(errors, path) &&
      !openAccordion &&
      setOpenAccordion(!openAccordion);
  }, [errors]);

  return (
    <View
      style={{
        zIndex: overrideZindex ? overrideZindex : -1,
        ...containerStyle,
      }}
      pointerEvents={disableAccordion ? "none" : "auto"}
    >
      <TouchableComponent
        accessible={isAccessible}
        style={[
          theme.Accordion.labelStyle,
          type === "secondary" ? theme.Accordion.secondaryLabelStyle : {},
          labelStyle,
          separatorAlways
            ? { ...theme.Accordion.closeStyle }
            : openAccordion
            ? { ...theme.Accordion.openStyle, ...openStyle }
            : { ...theme.Accordion.closeStyle, ...closeStyle },
          disableAccordion && {
            backgroundColor: "#E3E6E8",
          },
        ]}
        onPress={() => {
          setOpenAccordion(!openAccordion);
          handleOnPress(!openAccordion);
          // user closes accordian when validation errors are present in form
          !isEmpty(errors) &&
            openAccordion &&
            path != "" &&
            setErrors(set(errors, path, {}));
        }}
        testID={testID || title}
        accessibilityLabel={testID || title}
      >
        {headerContent ? (
          headerContent(openAccordion)
        ) : (
          <>
            <View style={theme.Accordion.titleContainer}>
              {imageProps && (
                <Image
                  style={{
                    width: imageProps.width || "25",
                    height: imageProps.height || "25",
                  }}
                  source={IMAGES[imageProps.source]}
                />
              )}
              <Text
                style={{
                  ...theme.Accordion.titleStyle,
                  ...(type === "secondary" &&
                    theme.Accordion.secondaryTitleStyle),
                  ...titleStyle,
                  ...(disableAccordion && {
                    color: colors.grayScaleLight,
                  }),
                }}
              >
                {title}
              </Text>
            </View>
            {actionLabel && openAccordion && (
              <TouchableComponent onPress={onAction}>
                <Text
                  style={{
                    ...theme.Accordion.titleStyle,
                    ...titleStyle,
                    ...actionLabelStyle,
                  }}
                >
                  {actionLabel}
                </Text>
              </TouchableComponent>
            )}
            {!disableAccordion && (
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                source={
                  IMAGES[
                    openAccordion ? "expanded-section" : "collapsed-section"
                  ]
                }
              />
            )}
          </>
        )}
      </TouchableComponent>
      {!isEmpty(children) ? (
        <View
          style={[
            theme.Accordion.contentStyle,
            contentStyle,
            { display: openAccordion ? "flex" : "none" },
          ]}
        >
          {children}
        </View>
      ) : null}
    </View>
  );
};

export default Accordion;
