import React, { useEffect, useContext, useMemo } from "react";
import { Dimensions, Image, TouchableOpacity, View } from "react-native";
import tw from "tailwind-rn";
import { IMAGES } from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import { getImageUriConfig } from "../helper";

const ImageModalContent = ({
  trackingDetails = {},
  modalName,
  setModalName,
  imageSize,
  setImageSize,
}) => {
  const { isDesktop } = useContext(DeviceContext);

  const imageUriConfig = useMemo(
    () => getImageUriConfig({ trackingDetails }),
    [trackingDetails]
  );
  const imageUri = imageUriConfig[modalName];

  useEffect(() => {
    if (modalName.length > 0 && modalName !== "map") {
      imageUri !== "" &&
        Image.getSize(
          imageUri,
          (width, height) => {
            if (
              width > Dimensions.get("window").width ||
              height > Dimensions.get("window").height
            ) {
              setImageSize({
                width: Dimensions.get("window").width / (isDesktop ? 2 : 1.5),
                height: Dimensions.get("window").height / 2,
              });
            } else {
              setImageSize({ width, height });
            }
          },
          (error) => {
            console.error(`Couldn't get the image size: ${error}`);
          }
        );
    }
  }, [modalName]);

  return (
    <View style={[tw("flex items-center justify-center")]}>
      <Image
        style={{ ...imageSize }}
        resizeMode="contain"
        source={{ uri: imageUri }}
      />
      <View
        style={[
          tw("flex-row items-center justify-end w-full absolute top-0"),
          { zIndex: 1 },
        ]}
      >
        <TouchableOpacity
          testID="close_zoomed_image"
          accessibilityLabel="close_zoomed_image"
          onPress={() => setModalName("")}
        >
          <Image
            style={{ width: 30, height: 30 }}
            resizeMode="cover"
            source={IMAGES["close"]}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ImageModalContent;
