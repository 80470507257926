import { createSelector } from "reselect";
import { SLICE_NAME } from "./constants";
import { initialState } from "./slice";
import { selectState as selectParentState } from "../selector";

/**
 * Gets the sub-state slice from the store
 * @param {object} state global state
 * @returns {object} sub-state slice
 */
export const getStateSlice = (state = {}) => state[SLICE_NAME] || initialState;

// memoized selector of the sub-state slice
export const selectState = createSelector(selectParentState, getStateSlice);

export const selectLoading = createSelector(
  selectState,
  ({ loading }) => loading
);

export const selectAPIResponse = createSelector(selectState, ({ api }) => api);

export const selectData = createSelector(selectState, ({ data }) => data);

export const selectPageLimit = createSelector(
  selectState,
  ({ pageLimit }) => pageLimit
);

export const selectPageOffset = createSelector(
  selectState,
  ({ pageOffset }) => pageOffset
);

export const selectActions = createSelector(
  selectState,
  ({ actions }) => actions
);
