import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigation,
  CommonActions,
  useRoute,
} from "@react-navigation/native";
import { View, Platform, Text } from "react-native";
import { PrintIframeRenderer } from "components/elements";

import ListingScreen from "./listing-screen";
import UpsertScreen from "./upsert-screen";
import { CustomModal } from "components/elements";
import SplitScreen from "components/containers/split-screen";
import SubHeader from "components/containers/header/sub-header";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import UserProfileContext from "library/contexts/userProfile";
import { request } from "library/utils/request";
import UserProfileStorage from "library/storage/userProfile";

import {
  selectShowUpsert,
  selectMeatBallAction,
} from "library/sagas/views/home/drawer/customer-directory/selector";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import {
  selectShowOrderUnpaidModal,
  selectApiResponse,
} from "library/sagas/ongoing/order-details/selector";
import {
  setPageInitialize,
  setPageReset,
} from "library/sagas/views/home/drawer/customer-directory/slice";
import { setPageAction as setCustomerDirectoryActions } from "library/sagas/views/home/drawer/customer-directory/slice";
import { setShowOrderUnpaidModal } from "library/sagas/ongoing/order-details/slice";
import { setEditOrder } from "library/sagas/views/home/drawer/create-order/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";

import { fonts, backgroundColors } from "styles/theme";
import get from "lodash/get";
import tw from "tailwind-rn";

const CustomerDirectory = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { proxyUser } = React.useContext(UserProfileContext);
  const { messages, Localise } = React.useContext(I18NContext);
  const shopNames = UserProfileStorage.getAllShopNames();
  const dashboardShopCode = useSelector(selectDashboardShopCode);
  const showOrderUnpaidModal = useSelector(selectShowOrderUnpaidModal);
  const showUpsert = useSelector(selectShowUpsert);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const { recordId, deliveryMethod, sourceMemberCode } =
    useSelector(selectMeatBallAction);
  const { setParams } = navigation;
  const orderDetails = get(orderDetailsResponse, `orderItems.0`, {});
  const {
    orderItemId = "",
    direction = "",
    receivingMember = {},
    sendingMember = {},
    deliveryInfo = {},
  } = orderDetails;

  const shopCode =
    direction === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const sideCarComponents = [<UpsertScreen key={"content"} />];
  if (!isDesktop) {
    sideCarComponents.unshift(
      <SubHeader
        key={"header"}
        showTwoScreens={showUpsert}
        title={Localise(messages, "Customer Directory")}
        onTrigger={onBack}
      />
    );
  }

  useEffect(() => {
    if (dashboardShopCode !== "all") {
      // Updating selected shop in customer directory redux slices in order show it as pre selected in shops filter.
      const selectedShop = [
        {
          label: `${dashboardShopCode} ${shopNames[dashboardShopCode]}`,
          value: dashboardShopCode,
        },
      ];

      dispatch(
        setCustomerDirectoryActions({ type: "shops", value: selectedShop })
      );
    }

    dispatch(setPageInitialize(route));

    return () => {
      dispatch(setPageReset());
      setParams({
        customerId: undefined,
        storeOrigin: undefined,
      });
    };
  }, []);

  const onBack = () => {};

  const Content = () => {
    return (
      <View
        style={{
          maxWidth: isSmallScreen ? "100%" : 600,
          maxHeight: isSmallScreen ? "100%" : 400,
          margin: "auto",
          backgroundColor: backgroundColors.secondary,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={[
            tw("p-3 flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {Localise(messages, "Order Is Unpaid")}
          </Text>
        </View>
        <View style={isSmallScreen ? {} : tw("mx-20")}>
          <Text style={[tw("p-5"), { fontSize: 14 }]}>
            {Localise(messages, "Do you want to complete the payment?")}
          </Text>
        </View>
      </View>
    );
  };
  const modalContent = {
    content: <Content />,
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "Ok"),
      },
      { type: "secondary", title: Localise(messages, "Cancel") },
    ],
  };

  const orderDeliveryMethod = deliveryMethod || deliveryInfo?.deliveryMethod;

  const isLocalOrder = [
    "FLORIST_DELIVERED",
    "STORE_PICKUP",
    "WALK_IN",
  ].includes(orderDeliveryMethod);

  const handleProcessPayment = () => {
    dispatch(setEditOrder());
    isLocalOrder &&
      request("lock-order", {
        recordId: recordId || orderItemId,
        deliveryMethod: orderDeliveryMethod,
      });
    navigation.dispatch(
      CommonActions.navigate({
        name: "create-order",
        params: {
          id: recordId || orderItemId,
          action: "edit",
          smc: sourceMemberCode || shopCode,
          dm: orderDeliveryMethod,
        },
      })
    );
    dispatch(setCurrentPage("create-order"));
    dispatch(setShowOrderUnpaidModal(false));
  };
  const onCancel = () => {
    dispatch(setShowOrderUnpaidModal(false));
  };

  return (
    <>
      <SplitScreen
        showTwoScreens={showUpsert}
        subHeaderExists={!isDesktop}
        proxyHeaderExists={!!proxyUser}
        hasStickyHeader={true}
      >
        <View fsClass="fs-unmask">
          {[<SubHeader key={"header"} showTwoScreens={showUpsert} />].concat(
            ListingScreen()
          )}
        </View>
        <View fsClass="fs-unmask">{sideCarComponents}</View>
      </SplitScreen>
      {showOrderUnpaidModal && (
        <CustomModal
          modalVisible={showOrderUnpaidModal}
          modalContent={modalContent}
          primaryhandler={() => handleProcessPayment()}
          secondaryhandler={() => onCancel()}
          contentStyle={{ backgroundColor: "white" }}
          modalStyle={
            Platform.OS !== "web"
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#00000070",
                  color: backgroundColors.secondary,
                }
              : { padding: 0 }
          }
          isReduceBtnSpacing={true}
        ></CustomModal>
      )}
      <PrintIframeRenderer testID={"customer-directory"} />
    </>
  );
};

export default CustomerDirectory;
