import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useFormikContext } from "formik";
import { Text, Button } from "react-native-elements";
import { View, Platform, Keyboard } from "react-native";
import isEmpty from "lodash/isEmpty";
import { fonts, theme } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { ConfirmModal } from "components/wrappers";

const SubmitButton = ({
  title,
  type,
  icon,
  error = "",
  mainContainerStyle = {},
  containerStyle = {},
  buttonStyle = {},
  titleStyle = {},
  disabledStyle = {},
  disableOnErrors = false,
  isSubmitOnEnter = false,
  disableOnDirty = false,
  buttonRef = null,
  refFromProp,
  isLoading = false,
  testID,
  onPressCallback, // Optional callback prop
  showWarningOnSave = false,
  warningText = "Do you want to proceed",
  saveCancel = false,
  customDisable = false,
  ...otherProps
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const [isDisabled, setDisabled] = useStateIfMounted(false);
  const [showWarningBeforeSave, setShowWarningBeforeSave] =
    useStateIfMounted(false);

  const submitBtnRef = useRef();
  useImperativeHandle(refFromProp, () => ({
    triggerSubmit: () => {
      submitBtnRef.current?.props?.onPress();
    },
    disableButton: (disable = false) => {
      setDisabled(disable);
    },
  }));

  const { handleSubmit, dirty, isSubmitting, isValid, errors } =
    useFormikContext();
  const [loading, setLoading] = useStateIfMounted(false);

  useEffect(() => {
    if (error || !isSubmitting) {
      setLoading(false);
    }
  }, [error, isSubmitting]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const SubmitAction = () => {
    if (Platform.OS === "web") {
      handleSubmit();
    } else {
      setTimeout(() => {
        handleSubmit();
      }, 100);
    }
  };

  const handlePress = () => {
    if (showWarningOnSave) {
      setShowWarningBeforeSave(true);
    } else {
      setLoading(true);
      Keyboard.dismiss();
      SubmitAction();
    }
    if (onPressCallback) {
      onPressCallback();
    }
  };

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) {
      setLoading(true);
      SubmitAction();
    }
  };

  useEffect(() => {
    if (Platform.OS === "web" && isSubmitOnEnter) {
      if (buttonRef) {
        buttonRef.current.addEventListener("keydown", submitOnEnter);
      } else {
        document.addEventListener("keydown", submitOnEnter);
        return () => document.removeEventListener("keydown", submitOnEnter);
      }
    }
  }, []);

  const themeTitleStyle =
    type === "secondary"
      ? theme.Button.secondaryTitleStyle
      : type === "regular"
      ? theme.Button.regularTitleStyle
      : {};

  const themeButtonStyle =
    type === "secondary"
      ? theme.Button.secondaryButtonStyle
      : type === "regular"
      ? theme.Button.regularButtonStyle
      : {};

  const loadingProps =
    type === "secondary"
      ? { color: theme.Button.secondaryTitleStyle.color }
      : type === "regular"
      ? { color: theme.Button.regularTitleStyle.color }
      : {};

  const id = testID || title.replace(" ", "_");
  return type === "link" ? (
    <Text
      testID={id}
      accessibilityLabel={id}
      style={[fonts.link1, containerStyle]}
      onPress={handlePress}
    >
      {Localise(messages, title)}
    </Text>
  ) : (
    <View style={mainContainerStyle}>
      {!isEmpty(errors) && !isEmpty(error) && (
        <Text style={theme.SubmitButton.errorStyle}>{error}</Text>
      )}
      <Button
        testID={id}
        accessibilityLabel={id}
        titleStyle={{ ...themeTitleStyle, ...titleStyle }}
        icon={icon}
        buttonStyle={{
          ...themeButtonStyle,
          ...buttonStyle,
          ...(loading && saveCancel ? { paddingVertical: 5 } : {}),
        }}
        containerStyle={containerStyle}
        disabled={
          isDisabled
            ? true
            : disableOnErrors
            ? !(dirty && isValid)
            : disableOnDirty
            ? !dirty
            : customDisable
        }
        disabledStyle={disabledStyle}
        loading={loading}
        loadingProps={loadingProps}
        onPress={handlePress}
        title={Localise(messages, title)}
        ref={submitBtnRef}
        {...otherProps}
      />
      <ConfirmModal
        modalVisible={showWarningBeforeSave}
        handlePrimary={() => {
          setShowWarningBeforeSave(false);
          setLoading(true);
          Keyboard.dismiss();
          SubmitAction();
        }}
        contentStyle={{ maxWidth: 340 }}
        handleSecondary={() => {
          setShowWarningBeforeSave(false);
        }}
        data={{
          modal: {
            primary: "Continue",
            secondary: "Cancel",
            content: Localise(messages, warningText),
          },
        }}
      />
    </View>
  );
};

export default React.memo(forwardRef(SubmitButton));
