import React, { useEffect, useContext, useCallback } from "react";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Image,
} from "react-native";
import { Input, Text } from "react-native-elements";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import debounce from "lodash/debounce";

import { Accordion, PrintIframeAction, Tooltip } from "components/elements";

import IMAGES from "static/assets/images";
import { fonts, backgroundColors, colors, shapes } from "styles/theme";

import {
  getRecipeInfo,
  getOccasionLabel,
} from "../../order-details/product-details/helper";

import ProductCatalogSortComponent from "./sort";
import ProductCatalogFilterComponent from "./filter";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  processProductResponse,
  processAddonsResponse,
  // filterFromList,
} from "library/utils/createOrder";
import { request } from "library/utils/request";
import { giftCardProducts } from "library/utils/giftCardProducts";
import {
  isMHQNonCoreMember,
  isCoreMember,
  isCoreConnectMember,
} from "library/utils/entitlements";
import Environment from "library/utils/environment";
import UserProfileStorage from "library/storage/userProfile";

import { fetchProduct } from "library/sagas/ongoing/global-data/slice";

export const ProductSelection = ({
  onComplete,
  onSelect,
  isSmallScreen,
  formIKPath,
  formValues,
  fetchProduct,
  currentproduct,
  memberCodes,
  sendingMemberCode,
  isFromSubscription = false,
  showGiftCards = true,
  addText = "Add to Order",
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const { isTablet, isDesktop } = useContext(DeviceContext);

  const [productQuery, setProductQuery] = useStateIfMounted("");
  const [products, setProducts] = useStateIfMounted([]);
  //const [localAddOns, setLocalAddOns] = useStateIfMounted([]);

  const [loading, setLoading] = useStateIfMounted(false);
  const [appliedFilters, setAppliedFilters] = useStateIfMounted([]);
  const [selectedMemberCode, setSelectedMemberCode] =
    useStateIfMounted(sendingMemberCode);
  const [occasionsList, setOccasionsList] = useStateIfMounted([]);

  const orderLineItems = get(formValues, `${formIKPath}.lineItems`, []);
  const occasion = get(formValues, `${formIKPath}.deliveryInfo.occasion`, "");

  const isCoreUser = isCoreMember(sendingMemberCode);
  const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);

  const { gift_cards = "false" } =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const isGiftCardsEnabled =
    Environment.get("GIFT_CARDS_ENABLED", false) &&
    gift_cards === "true" &&
    !isFromSubscription &&
    showGiftCards;

  const allowMultipleProducts =
    !isCoreUser && !isCoreConnectUser && !isFromSubscription;

  const getSiteId = () => {
    const siteIds = [];
    if (isEmpty(appliedFilters) || appliedFilters.includes("ftdCatalog")) {
      siteIds.push("fsg");
    }
    if (isEmpty(appliedFilters) || appliedFilters.includes("floristCatalog")) {
      siteIds.push(selectedMemberCode);
    }
    return siteIds.toString();
  };

  const getProducts = () => {
    setLoading(true);
    const isNonCoreMember = isMHQNonCoreMember(selectedMemberCode);

    const siteIds = getSiteId();

    const isCoreUser = isCoreMember(sendingMemberCode);
    const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);
    const allowMultipleProducts =
      !isCoreUser && !isCoreConnectUser && !isFromSubscription;

    const getLocalAddonsReq = () =>
      request("get-local-addons", {
        query: productQuery,
        memberCode: selectedMemberCode,
        siteId: `ftd,${selectedMemberCode}`,
        includeOverrides: true,
      });

    const getProductsReq = () =>
      request("get-products", {
        query: productQuery,
        ...(isNonCoreMember && {
          memberCode: selectedMemberCode,
          siteId: siteIds,
          includeOverrides: siteIds.includes("fsg"),
        }),
      });

    let promises = [];
    if (appliedFilters.length > 0) {
      if (appliedFilters.includes("Addons")) {
        promises.push(getLocalAddonsReq());
      }
      if (
        appliedFilters.includes("ftdCatalog") ||
        appliedFilters.includes("floristCatalog")
      ) {
        promises.push(getProductsReq());
      }
    } else {
      promises = allowMultipleProducts
        ? [getLocalAddonsReq(), getProductsReq()]
        : [getProductsReq()];
    }

    Promise.all(promises)
      .then((responses = []) => {
        let addons = [];
        let products = [];
        if (promises.length === 2) {
          addons = processAddonsResponse(
            responses && responses[0]?.productSummaries
          );
          products = processProductResponse(responses && responses[1].records);
        } else {
          if (appliedFilters.includes("Addons")) {
            addons = processAddonsResponse(
              responses && responses[0]?.productSummaries
            );
          } else {
            products = processProductResponse(
              responses && responses[0].records
            );
          }
        }

        // Removing gift-card from search list for non-premium member and if products are already selected
        const formattedProductsList = !isGiftCardsEnabled
          ? products.filter(
              (eachProduct) => !giftCardProducts.includes(eachProduct?.pid)
            )
          : products;
        setProducts([...formattedProductsList, ...addons]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
      });
  };

  const delayedQuery = useCallback(debounce(getProducts, 2000), [
    productQuery,
    appliedFilters,
  ]); //adding 2 second delay to trigger search action

  useEffect(() => {
    setLoading(true); // setting loading here as it is showing "No products found" initially
    delayedQuery();
    return delayedQuery.cancel;
  }, [productQuery, appliedFilters]);

  useEffect(() => {
    formValues?.sendingMember &&
      setSelectedMemberCode(formValues?.sendingMember);
  }, [formValues?.sendingMember]);

  useEffect(() => {
    if (!isEmpty(currentproduct)) {
      let productIndex = products.findIndex(
        (product) =>
          product?.pid?.toLowerCase() ===
          currentproduct?.productId?.toLowerCase()
      );
      if (productIndex !== -1) {
        let newProducts = [...products];
        newProducts[productIndex] = {
          ...newProducts[productIndex],
          ...currentproduct,
        };
        setProducts(newProducts);
      }
    }
  }, [currentproduct]);

  useEffect(() => {
    request("get-occasions", {}, undefined, true).then((res) => {
      setOccasionsList((res && res.occasions) || []);
    });
  }, []);
  // Set "My Catalog" as the default filter when the component mounts
  useEffect(() => {
    // Only set the filter if it's not already applied
    if (appliedFilters.length === 0) {
      setAppliedFilters(["floristCatalog"]);
    }
  }, []);

  const occasionLabel =
    (occasion && getOccasionLabel(occasionsList, occasion)) || "";

  return (
    <View
      style={{
        ...shapes.sectionBorder,
        padding: isDesktop ? 20 : 10,
        marginVertical: 15,
      }}
    >
      <View style={[tw("flex flex-row justify-between")]}>
        <Text
          style={{
            ...fonts.sectionHeading,
            color: colors.highlighter,
          }}
        >
          {Localise(messages, "Product Catalog")}
        </Text>

        {!isTablet && (
          <TouchableOpacity
            onPress={() => onComplete()}
            testID="close"
            accessibilityLabel="close"
          >
            <Tooltip
              text={Localise(messages, "Close Product Catalog")}
              renderForWebOnly={true}
            >
              <Image
                style={{ width: 20, height: 20 }}
                resizeMode="cover"
                source={IMAGES["close"]}
              />
            </Tooltip>
          </TouchableOpacity>
        )}
      </View>

      <View style={{ ...tw("flex flex-row justify-end items-center mt-2") }}>
        <ProductCatalogFilterComponent
          isSmallScreen={isSmallScreen}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          filterIcon={
            <Image
              style={{
                width: 18,
                height: 18,
              }}
              resizeMode="cover"
              source={IMAGES["filter"]}
            />
          }
          memberCodes={memberCodes}
          selectedShopCode={selectedMemberCode}
          isFromSubscription={isFromSubscription}
        />
        <ProductCatalogSortComponent
          isSmallScreen={isSmallScreen}
          products={products}
          updateProducts={(products) => {
            setProducts([...products]);
          }}
        />
      </View>

      <View style={tw("mt-4")}>
        <Input
          containerStyle={{
            width: "100%",
            paddingHorizontal: 0,
          }}
          placeholder={Localise(messages, "Search Catalog")}
          label=""
          name="productSearch"
          onChangeText={(text) => {
            setProductQuery(text);
          }}
          testID="search_catalog"
          accessibilityLabel="search_catalog"
        />

        <View>
          {loading ? (
            <View style={{ marginLeft: 5 }}>
              <ActivityIndicator color={colors.activityIndicator} />
              <Text style={[fonts.heading6]}>
                {Localise(messages, "Searching for products")}
              </Text>
            </View>
          ) : products.length ? (
            products.map((product, index) => {
              let buttonTitle = addText;

              if (allowMultipleProducts) {
                const isExistingProduct =
                  orderLineItems.findIndex(
                    (orderItem) =>
                      orderItem?.productFirstChoiceCode?.toLowerCase() ===
                      product?.pid?.toLowerCase()
                  ) >= 0;

                buttonTitle = isExistingProduct ? "Remove from Order" : addText;
              } else {
                const productFirstChoiceCode =
                  (orderLineItems.length &&
                    orderLineItems[0].productFirstChoiceCode) ||
                  "";

                if (productFirstChoiceCode) {
                  buttonTitle =
                    product?.pid?.toLowerCase() ===
                    productFirstChoiceCode?.toLowerCase()
                      ? "Remove from Order"
                      : "Replace Product";
                }
              }

              return (
                <Accordion
                  key={product?.pid}
                  image={product?.img}
                  title={product?.name}
                  labelStyle={{
                    marginTop: 0,
                    backgroundColor: backgroundColors.secondary,
                    // height: isSmallScreen ? 110 : 90,   // Making sure to auto adjust
                    paddingHorizontal: 0,
                  }}
                  closeStyle={{ borderBottomWidth: 0 }}
                  defaultOpen={false}
                  handleOnPress={(openAccordion) => {
                    if (openAccordion && !product.recipeList) {
                      fetchProduct({
                        productId: product?.pid,
                        siteId: selectedMemberCode || memberCodes[0],
                        orderSource:
                          product.siteId === "fsg" ? "FLORIST" : "LOCAL",
                      });
                    }
                  }}
                  headerContent={(openAccordion) => (
                    <ProductListItem
                      key={index}
                      product={product}
                      isSmallScreen={isSmallScreen}
                      openAccordion={openAccordion}
                      actionLabel={buttonTitle}
                      onAction={onSelect}
                    />
                  )}
                  containerStyle={{
                    borderWidth: 1,
                    borderColor: colors.light,
                    marginTop: 10,
                  }}
                  contentStyle={{
                    backgroundColor: "white",
                    borderWidth: 0,
                    paddingHorizontal: 5,
                  }}
                >
                  <ProductListItemContent
                    product={product}
                    onSelect={onSelect}
                    isSmallScreen={isSmallScreen}
                    actionLabel={buttonTitle}
                    occasion={occasionLabel}
                  />
                </Accordion>
              );
            })
          ) : (
            <View>
              <Text style={[fonts.heading6]}>
                {Localise(messages, "Please select a valid product")}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const ProductListItemContent = ({ product, isSmallScreen, occasion }) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const showRecipeList = product.recipeList ? [...product.recipeList] : [];

  return (
    <View style={[tw("ml-24 mr-5")]}>
      {!!product.price && (
        <View style={tw("flex flex-row")}>
          <Text style={{ fontWeight: "700" }}>
            {Localise(messages, "Suggested Retail Price")}
            {" : "}
          </Text>
          <Text style={[tw("mb-2"), fonts.style1]}>${product.price}</Text>
        </View>
      )}
      {!!product.dimension && product.dimension !== "NULL" && (
        <View style={tw("flex flex-row")}>
          <Text style={{ fontWeight: "700" }}>
            {Localise(messages, "Size / Style")}:{" "}
          </Text>
          <Text style={[tw("mb-2"), fonts.style1]}>{product.dimension}</Text>
        </View>
      )}
      {!!product.displayName && product.displayName !== "NULL" && (
        <View style={tw("flex flex-row")}>
          <Text style={{ fontWeight: "700" }}>
            {Localise(messages, "Section")}
            {" : "}
          </Text>
          <Text style={[tw("mb-2"), fonts.style1]}>{product.displayName}</Text>
        </View>
      )}
      {!!occasion && (
        <View style={tw("flex flex-row")}>
          <Text style={{ fontWeight: "700" }}>
            {Localise(messages, "Occasion")}
            {" : "}
          </Text>
          <Text style={[tw("mb-2"), fonts.style1]}>{occasion}</Text>
        </View>
      )}
      {showRecipeList.length > 1 && (
        <>
          <View style={tw("mt-5")}>
            <Text style={{ fontWeight: "700", marginBottom: 10 }}>
              {Localise(messages, "Recipe")}
            </Text>
            {showRecipeList.map((ele, index) => (
              <View key={index} style={tw("flex flex-row py-1")}>
                <Text style={tw(`w-1/4 ${ele.css || ""}`)}>
                  {Localise(messages, ele.quantity) || "-"}
                </Text>
                <Text style={tw(`w-1/4 ${ele.css || ""}`)}>
                  {Localise(messages, ele.color) || "-"}
                </Text>
                <Text style={tw(`w-2/4 ${ele.css || ""}`)}>
                  {Localise(messages, ele.item)}
                </Text>
              </View>
            ))}
          </View>
          <View style={tw("my-4")}>
            <Text
              style={{ ...fonts.link1, marginBottom: 8 }}
              onPress={() =>
                PrintIframeAction(
                  getRecipeInfo(
                    {
                      ...product,
                      productFirstChoiceCode: product?.pid,
                      formattedPrice: product?.price,
                      productFirstChoiceDescription: product?.name,
                      occasion,
                    },
                    Localise,
                    messages
                  )
                )
              }
            >
              {Localise(messages, "Print")}
            </Text>
          </View>
        </>
      )}
      <View
        style={
          !isSmallScreen ? tw("flex flex-row justify-between items-center") : {}
        }
      >
        {/*<Button
          title={actionLabel}
          style={{ minWidth: 120 }}
          buttonStyle={{
            ...theme.Button.buttonStyle,
            paddingHorizontal: 10,
          }}
          containerStyle={{ marginLeft: 0 }}
          titleStyle={{
            ...theme.Button.titleStyle,
            fontSize: 12,
          }}
          onPress={() => {
            onSelect(product, buttonTitle.split(" ")[0]);
          }}
        />*/}
      </View>
    </View>
  );
};

const ProductListItem = ({
  product,
  isSmallScreen,
  openAccordion,
  actionLabel,
  onAction,
}) => {
  const TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableOpacity
      : require("react-native-gesture-handler").TouchableOpacity;

  const { messages, Localise } = React.useContext(I18NContext);
  const { img = "" } = product;
  const imgUri = img.includes("http") ? img : `https:${product.img}`;
  const id = actionLabel.replace(" ", "_");
  return (
    <View style={[tw("flex flex-row p-2 w-full")]}>
      <Image
        style={{
          width: 75,
          height: 75,
          borderWidth: 1,
          borderColor: colors.light,
        }}
        resizeMode="cover"
        source={{ uri: imgUri }}
      />
      <View style={{ marginHorizontal: 20, flex: 1 }}>
        <View>
          <View style={tw("flex flex-row justify-between items-start")}>
            <Text style={tw(" mb-2 font-bold")}>{product.name}</Text>
            {!isSmallScreen && <Text>${product.price}</Text>}
          </View>
          <View style={tw("flex flex-row justify-between items-start")}>
            <Text style={tw(" mb-2 font-bold")}>{product.addonType}</Text>
          </View>
          <View style={tw("flex flex-row")}>
            <Text style={{ fontWeight: "700" }}>
              {Localise(messages, "Product Code")}
              {": "}
            </Text>
            <Text style={[tw("mb-2"), fonts.style1]}>
              {product?.refNumberId || product?.pid}
            </Text>
          </View>
        </View>
        {isSmallScreen && (
          <View style={tw("mb-2")}>
            <Text>${product.price}</Text>
          </View>
        )}
        <TouchableComponent
          style={[tw("flex flex-row items-center mb-2"), { minWidth: 100 }]}
          onPress={() => {
            onAction(product, actionLabel.split(" ")[0]);
          }}
          testID={id}
          accessibilityLabel={id}
        >
          <Text style={fonts.link1}>{Localise(messages, actionLabel)}</Text>
        </TouchableComponent>
      </View>
      <View style={{ height: 18 }}>
        <Image
          style={{
            width: 20,
            height: 20,
          }}
          source={
            IMAGES[openAccordion ? "expanded-section" : "collapsed-section"]
          }
        />
      </View>
    </View>
  );
};

const mapStateToProps = (state = {}) => {
  return {
    currentproduct: state.mhq.ongoing.global.currentproduct,
    addons: state.mhq.ongoing.global.addons,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProduct }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);
