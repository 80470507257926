import React from "react";
import {
  ScrollView,
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
} from "react-native";

const TabBarNew = ({
  groups,
  selectedGroups = [],
  onSelectionChange = () => {},
}) => {
  return (
    <View style={styles.container}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.groupContainer}
      >
        {groups.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            <View style={styles.group}>
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={styles.tabContainer}
              >
                {group.tabs.map((tab, tabIndex) => {
                  const groupSelectedValues =
                    selectedGroups.find((e) => e?.category === group?.category)
                      ?.values || [];

                  return (
                    <TouchableOpacity
                      key={tabIndex}
                      style={[
                        styles.tabButton,
                        groupSelectedValues?.includes(tab.value) &&
                          styles.selectedTab,
                      ]}
                      onPress={() =>
                        onSelectionChange(group.category, tab.value)
                      }
                    >
                      <Text style={styles.tabText}>{tab.label}</Text>
                      {tab.badge && (
                        <View style={styles.badge}>
                          <Text style={styles.badgeText}>{tab.badge}</Text>
                        </View>
                      )}
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          </React.Fragment>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    borderRadius: 8,
  },
  groupContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  group: {
    padding: 4,
    marginHorizontal: 2,
    borderRadius: 5,
    backgroundColor: "#E2E9ED",
  },
  tabContainer: {
    flexDirection: "row",
  },
  tabButton: {
    marginHorizontal: 2,
    paddingVertical: 5,
    paddingHorizontal: 6,
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  selectedTab: {
    backgroundColor: "white",
  },
  tabText: {
    color: "black",
  },
  badge: {
    backgroundColor: "red",
    borderRadius: 10,
    paddingHorizontal: 6,
    paddingVertical: 2,
    marginLeft: 6,
  },
  badgeText: {
    color: "white",
    fontSize: 12,
  },
});

export default TabBarNew;
