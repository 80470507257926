import React, { useContext } from "react";
import { Text, View } from "react-native";
import { colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import tw from "tailwind-rn";
import throttle from "lodash/throttle";

export const OptimizeMessage = ({
  localPermissions = {},
  isPastRoute,
  isOptimized,
  routeHasEdits,
  style = {},
}) => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <View style={style}>
      {localPermissions?.multiShopRoute &&
        !localPermissions.isCFROptimiseEnabled &&
        !isPastRoute &&
        isOptimized && (
          <View style={tw("flex flex-row justify-start mt-1 mb-2")}>
            <Text style={{ color: colors.primary }}>
              {Localise(
                messages,
                "Route optimized. Please make additional adjustments before starting the route."
              )}
            </Text>
          </View>
        )}
      {!isPastRoute && !isOptimized && (
        <View style={tw("flex flex-row justify-start mt-1 mb-2")}>
          <Text style={{ color: colors.primary }}>
            {Localise(
              messages,
              routeHasEdits
                ? "This route has not been optimized since the last order update."
                : "This route has not been optimized."
            )}
          </Text>
        </View>
      )}
      {localPermissions.isCFROptimiseEnabled && (
        <View style={{ paddingBottom: 10 }}>
          <Text style={{ color: colors.primary, textAlign: "justify" }}>
            {Localise(
              messages,
              `Advanced route optimization is applicable for optimizing large routes more than 25 orders in route`
            )}
          </Text>
        </View>
      )}
    </View>
  );
};

export const handleActionPress = throttle(
  (eachAction, actionHandler, routeData, isActionEnabled) => {
    if (isActionEnabled(eachAction)) {
      actionHandler({
        action: eachAction.key,
        routeId: routeData.routeId,
        actionType: eachAction.actionType,
      });
    }
  },
  3000
);
