import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  memo,
} from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { FlashList } from "@shopify/flash-list";
import debounce from "lodash/debounce";
import { Input, Icon } from "react-native-elements";
import styles from "./styles";
import { theme } from "styles/theme";

/* 
Data format : { id : "", name: "" , children: [] }
*/

const TreeView = ({
  data = [],
  onSelectNode,
  label = "",
  labelStyle = {},
  placeholder = "",
  onBlur,
  value = "",
  onChangeText,
  disabled = false,
  inputContainerStyle = {},
  onClear,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState(new Set());
  const [searchText, setSearchText] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [parentName, setParentName] = useState("");

  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Debounce the search input
  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text);
    }, 300),
    []
  );

  const handleChangeText = useCallback(
    (text) => {
      setSearchText(text);
      debouncedSearch(text);
      onChangeText(text);
    },
    [debouncedSearch]
  );

  // Find the parent of a selected item
  const findParent = useCallback((itemId, node) => {
    if (node.children) {
      for (const child of node.children) {
        if (child.id === itemId) {
          return node;
        }
        const result = findParent(itemId, child);
        if (result) return result;
      }
    }
    return null;
  }, []);

  const handleSelect = useCallback(
    (item) => {
      const parent = findParent(item.id, { children: data });
      setParentName(parent ? parent.name : "");
      setSelectedItem(item);
      setSearchText(item.name);
      onSelectNode(item);
      setDropdownVisible(false);
    },
    [data, findParent]
  );

  // Handle toggling node expansion
  const handleToggle = useCallback((id) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = new Set(prevExpandedItems);
      if (newExpandedItems.has(id)) {
        newExpandedItems.delete(id);
      } else {
        newExpandedItems.add(id);
      }
      return newExpandedItems;
    });
  }, []);

  // Function to filter items based on search query
  const searchItems = useCallback((items, query) => {
    if (!query) return items;

    const collectMatchingChildren = (item) => {
      let matchingItems = [];
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        matchingItems.push(item);
      }
      if (item.children) {
        const childMatches = item.children.reduce((acc, child) => {
          return acc.concat(collectMatchingChildren(child));
        }, []);
        matchingItems = matchingItems.concat(childMatches);
      }
      return matchingItems;
    };

    return items.reduce(
      (acc, item) => acc.concat(collectMatchingChildren(item)),
      []
    );
  }, []);

  // Memoize filtered data
  const filteredData = useMemo(
    () => searchItems(data, searchText),
    [data, searchText, searchItems]
  );

  // Focus handler for showing the dropdown
  const handleFocus = useCallback(() => {
    setDropdownVisible(true);
  }, []);

  // Clear search handler
  const handleClearSearch = useCallback(() => {
    setSearchText("");
    setSelectedItem(null);
    setExpandedItems(new Set());
    setDropdownVisible(false);
    setParentName("");
    onClear();

    debouncedSearch.cancel(); // Cancel any pending debounced calls
  }, [debouncedSearch]);

  useEffect(() => {
    value && setSearchText(value);
  }, []);

  // Handle clicks outside the dropdown to close it
  const handlePressOutside = useCallback((e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      searchInputRef.current &&
      !searchInputRef.current.contains(e.target)
    ) {
      setDropdownVisible(false);
      onBlur(e);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => handlePressOutside(e);

    // Add event listener only on web
    if (Platform.OS === "web") {
      window.addEventListener("click", handleClickOutside);

      return () => {
        window.removeEventListener("click", handleClickOutside);
        debouncedSearch.cancel();
      };
    }
  }, [handlePressOutside, debouncedSearch]);

  // Handle click on parent name
  const handleParentNameClick = useCallback(() => {
    const parent = findParent(selectedItem.id, { children: data });
    if (parent) {
      handleSelect(parent);
      setExpandedItems((prevExpandedItems) => {
        const newExpandedItems = new Set(prevExpandedItems);
        newExpandedItems.add(parent.id);
        return newExpandedItems;
      });
    }
  }, [selectedItem, data, findParent, handleSelect]);

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer} ref={searchInputRef}>
        <Input
          placeholder={placeholder}
          containerStyle={{ paddingHorizontal: 0 }}
          onChangeText={handleChangeText}
          onFocus={handleFocus}
          value={searchText}
          label={label}
          labelStyle={labelStyle}
          disabled={disabled}
          inputContainerStyle={{
            ...theme.Input.inputContainerStyle,
            inputContainerStyle,
          }}
          rightIcon={
            !disabled && (
              <Icon
                name={"close"}
                type={"material-community"}
                size={14}
                iconStyle={{ color: "rgba(170, 170, 170, 1)" }}
                onPress={!disabled && handleClearSearch}
              />
            )
          }
        />
      </View>
      {!disabled &&
        dropdownVisible &&
        (searchText || filteredData.length > 0) && (
          <View style={styles.dropdownContainer} ref={dropdownRef}>
            {selectedItem?.name ? (
              <View style={styles.selectedItemContainer}>
                <Text style={styles.selectedItem}>
                  {selectedItem ? `${selectedItem.name} ` : ""}
                </Text>
                {parentName ? (
                  <TouchableOpacity onPress={handleParentNameClick}>
                    <Text style={styles.parentName}>{`in ${parentName}`}</Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            ) : null}
            <FlashList
              data={searchText ? filteredData : data}
              renderItem={({ item }) => (
                <TreeNode
                  item={item}
                  level={0}
                  onSelect={handleSelect}
                  onToggle={handleToggle}
                  expandedItems={expandedItems}
                  selectedItem={selectedItem}
                />
              )}
              keyExtractor={(item) => item.id.toString()}
              estimatedItemSize={46}
              scrollEnabled={true}
              scrollEventThrottle={10}
            />
          </View>
        )}
    </View>
  );
};

const TreeNode = memo(
  ({ item, level, onSelect, onToggle, expandedItems, selectedItem }) => {
    const [isExpanded, setIsExpanded] = useState(expandedItems.has(item.id));

    const handleToggle = useCallback(() => {
      setIsExpanded((prev) => !prev);
      onToggle(item.id);
    }, [item.id, onToggle]);

    const handleSelect = useCallback(() => {
      onSelect(item);
    }, [item, onSelect]);

    return (
      <View style={[styles.nodeContainer, { marginLeft: level * 3 }]}>
        <TouchableOpacity onPress={handleSelect} style={styles.nodeTouchable}>
          {item.children && item.children.length > 0 && (
            <TouchableOpacity onPress={handleToggle}>
              <Icon
                name={isExpanded ? "chevron-down" : "chevron-right"}
                size={11}
                type="font-awesome"
                iconStyle={{
                  marginRight: 20,
                  paddingTop: Platform.OS !== "web" ? 10 : 0,
                  height: "100%",
                }}
              />
            </TouchableOpacity>
          )}
          <Text
            style={[
              styles.nodeText,
              selectedItem &&
                selectedItem.id === item.id &&
                styles.selectedNodeText,
            ]}
          >
            {item.name}
          </Text>
        </TouchableOpacity>
        {isExpanded && item.children && (
          <View style={{ minHeight: 5 }}>
            <FlashList
              data={item.children}
              renderItem={({ item }) => (
                <TreeNode
                  item={item}
                  level={level + 1}
                  onSelect={onSelect}
                  onToggle={onToggle}
                  expandedItems={expandedItems}
                  selectedItem={selectedItem}
                />
              )}
              keyExtractor={(item) => item.id.toString()}
              estimatedItemSize={46}
              scrollEnabled={true}
              scrollEventThrottle={10}
            />
          </View>
        )}
      </View>
    );
  }
);

TreeNode.displayName = TreeNode;
export default TreeView;
