/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import { Text, Button } from "react-native-elements";
import { fonts, theme, colors } from "styles/theme";
import { View, TouchableOpacity } from "react-native";
import { formatPrice } from "library/utils/formatter";
import { useFormikContext } from "formik";
import { FormFieldSwitch, FormField, Form } from "components/elements/forms";

import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { roundTheFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";

import { houseAccountLateFeeAmountSchema } from "./config";
import ChargeLateFeesError from "./errors";
import { SaveCancelButtons } from "components/wrappers";
import tw from "tailwind-rn";
import isEmpty from "lodash/isEmpty";
import { DeviceContext } from "library/contexts/appSettings";

const HouseAccountSettings = ({ shopSettings, saveSettings, loadingKey }) => {
  const { messages, Localise } = useContext(I18NContext);
  const validationSchema = houseAccountLateFeeAmountSchema(messages, Localise);

  const isHALateFeeChargeEnabled =
    shopSettings["house_account_latefeeCharge_enabled"] === "true";

  const isHALateFeeAmountEnabled = formatPrice(
    shopSettings["house_account_latefeeAmount"] || 0
  );

  const [haLateFeeToggle, setHaLateFeeToggle] = useStateIfMounted(
    isHALateFeeChargeEnabled
  );
  const [haLateFeeAmount, setHaLateFeeAmount] = useStateIfMounted(
    isHALateFeeAmountEnabled
  );

  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const { isDesktop } = React.useContext(DeviceContext);

  useEffect(() => {
    if (isEmpty(shopSettings)) return;
    setHaLateFeeToggle(
      shopSettings["house_account_latefeeCharge_enabled"] === "true"
    );
    setHaLateFeeAmount(
      formatPrice(shopSettings["house_account_latefeeAmount"] || 0)
    );
  }, [shopSettings]);

  const onSubmit = (values) => {
    saveSettings(
      {
        preferences: [
          {
            id: "house_account_latefeeAmount",
            values: [values?.lateFeesAmount],
          },
          {
            id: "house_account_latefeeCharge_enabled",
            values: [haLateFeeToggle === true ? "true" : "false"],
          },
        ],
      },
      true,
      false,
      () => {
        setIsSubmit(true);
      }
    );
  };

  return (
    <Form
      initialValues={{ lateFeesAmount: haLateFeeAmount || "0.00" }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
      render={({ errors, values, setValues, handleSubmit }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (haLateFeeToggle) {
            values?.lateFeesAmount !== haLateFeeAmount
              ? setIsSubmit(false)
              : setIsSubmit(true);
          }
        }, [values?.lateFeesAmount]);

        const errorKeys = Object.keys(errors);
        const errorsArray = errorKeys?.map((field) => errors[field]);

        return (
          <>
            <View style={[tw("flex-row flex-wrap justify-between")]}>
              <View
                style={[tw("flex flex-row items-center"), { marginTop: 4 }]}
                testID="chargeLateFee"
              >
                <View style={{ paddingRight: 20, maxWidth: 200 }}>
                  <Text style={fonts.heading5}>
                    {Localise(messages, "Charge Late Fees")}
                  </Text>
                </View>
                <View style={{ marginLeft: -5 }}>
                  <FormField
                    name="lateFeesAmount"
                    labelStyle={{ fontWeight: "normal" }}
                    containerStyle={{
                      width: 100,
                    }}
                    errorStyle={{ paddingBottom: 0, fontSize: 0, height: 0 }}
                    iconType={"material-community"}
                    iconName={"currency-usd"}
                    iconSize={14}
                    inputContainerStyle={{
                      padding: 6,
                    }}
                    leftIconContainerStyle={{ paddingRight: 0 }}
                    isPrice={true}
                    keyboardType="numeric"
                    transformText={roundTheFees}
                  />
                </View>
                <FormFieldSwitch
                  value={haLateFeeToggle}
                  onValueChange={(val) => {
                    setHaLateFeeToggle(val);
                    val !== isHALateFeeChargeEnabled
                      ? setIsSubmit(false)
                      : setIsSubmit(true);
                    if (!val) {
                      setHaLateFeeAmount(isHALateFeeAmountEnabled);
                      setValues({ lateFeesAmount: isHALateFeeAmountEnabled });
                    }
                  }}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    paddingLeft: 5,
                    width: "5%",
                  }}
                ></View>
              </View>
              <SaveCancelButtons
                buttonTitle={Localise(messages, "Save")}
                onCancel={() => {
                  setHaLateFeeAmount(haLateFeeAmount);
                  setValues({ lateFeesAmount: haLateFeeAmount });
                  setHaLateFeeToggle(isHALateFeeChargeEnabled);
                  setIsSubmit(true);
                }}
                onSubmit={handleSubmit}
                disableOnDirty={isSubmit}
                isLoading={loadingKey === "house_account_latefeeAmount"}
              />
              {/* <View style={tw("flex flex-row-reverse items-center")}>
                <Button
                  onPress={handleSubmit}
                  title={Localise(messages, "Save")}
                  testID="save"
                  accessibilityLabel="save"
                  disabled={isSubmit}
                  loading={loadingKey === "house_account_latefeeAmount"}
                />
                 <View
                  style={[
                    tw("flex flex-row items-center"),
                    {
                      ...theme.Button.cancelButtonStyle,
                      ...(isSubmit && {
                        borderColor: colors.grayScaleLight,
                      }),
                      width: isDesktop ? 72 : 80,
                      height: 35,
                    },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setHaLateFeeAmount(haLateFeeAmount);
                      setValues({ lateFeesAmount: haLateFeeAmount });
                      setHaLateFeeToggle(isHALateFeeChargeEnabled);
                      setIsSubmit(true);
                    }}
                  >
                    <Text style={{
                        ...theme.Button.secondaryTitleStyle,
                        color: isSubmit
                          ? colors.grayScaleLight
                          : colors.highlighter,
                        fontSize: 12,
                      }}>
                      {Localise(messages, "Cancel")}
                    </Text>
                  </TouchableOpacity>
                  </View>
              </View> */}
            </View>
            <ChargeLateFeesError errorsArray={errorsArray} />
          </>
        );
      }}
    />
  );
};

export default HouseAccountSettings;
