import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { formatPrice } from "library/utils/formatter";
import { getOrderSubTotal } from "library/utils/createOrder";
import { COMMON } from "library/constants";

export const canCallTax = (
  index,
  values,
  orderResponse,
  unBlockTax,
  orderSource,
  reCalculateTax
) => {
  if (isEmpty(orderResponse)) return false;

  if (
    ["Bloomnet", "Teleflora"].includes(orderSource) ||
    (["TEL eFlorist", "Email Order"].includes(orderSource) && !reCalculateTax)
  )
    return false;

  const currentOrderItem = get(values, `orderItems.${index}`, []);

  // order details loaded sub total
  const originalValues = get(values, `orderItems.${index}.oldValues`, {});
  const {
    relayFee: originalRelayFee,
    retailDeliveryFee: originalRetailDF,
    totalDeliveryFee: originalDF,
    serviceFee: originalServiceFee,
    retransFee: originalRetransFee,
    subTotal: orderDetailsSubTotal,
  } = originalValues || {};

  const originalCustomerId = get(orderResponse, "customerInfo.customerId", "");
  const currentCustomerId = get(values, "customerInfo.customerId", "");

  const { deliveryFee, retailDeliveryFee, relayFee, serviceFee, retransFee } =
    get(values, `orderItems.${index}`, {});

  // current order sub total
  const currentOrderSubTotal = getOrderSubTotal(currentOrderItem);

  if (!unBlockTax.current) {
    setTimeout(() => {
      unBlockTax.current = true;
    }, 3000);
  } else {
    return true;
  }

  return (
    (!!currentOrderSubTotal &&
      !!orderDetailsSubTotal &&
      formatPrice(currentOrderSubTotal) !==
        formatPrice(orderDetailsSubTotal)) ||
    formatPrice(originalDF) !== formatPrice(deliveryFee) ||
    formatPrice(originalRetailDF) !== formatPrice(retailDeliveryFee) ||
    formatPrice(originalRelayFee) !== formatPrice(relayFee) ||
    formatPrice(originalServiceFee) !== formatPrice(serviceFee) ||
    formatPrice(originalRetransFee) !== formatPrice(retransFee) ||
    originalCustomerId !== currentCustomerId
  );
};

export const canCallSummary = (unBlockSummary) => {
  // Holding summary call for 4sec as inital load is happening. Amount due may fluctuate as amount calculation and tax cal is happening
  if (!unBlockSummary.current) {
    setTimeout(() => {
      unBlockSummary.current = true;
      return true;
    }, 5000);
  }

  return true;
};

export const getPromoCodesList = (promoCodesList) =>
  promoCodesList?.map(({ promoCode }) => ({
    label: promoCode,
    value: promoCode,
  })) || [];

export const parsePromoValue = (promoValue) => {
  const result = promoValue.match(/^\$?(\d+(\.\d+)?)%?$/);
  if (!result) return null;

  return {
    discountType: promoValue.startsWith("$")
      ? COMMON.DOLLAR
      : COMMON.PERCENTAGE,
    discountValue: parseFloat(result[1] || 0).toFixed(2),
  };
};
