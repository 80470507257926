import { formatPrice } from "library/utils/formatter";

export const extractPriceAndQuantity = (arr) => {
  const finalArr = arr.map(
    ({
      actualPrice = "",
      itemPrice = "",
      newPrice = "",
      originalQuantity = "1",
      quantity = "",
      isRemoved = false,
      updatedItemPrice = "",
    }) => {
      const finalQuantity = quantity || originalQuantity;
      const finalItemPrice =
        updatedItemPrice?.length > 0 &&
        parseFloat(Number(updatedItemPrice) * Number(finalQuantity)) !==
          newPrice
          ? updatedItemPrice
          : parseFloat(Number(newPrice) / Number(quantity || 1));

      const newItemPrice = !isRemoved
        ? newPrice
          ? parseFloat(finalItemPrice)
          : itemPrice || actualPrice
        : "0.00";
      return {
        itemPrice: formatPrice(newItemPrice),
        quantity: finalQuantity,
      };
    }
  );
  return finalArr;
};

export const getLineItemsTotal = (lineItemsArray = []) => {
  const total = lineItemsArray.reduce((total, item) => {
    const itemPrice = parseFloat(item?.itemPrice);
    const quantity = parseFloat(item?.quantity);

    // If itemPrice or quantity is NaN, treat them as 0
    const validItemPrice = !isNaN(itemPrice) ? itemPrice : 0.0;
    const validQuantity = !isNaN(quantity) ? quantity : 0;

    return total + validItemPrice * validQuantity;
  }, 0);

  return total;
};

export const isOrderItemTotalsMatching = (props) => {
  const { values = [], oldValues = [] } = props;

  const prevValues = extractPriceAndQuantity(oldValues);
  const newValues = extractPriceAndQuantity(values);

  const prevTotal = formatPrice(getLineItemsTotal(prevValues));
  const newTotal = formatPrice(getLineItemsTotal(newValues));

  const output = prevTotal === newTotal;

  return output;
};
