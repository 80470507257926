import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, Image } from "react-native";
import { Button } from "react-native-elements";

import { RulesMapping } from "../ui-config";
import { getExceptionValidationSchema } from "../yup";
import ExceptionInput from "./input";

import {
  saveDeliveryExceptions,
  deleteDeliveryExceptions,
} from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/slice";
import { selectExceptions } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/selector";
import I18NContext from "library/contexts/i18N";
import { Form } from "components/elements/forms";
import { ToasterHandler, Spinner } from "components/elements";
import { Section } from "components/wrappers";
import IMAGES from "static/assets/images";
import { DeviceContext } from "library/contexts/appSettings";
import { newException } from "./helper";

import { theme, colors } from "styles/theme";
import tw from "tailwind-rn";

const DeliveryExceptions = () => {
  const allExceptions = useSelector(selectExceptions);
  const { isMobile } = React.useContext(DeviceContext);
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const [newExceptionData, setNewExceptionData] = useState([]);

  if (!allExceptions) {
    return (
      <View style={{ minHeight: 150 }}>
        <Spinner size="large" />
      </View>
    );
  }

  const criteriaChangeHandler = (val, setFieldValue) => {
    setFieldValue(
      "cityZipInfo",
      val === "CITY_ZIP_CODE"
        ? {
            city: "",
            zips: [],
          }
        : {}
    );
  };

  const ruleChangeHandler = (val, setFieldValue) => {
    setFieldValue("additionalFee", "");
  };

  const addNewExceptionHandler = () => {
    setNewExceptionData(newException);
  };

  const deleteExceptionHandler = (i, values) => {
    if (values.exceptionId) {
      const initialValues = allExceptions.find(
        (row) => row.exceptionId === values.exceptionId
      );

      dispatch(
        deleteDeliveryExceptions({
          params: {
            exceptionId: values.exceptionId,
            exceptionType: RulesMapping[initialValues.rule],
          },
          resolve: () => {
            ToasterHandler(
              "success",
              `${Localise(messages, "Delivery Exception")} (${
                values.label
              }) ${Localise(messages, "has been deleted")}.`
            );
          },
          reject: () => {
            ToasterHandler(
              "error",
              Localise(
                messages,
                "We were unable to process your request, please try again."
              )
            );
          },
        })
      );
    } else {
      const exceptions = [...allExceptions];
      exceptions.splice(i, 1);

      setNewExceptionData([]);
    }
  };

  const onSubmit = (values, formikBag) => {
    dispatch(
      saveDeliveryExceptions({
        params: values,
        resolve: () => {
          ToasterHandler(
            "success",
            Localise(messages, "Delivery Exception Settings have been updated")
          );
          setNewExceptionData([]);
          formikBag.setSubmitting(false);
        },
        reject: () => {
          ToasterHandler(
            "error",
            Localise(
              messages,
              "Unable to create exception, might be a duplicate or invalid."
            )
          );
          formikBag.setSubmitting(false);
        },
      })
    );
  };

  const ExceptionForm = ({ exceptionProps }) =>
    exceptionProps?.map((exception, i) => {
      const title = exception.exceptionId
        ? exception.label
        : Localise(messages, "New Exception");
      const subtitle = exception.exceptionId
        ? exception.status
          ? Localise(messages, "Active")
          : Localise(messages, "Inactive")
        : "";

      return (
        <Section
          defaultOpen={exception.status && i === 0}
          title={title}
          key={exception.exceptionId || i}
          headerContent={
            subtitle &&
            ((openAccordion) => (
              <View
                style={[
                  tw("flex flex-row items-center justify-between w-full"),
                ]}
              >
                <View style={[tw("flex flex-row items-center")]}>
                  <Text
                    style={{
                      ...theme.Accordion.titleStyle,
                      color: colors.primary,
                      paddingLeft: 10,
                    }}
                  >
                    {Localise(messages, title)}{" "}
                  </Text>
                  <Text style={[tw("ml-4")]}>
                    {Localise(messages, subtitle)}
                  </Text>
                </View>
                <Image
                  style={{ width: 20, height: 20 }}
                  containerStyle={{ marginLeft: "auto" }}
                  source={
                    IMAGES[
                      openAccordion ? "expanded-section" : "collapsed-section"
                    ]
                  }
                />
              </View>
            ))
          }
          titleStyle={{ fontWeight: "800" }}
        >
          <Form
            initialValues={exception}
            onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
            validationSchema={getExceptionValidationSchema(
              messages,
              Localise,
              allExceptions
            )}
            render={() => {
              return (
                <ExceptionInput
                  criteriaChangeHandler={criteriaChangeHandler}
                  ruleChangeHandler={ruleChangeHandler}
                  deleteExceptionHandler={deleteExceptionHandler}
                  i={i}
                />
              );
            }}
          />
        </Section>
      );
    });

  return (
    <>
      <Section
        title={Localise(messages, "Delivery Exceptions")}
        defaultOpen={true}
        titleStyle={{ fontWeight: "800" }}
      >
        {!!allExceptions.length && (
          <ExceptionForm exceptionProps={allExceptions} />
        )}
        {!!newExceptionData.length && (
          <ExceptionForm exceptionProps={newExceptionData} />
        )}
        {!newExceptionData.length && (
          <View style={tw(`justify-between flex-${isMobile ? "col" : "row"}`)}>
            <Button
              testID={"new_exception"}
              accessibilityLabel="new_exception"
              titleStyle={{
                ...theme.Button.secondaryTitleStyle,
              }}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
              }}
              containerStyle={{ marginLeft: 0 }}
              title={Localise(messages, "New Exception")}
              onPress={() => addNewExceptionHandler()}
            />
          </View>
        )}
      </Section>
    </>
  );
};

export default DeliveryExceptions;
