import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  Form,
  FormField,
  FormFieldSwitch,
  FormFieldAutoComplete,
  FormFieldPicker,
} from "components/elements/forms";
import { Button } from "react-native-elements";
import { theme, colors } from "styles/theme";
import I18NContext from "library/contexts/i18N";
import { ZoneAccordion } from "components/elements/accordion-zone";
import { ToasterHandler, CustomModal } from "components/elements";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  selectAutoRoutes,
  selectCreatedRouteZone,
  selectShopCode,
  selectEditedWaypoints,
  selectIsEditableZone,
  selectIsCreateZoneEnabled,
  selectCoverageAreaCircleVisibilty,
  selectDeleteZoneData,
  selectIsDeleteModalVisible,
} from "library/sagas/views/home/drawer/delivery/selector";
import { capacityDropdownValues } from "./config";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAutoRoutes,
  resetCreatedRouteZone,
  setNewZoneCreationFailed,
  resetIsCreateZoneEnabled,
  setIsLoadingAutoRoutes,
  setIsDeleteModalVisible,
  setDeleteZoneData,
  setEditZoneIndex,
  setIsEditableZone,
  setIsCreateZoneEnabled,
  setIsEditCancelled,
  fetchAutoRoutes,
  setEditedWaypoints,
  setCoverageAreaCircleVisibilty,
  deleteAutoRoute,
} from "library/sagas/views/home/drawer/delivery/slice";
import { request } from "library/utils/request";
import tw from "tailwind-rn";
import { IMAGES } from "static/assets/images";
import { validationSchema } from "./config";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { getDrivers } from "../upsert-route/helper";

export const SideCardElements = ({
  isAutoRoutesLoading,
  isClickable,
  onPress = () => {},
  editableZone = () => {},
}) => {
  let TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableOpacity
      : require("react-native-gesture-handler").TouchableOpacity;

  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const autoRoutes = useSelector(selectAutoRoutes);
  const [autoRoutesData, setAutoRoutesData] = useStateIfMounted([]);
  const createdZoneData = useSelector(selectCreatedRouteZone);
  const { messages, Localise } = React.useContext(I18NContext);
  const [editZoneIndex, setEditZoneIndexSideCard] = useStateIfMounted("");
  const isEditable = useSelector(selectIsEditableZone);
  const editedZoneWayPoints = useSelector(selectEditedWaypoints);
  const isCreateZoneEnabled = useSelector(selectIsCreateZoneEnabled);
  const [validationOnChange, setValidationOnChange] = useStateIfMounted(false);
  const [isModalVisible, setIsModalVisible] = useStateIfMounted(false);
  const [isAutoRouteOrdersEnabled, setAutoRouteOrdersEnabled] =
    useStateIfMounted(false);
  const [isAutoRouteEnabledLoading, setAutoRouteEnabledLoading] =
    useStateIfMounted(false);
  const [isSwitchDisabled, setSwitchDisabled] = useStateIfMounted(false);
  const [driverQuery, setDriverQuery] = useStateIfMounted("");
  const [driversData, setDriversData] = useStateIfMounted([]);
  const [, setLoading] = useStateIfMounted(false);
  const AutoRouteFormRef = useRef();
  const isCoverageAreaCircleVisible = useSelector(
    selectCoverageAreaCircleVisibilty
  );

  const deleteZoneData = useSelector(selectDeleteZoneData);
  const isDeleteModalVisible = useSelector(selectIsDeleteModalVisible);

  const handleIsZoneEdited = (val) => {
    editableZone(val);
  };

  useEffect(() => {
    if (createdZoneData?.name?.length > 1) {
      if (autoRoutes.length > 0) {
        setAutoRoutesData([...autoRoutes, createdZoneData]);
      } else {
        setAutoRoutesData([createdZoneData]);
      }
    } else if (autoRoutes.length > 0) {
      setAutoRoutesData(autoRoutes);
    } else {
      setAutoRoutesData([]);
    }
    if (autoRoutes.length > 0) {
      setSwitchDisabled(false);
      autoRoutes.map((data) => {
        if (AutoRouteFormRef.current) {
          AutoRouteFormRef.current.setFieldValue(
            "driver.name",
            data.driverName || ""
          );
          AutoRouteFormRef.current.setFieldValue(
            "driver.id",
            data.driverId || ""
          );
        }
        setAutoRouteOrdersEnabled(data.autoRouteOrders);
      });
    } else {
      setAutoRouteOrdersEnabled(false);
      setSwitchDisabled(true);
    }
  }, [autoRoutes, createdZoneData]);
  useEffect(() => {
    getDrivers(shopCode, setLoading, setDriversData);
  }, [shopCode]);
  const handleErrors = (err, setErrors) => {
    console.log("err", err);
    if (
      err?.errors &&
      ["LMDS.AUTOROUTE.TITLE_EXIST", "BAD_REQUEST"].includes(
        err?.errors[0]?.key
      )
    ) {
      setErrors({ name: "Enter a New Title" });
      ToasterHandler(
        "uh oh",
        Localise(
          messages,
          err?.errors[0]?.message ||
            "Unable to save the autoroute. Please try again."
        )
      );
    } else {
      ToasterHandler(
        "uh oh",
        Localise(messages, "Unable to save the autoroute. Please try again.")
      );
    }
  };

  const handleToggle = async (autoRouteValues, toggleValue) => {
    let isSucessToastInvoked = false;
    let isErrorToastInvoked = false;
    await Promise.all(
      autoRouteValues.map(async (autoRouteValues) => {
        const requestObj = {
          capacity: autoRouteValues.capacity,
          colorCode: autoRouteValues.colorCode,
          name: autoRouteValues.name,
          wayPoints: autoRouteValues.wayPoints,
          autoRouteOrders: toggleValue,
          siteId: shopCode,
          driverId: autoRouteValues.driverId,
        };
        request("edit-autoroutes", {
          shopCode,
          reqObj: requestObj,
          autoRouteId: autoRouteValues.autoRouteId,
        })
          .then((res) => {
            if (
              !isErrorToastInvoked &&
              !isSucessToastInvoked &&
              res?.autoRouteId
            ) {
              dispatch(fetchAutoRoutes());
              setAutoRouteEnabledLoading(false);
              ToasterHandler(
                "Success!",
                Localise(messages, "Settings have been updated")
              );
              isSucessToastInvoked = true;
              return;
            }
          })
          .catch((error) => {
            if (!isErrorToastInvoked) {
              setAutoRouteEnabledLoading(false);
              ToasterHandler(
                "uh oh",
                Localise(messages, "Please try saving your settings again")
              );
              isErrorToastInvoked = true;
              return;
            }
            console.log("Error: " + JSON.stringify(error));
          });
      })
    );
  };

  const handleSaveButton = ({ ZoneData, setErrors }) => {
    if (ZoneData?.isNewZone) {
      const reqObj = {
        capacity: ZoneData.capacity,
        colorCode: ZoneData.colorCode,
        name: ZoneData.name,
        wayPoints: ZoneData.wayPoints,
        siteId: shopCode,
        autoRouteOrders: isAutoRouteOrdersEnabled,
        driverId: ZoneData.driverId,
      };
      request("create-autoroutes", { shopCode, reqObj })
        .then((res) => {
          const content = [res];
          dispatch(resetCreatedRouteZone());
          dispatch(saveAutoRoutes({ content }));
          dispatch(resetIsCreateZoneEnabled());
          dispatch(setEditedWaypoints([]));
          ToasterHandler(
            "success",
            Localise(messages, "New autoroute has been created")
          );
          setValidationOnChange(false);
          dispatch(fetchAutoRoutes());
        })
        .catch((err) => {
          handleErrors(err, setErrors);
        });
    } else {
      request("edit-autoroutes", {
        shopCode,
        reqObj: ZoneData,
        autoRouteId: ZoneData.autoRouteId,
      })
        .then((res) => {
          if (res?.autoRouteId) {
            dispatch(fetchAutoRoutes());
            ToasterHandler(
              "success",
              Localise(messages, "Autoroute has been updated")
            );
            dispatch(setIsEditableZone(false));
            dispatch(resetIsCreateZoneEnabled());
            dispatch(setEditedWaypoints([]));
            setValidationOnChange(false);
            handleIsZoneEdited(dispatch(setIsEditableZone(false)));
          }
        })
        .catch((err) => {
          handleErrors(err, setErrors);
        });
    }
    dispatch(setIsLoadingAutoRoutes(false));
  };

  const handleContinue = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(resetCreatedRouteZone());
    dispatch(setNewZoneCreationFailed(true));
    dispatch(resetIsCreateZoneEnabled());
  };

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {Localise(
          messages,
          createdZoneData?.name
            ? "Would you like to save your zone drawing before leaving?"
            : "Would you like to finish drawing the zone before leaving?"
        )}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Leave") },
      {
        type: "primary",
        title: Localise(messages, createdZoneData.name ? "Yes" : "Finish"),
      },
    ],
  };

  const modal = (
    <CustomModal
      modalVisible={isModalVisible}
      modalContent={modalContent}
      primaryhandler={handleContinue}
      secondaryhandler={handleCancel}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );

  const handleShowCoverageArea = () => {
    if (!isCoverageAreaCircleVisible) {
      dispatch(setCoverageAreaCircleVisibilty(true));
    } else {
      dispatch(setCoverageAreaCircleVisibilty(false));
    }
  };

  const deleteAutoRouteZone = (zoneDetails) => {
    if (zoneDetails?.isNewZone) {
      dispatch(resetCreatedRouteZone());
      dispatch(setNewZoneCreationFailed(true));
      dispatch(resetIsCreateZoneEnabled());
      ToasterHandler(
        "success",
        Localise(messages, "Autoroute has been deleted")
      );
    } else {
      request("delete-autoroutes", {
        shopCode,
        autoRouteId: zoneDetails?.autoRouteId,
      })
        .then((res) => {
          if (res?.status === "Successfully Deleted") {
            dispatch(
              deleteAutoRoute({
                autoRoutes: autoRoutes,
                autoRouteId: zoneDetails?.autoRouteId,
              })
            );
            ToasterHandler(
              "success",
              Localise(messages, "Autoroute has been deleted")
            );
          }
        })
        .catch((err) => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "Unable to delete the autoroute. Please try again."
            )
          );
        });
    }
    dispatch(setIsLoadingAutoRoutes(false));
    handleIsZoneEdited(dispatch(setIsEditableZone(false)));
  };

  const handleDeleteContinue = () => {
    dispatch(setIsLoadingAutoRoutes(true));
    dispatch(setIsDeleteModalVisible(false));
    if (deleteZoneData?.name) {
      deleteAutoRouteZone(deleteZoneData);
      if (editedZoneWayPoints?.length) {
        dispatch(setEditedWaypoints([]));
      }
    }
  };

  const handleDeleteCancel = () => {
    dispatch(setIsDeleteModalVisible(false));
    handleIsZoneEdited(dispatch(setIsEditableZone(false)));
  };

  const deleteModalContent = {
    content: (
      <Text style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}>
        {`${
          Localise(messages, "Are you sure you want to delete ") +
          deleteZoneData?.name
        }"?"
        `}
      </Text>
    ),
    buttons: [
      { type: "secondary", title: Localise(messages, "Never mind") },
      { type: "primary", title: Localise(messages, "Delete") },
    ],
  };
  const deleteModal = (
    <CustomModal
      modalVisible={isDeleteModalVisible}
      modalContent={deleteModalContent}
      primaryhandler={handleDeleteContinue}
      secondaryhandler={handleDeleteCancel}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web" && {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      }
    ></CustomModal>
  );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "white",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
      }}
    >
      <View
        style={{
          ...tw("flex flex-row items-center"),
        }}
      >
        <FormFieldSwitch
          value={isAutoRouteOrdersEnabled}
          onValueChange={(val) => {
            setAutoRouteEnabledLoading(true);
            setAutoRouteOrdersEnabled(val);
            handleToggle(autoRoutes, val);
          }}
          disabled={isSwitchDisabled || isEditable || !isCreateZoneEnabled}
        />
        <Text style={[{ margin: 10 }]}>
          Auto Route my orders into these Zones
        </Text>
        {isAutoRouteEnabledLoading && (
          <ActivityIndicator
            color={colors.activityIndicator}
            testID={"Auto_Routes_Toggle_loader"}
          ></ActivityIndicator>
        )}
      </View>
      <View
        style={{ backgroundColor: colors.grayScaleLight, height: 1 }}
      ></View>
      <View style={{ height: 30, width: 25, flexDirection: "row" }}>
        {isClickable ? (
          <TouchableOpacity
            testID="create-auto-routes"
            accessibilityLabel="create-auto-routes"
            style={{
              opacity: 1,
            }}
            onPress={onPress}
            disabled={!isClickable}
          >
            <Image
              style={{
                width: 25,
                height: 25,
                marginTop: 6,
              }}
              resizeMode="cover"
              source={IMAGES["create-order"]}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            testID="create-auto-routes"
            accessibilityLabel="create-auto-routes"
            onPress={onPress}
            disabled={!isClickable}
          >
            <Image
              style={{
                width: 25,
                height: 25,
                marginTop: 6,
                opacity: 0.5,
              }}
              resizeMode="cover"
              source={IMAGES["create-order"]}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          testID="show-coverage-area-layer"
          accessibilityLabel="show-coverage-area-layer"
          onPress={() => {
            handleShowCoverageArea();
          }}
        >
          <Image
            style={{
              width: 25,
              height: 25,
              marginTop: 6,
              marginLeft: 5,
            }}
            resizeMode="cover"
            source={IMAGES["layerIcon"]}
          />
        </TouchableOpacity>
        {isAutoRoutesLoading && (
          <ActivityIndicator
            style={{ marginLeft: 10 }}
            color={colors.activityIndicator}
            testID={"Auto_Routes_loader"}
          ></ActivityIndicator>
        )}
      </View>
      {autoRoutes?.length === 0 && (
        <View style={{ marginTop: 5, marginLeft: 3 }}>
          <Text>{Localise(messages, "No Zones have been created")}</Text>
        </View>
      )}
      {autoRoutesData?.map((value, index) => {
        return (
          <View key={index} style={{ zIndex: autoRoutesData.length - index }}>
            <ZoneAccordion
              defaultOpen={value?.isNewZone ? true : false}
              key={index}
              accordionBorderColor={value.colorCode}
              headerContent={(openAccordion) => (
                <View
                  style={[
                    tw("flex flex-row items-center justify-between w-full"),
                  ]}
                >
                  <View
                    style={{
                      ...tw("flex flex-row items-center "),
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        ...theme.Accordion.titleStyle,
                        color: colors.primary,
                        paddingLeft: 10,
                      }}
                    >
                      {Localise(
                        messages,
                        value.name.length < 20
                          ? value.name
                          : value.name.slice(0, 20) + "..."
                      )}{" "}
                    </Text>
                  </View>
                  {openAccordion && !(index == autoRoutes.length) && (
                    <View style={{ marginRight: 10 }}>
                      <TouchableComponent
                        disabled={
                          isEditable && index === editZoneIndex ? true : false
                        }
                        onPress={() => {
                          if (
                            !createdZoneData?.name &&
                            (isEditable || isCreateZoneEnabled)
                          ) {
                            if (
                              (editZoneIndex >= 0 && !isEditable) ||
                              index === editZoneIndex
                            ) {
                              if (!isEditable) {
                                dispatch(setIsCreateZoneEnabled(false));
                                if (!createdZoneData?.name) {
                                  if (!isEditable) {
                                    dispatch(setIsEditableZone(true));
                                    dispatch(setEditZoneIndex(index));
                                    setEditZoneIndexSideCard(index);
                                    handleIsZoneEdited(
                                      dispatch(setIsEditableZone(true))
                                    );
                                  } else {
                                    dispatch(setIsEditableZone(false));
                                    setEditZoneIndexSideCard("");
                                    handleIsZoneEdited(
                                      dispatch(setIsEditableZone(false))
                                    );
                                  }
                                }
                              }
                            } else {
                              if (isEditable) {
                                ToasterHandler(
                                  "uh oh",
                                  Localise(
                                    messages,
                                    "Please save the current zone before working on another."
                                  )
                                );
                              }
                            }
                          } else {
                            if (
                              !createdZoneData?.name ||
                              (!isCreateZoneEnabled && !isEditable)
                            ) {
                              setIsModalVisible(true);
                              dispatch(setDeleteZoneData(value));
                            }
                          }
                        }}
                      >
                        <Image
                          style={{
                            width: 18,
                            height: 18,
                            resizeMode: "contain",
                            opacity:
                              isEditable && index === editZoneIndex ? 0.5 : 1,
                          }}
                          source={IMAGES["edit-zone"]}
                        />
                      </TouchableComponent>
                    </View>
                  )}
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    source={
                      IMAGES[
                        openAccordion ? "expanded-section" : "collapsed-section"
                      ]
                    }
                  />
                </View>
              )}
            >
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <Form
                    initialValues={{
                      name: value.name,
                      capacity: value.capacity,
                      driverName: value.driverName || "",
                      driverId: value.driverId || "",
                    }}
                    innerRef={AutoRouteFormRef}
                    validateOnChange={validationOnChange}
                    validateOnBlur={validationOnChange}
                    validationSchema={validationSchema(messages, Localise)}
                    onSubmit={(values, errors) => {
                      if (value?.isNewZone) {
                        dispatch(setIsLoadingAutoRoutes(true));
                        const newAutoRouteData = {
                          name: values.name ? values.name : value?.name,
                          capacity: values.capacity,
                          wayPoints: editedZoneWayPoints.length
                            ? editedZoneWayPoints
                            : value?.wayPoints,
                          siteId: shopCode,
                          colorCode: value.colorCode,
                          isNewZone: value?.isNewZone,
                          driverId: values?.driverId,
                        };
                        handleSaveButton({
                          ZoneData: newAutoRouteData,
                          setErrors: errors.setErrors,
                        });
                      } else {
                        if (isEditable && index == editZoneIndex) {
                          const reqObj = {
                            name: values.name,
                            capacity: values.capacity,
                            wayPoints: editedZoneWayPoints.length
                              ? editedZoneWayPoints
                              : value?.wayPoints,
                            autoRouteId: value?.autoRouteId,
                            colorCode: value.colorCode,
                            siteId: shopCode,
                            autoRouteOrders: isAutoRouteOrdersEnabled,
                            driverId: values?.driverId,
                          };
                          handleSaveButton({
                            ZoneData: reqObj,
                            setErrors: errors.setErrors,
                          });
                        }
                      }
                    }}
                    render={(formikData) => {
                      const { values, setValues } = formikData;
                      const finalValues = cloneDeep(values);

                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useEffect(() => {
                        set(finalValues, "driver.name", "");
                        set(finalValues, "driver.id", "");
                        setValues(finalValues);
                      }, [shopCode]);
                      return (
                        <>
                          <View style={{ flexDirection: "row" }}>
                            <Text>{Localise(messages, "Title *")}</Text>
                            <FormField
                              containerStyle={{
                                width: 150,
                                marginLeft: 67,
                                bottom: 8,
                              }}
                              value={formikData.values.name}
                              autoCapitalize="none"
                              autoCorrect={false}
                              editable={
                                value?.isNewZone ||
                                (isEditable && index == editZoneIndex)
                              }
                              autoComplete="new-password"
                              keyboardType="phone-pad"
                              name="name"
                              placeholder="Title"
                              title={formikData.values.name}
                              isUpdateOnChange={value?.isNewZone || isEditable}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text>{Localise(messages, "Capacity *")}</Text>
                            <FormFieldPicker
                              containerStyle={{
                                width: 100,
                                marginLeft: 38,
                                bottom: 8,
                              }}
                              placeholder={{
                                label: Localise(messages, "Select Capacity"),
                              }}
                              value={{
                                value: formikData.values.capacity,
                                label: Localise(
                                  messages,
                                  formikData.values.capacity
                                ),
                              }}
                              defaultValue={formikData.values}
                              data={capacityDropdownValues}
                              name="capacity"
                              label={Localise(messages, "")}
                              //path={path}
                              disabledFieldTouch={true}
                              disabled={
                                value?.isNewZone
                                  ? !value?.isNewZone
                                  : !(isEditable && index == editZoneIndex)
                              }
                            />
                          </View>
                          <View
                            pointerEvents={
                              value?.isNewZone
                                ? !value?.isNewZone
                                : !(isEditable && index == editZoneIndex)
                                ? "none"
                                : "auto"
                            }
                            style={{
                              flexDirection: "row",
                              zIndex: 1,
                            }}
                          >
                            <Text>{Localise(messages, "Assign Driver")}</Text>
                            <FormFieldAutoComplete
                              data={
                                (driversData.length &&
                                  driversData.filter((each) => {
                                    const { firstName = "", lastName = "" } =
                                      each;
                                    return (
                                      firstName
                                        .toLowerCase()
                                        .includes(driverQuery.toLowerCase()) ||
                                      lastName
                                        .toLowerCase()
                                        .includes(driverQuery.toLowerCase())
                                    );
                                  })) ||
                                []
                              }
                              showOnFocus={true}
                              clearTextOnBackTab={true}
                              onChangeText={(text) => {
                                setDriverQuery(text);
                              }}
                              placeholder={"Enter Driver Name"}
                              listDisplayValues={["firstName", "lastName"]}
                              outerContainerStyle={{
                                marginLeft: 20,
                                width: 200,
                                bottom: 8,
                              }}
                              name="driverName"
                              isMultiSelect={false}
                              isFormatData={true}
                              onSelect={(selectedValue) => {
                                const { firstName, lastName, id } =
                                  selectedValue;
                                set(
                                  finalValues,
                                  "driverName",
                                  `${firstName} ${lastName}`
                                );
                                set(finalValues, "driverId", `${id}`);
                                setValues(finalValues);
                              }}
                              onClear={() => {
                                set(finalValues, "driver.name", "");
                                set(finalValues, "driver.id", "");
                                setValues(finalValues);
                              }}
                              popperPlacement={"bottom"}
                            />
                          </View>

                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {!(index == autoRoutes.length) && (
                              <View
                                style={{
                                  flex: 1,
                                  alignItems: "flex-start",
                                }}
                              >
                                <Button
                                  title={Localise(messages, "Delete")}
                                  titleStyle={theme.Button.secondaryTitleStyle}
                                  buttonStyle={{
                                    ...theme.Button.secondaryButtonStyle,
                                    paddingVertical: 6,
                                    paddingHorizontal: 10,
                                    margin: 0,
                                    width: 80,
                                    height: 35,
                                  }}
                                  containerStyle={{
                                    margin: 0,
                                    marginBottom: 10,
                                    width: 80,
                                    justifyContent: "center",
                                  }}
                                  onPress={() => {
                                    if (
                                      (!isEditable && isCreateZoneEnabled) ||
                                      (!isCreateZoneEnabled &&
                                        index === autoRoutes.length) ||
                                      (isEditable && index === editZoneIndex)
                                    ) {
                                      if (
                                        isEditable &&
                                        index === editZoneIndex
                                      ) {
                                        dispatch(resetIsCreateZoneEnabled());
                                        setEditZoneIndexSideCard("");
                                        dispatch(setIsEditCancelled(true));
                                      }
                                      dispatch(setIsDeleteModalVisible(true));
                                      dispatch(setDeleteZoneData(value));
                                    } else {
                                      if (
                                        createdZoneData?.name ||
                                        (!isCreateZoneEnabled && !isEditable)
                                      ) {
                                        setIsModalVisible(true);
                                        dispatch(setDeleteZoneData(value));
                                      } else if (isEditable) {
                                        ToasterHandler(
                                          "uh oh",
                                          Localise(
                                            messages,
                                            "Please save the current zone before working on another."
                                          )
                                        );
                                      }
                                    }
                                  }}
                                  testID={"delete_zone"}
                                  accessibilityLabel={"delete_zone"}
                                />
                              </View>
                            )}
                            <View
                              style={{
                                flex: 2,
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                title={Localise(messages, "Cancel")}
                                titleStyle={theme.Button.secondaryTitleStyle}
                                buttonStyle={{
                                  ...theme.Button.secondaryButtonStyle,
                                  paddingVertical: 6,
                                  paddingHorizontal: 10,
                                  margin: 0,
                                  width: 80,
                                  height: 35,
                                }}
                                containerStyle={{
                                  margin: 0,
                                  width: 80,
                                  marginBottom: 10,
                                  justifyContent: "center",
                                }}
                                onPress={() => {
                                  if (
                                    !createdZoneData?.name &&
                                    (isEditable || isCreateZoneEnabled)
                                  ) {
                                    if (
                                      (editZoneIndex >= 0 && !isEditable) ||
                                      index === editZoneIndex
                                    ) {
                                      formikData.resetForm();
                                      dispatch(resetIsCreateZoneEnabled());
                                      setEditZoneIndexSideCard("");
                                      dispatch(setIsEditableZone(false));
                                      dispatch(setIsEditCancelled(true));
                                      handleIsZoneEdited(
                                        dispatch(setIsEditableZone(false))
                                      );
                                    } else {
                                      if (isEditable) {
                                        ToasterHandler(
                                          "uh oh",
                                          Localise(
                                            messages,
                                            "Please save the current zone before working on another."
                                          )
                                        );
                                      }
                                    }
                                  } else {
                                    if (
                                      (!isEditable && isCreateZoneEnabled) ||
                                      (!isCreateZoneEnabled &&
                                        index === autoRoutes.length) ||
                                      (isEditable && index === editZoneIndex)
                                    ) {
                                      dispatch(setIsDeleteModalVisible(true));
                                      dispatch(setDeleteZoneData(value));
                                    } else if (
                                      createdZoneData?.name ||
                                      (!isCreateZoneEnabled && !isEditable)
                                    ) {
                                      setIsModalVisible(true);
                                      dispatch(setDeleteZoneData(value));
                                    } else if (isEditable) {
                                      ToasterHandler(
                                        "uh oh",
                                        Localise(
                                          messages,
                                          "Please save the current zone before working on another."
                                        )
                                      );
                                    }
                                  }
                                }}
                                testID={"edit_zone"}
                                accessibilityLabel={"edit_zone"}
                              />
                              <Button
                                title={Localise(messages, "Save")}
                                titleStyle={theme.Button.titleStyle}
                                buttonStyle={{
                                  ...theme.Button.primary,
                                  paddingVertical: 6,
                                  paddingHorizontal: 10,
                                  margin: 0,
                                  width: 80,
                                  height: 35,
                                }}
                                containerStyle={{
                                  margin: 0,
                                  left: 10,
                                  marginBottom: 10,
                                  width: 80,
                                  justifyContent: "center",
                                }}
                                onPress={() => {
                                  if (value?.isNewZone) {
                                    setValidationOnChange(true);
                                    formikData.submitForm();
                                  } else {
                                    if (isEditable && index == editZoneIndex) {
                                      setValidationOnChange(true);
                                      formikData.submitForm();
                                    } else {
                                      if (
                                        createdZoneData?.name ||
                                        (!isCreateZoneEnabled && !isEditable)
                                      ) {
                                        setIsModalVisible(true);
                                        dispatch(setDeleteZoneData(value));
                                      } else if (isEditable) {
                                        ToasterHandler(
                                          "uh oh",
                                          Localise(
                                            messages,
                                            "Please save the current zone before working on another."
                                          )
                                        );
                                      }
                                    }
                                  }
                                }}
                                testID={"save_zone"}
                                accessibilityLabel={"save_zone"}
                              />
                            </View>
                          </View>
                        </>
                      );
                    }}
                  ></Form>
                </View>
              </View>
            </ZoneAccordion>
          </View>
        );
      })}

      {modal}
      {deleteModal}
    </View>
  );
};

export default SideCardElements;
