import { combineReducers } from "redux";
import { SLICE_NAME } from "./constants";

import mercuryOnline from "./mercury-online/slice";
import customerDirectory from "./customer-directory/slice";
import giftCard from "./gift-cards/slice";
import molRefunds from "./mol-refunds/slice";
import ftdAdmin from "./ftd-admin/reducer";
import productCatalog from "./product-catalog/reducer";
import shopSettings from "./shop-settings/reducer";
import delivery from "./delivery/slice";
import deliveryTasks from "./delivery-tasks/slice";
import createOrder from "./create-order/slice";
import createCard from "./create-card/slice";
import events from "./events/slice";
import help from "./help/slice";
import qrScan from "./qr-scan/slice";
import ordersNew from "./orders-new/slice";

const reducer = {
  [SLICE_NAME]: combineReducers({
    ...mercuryOnline,
    ...productCatalog,
    ...customerDirectory,
    ...giftCard,
    ...molRefunds,
    ...ftdAdmin,
    ...shopSettings,
    ...delivery,
    ...deliveryTasks,
    ...createOrder,
    ...createCard,
    ...events,
    ...help,
    ...qrScan,
    ...ordersNew,
  }),
};

export default reducer;
