/* eslint-disable react/display-name */
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, ActivityIndicator, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import get from "lodash/get";
import toUpper from "lodash/toUpper";

import CreditCardSettings from "./credit-card-settings";
import ContactSyncSettings from "./contact-sync-settings";
import PageAccess from "./page-access";
import {
  PermissionsForPageAccess,
  PermissionsToShowCreditCardSettings,
} from "./config";

import { selectMemberCode } from "library/sagas/views/home/drawer/ftd-admin/member-settings//manage-groups/selector";
import { setSideCar } from "library/sagas/views/home/drawer/ftd-admin/member-settings/common/slice";

import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileContext from "library/contexts/userProfile";
import { request } from "library/utils/request";
import { isMHQNonCoreUser } from "library/utils/entitlements";

import IMAGES from "static/assets/images";
import { backgroundColors, fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import { ToasterHandler } from "components/elements";

const ShopPreferences = () => {
  const dispatch = useDispatch();
  const { userRole } = useContext(UserProfileContext);
  const memberCode = useSelector(selectMemberCode);
  const hasPageAccess = PermissionsForPageAccess[toUpper(userRole)];
  const showCreditCardSettings =
    PermissionsToShowCreditCardSettings[toUpper(userRole)];

  const onComplete = () => dispatch(setSideCar(""));

  const [loading, setLoading] = useStateIfMounted(false);
  const [entitlements, setEntitlements] = useStateIfMounted([]);
  const [isNonCoreUser, setNonCoreUser] = useStateIfMounted(false);

  const [cardSettingsLoading, setCardSettingsLoading] = useStateIfMounted("");
  const [creditCardAccess, setCreditCardAccess] = useStateIfMounted(false);
  const [auditMsg, setAuditMsg] = useStateIfMounted("");

  const [preferences, setPreferences] = useStateIfMounted();

  const processResponse = (res) => {
    let obj = {};
    res.preferences.map((ele) => {
      let key = ele["id"];
      obj[key] = ele.values[0];

      let key2 = "auditInfo";
      if (ele.auditInfo) obj[key2] = ele.auditInfo;
    });
    return obj;
  };

  const getShopSettings = (getOnlyCardSettings = false) => {
    if (!getOnlyCardSettings) {
      setCardSettingsLoading("get-shop-settings");
    } else {
      setCardSettingsLoading("update-card-settings");
    }

    request("get-shop-settings", {
      ids: ["credit_card", "contact_sync_settings"],
      memberCode: memberCode,
    })
      .then((res) => {
        if (res && res.preferences && res.preferences.length) {
          let result = processResponse(res);

          setPreferences(res.preferences);
          setCreditCardAccess(result.credit_card === "true" ? true : false);
          setAuditMsg(
            result.auditInfo && result.auditInfo.length ? result.auditInfo : ""
          );
          setCardSettingsLoading("");
        }
      })
      .catch((error) => {
        console.log("Get Shop Settings Error >> " + JSON.stringify(error));

        setCreditCardAccess(false);
        setCardSettingsLoading("");
      });
  };

  const saveSettings = (preferences, sectionName) => {
    request("save-card-settings", {
      preferences,
      memberCode: memberCode,
    })
      .then((res) => {
        ToasterHandler("hooray", `Your ${sectionName} have been saved`);
        if (res && res.preferences && res.preferences.length) {
          setPreferences(res.preferences);
        }
      })
      .catch((error) => {
        console.log("Failsed to save settings - ", error);
        ToasterHandler(`uh oh", "Please try saving your ${sectionName} again`);
      });
  };

  useEffect(() => {
    if (memberCode) {
      setLoading(true);
      setEntitlements([]);
      setNonCoreUser(false);

      request("get-member-entitlements", { memberCode })
        .then((res) => {
          let allEntitlements = [];

          const defaultEntitlements = get(
            res,
            "entitlements.defaultEntitlements",
            []
          );

          defaultEntitlements.map((entitlement) => {
            allEntitlements = Object.keys(entitlement) || [];
          });

          const alarcartEntitlements = get(
            res,
            "entitlements.alacartEntitlements",
            []
          );

          allEntitlements = [...allEntitlements, ...alarcartEntitlements];

          setEntitlements(allEntitlements);

          const isStandardPremiumUser = isMHQNonCoreUser(allEntitlements);

          setNonCoreUser(isStandardPremiumUser);

          if (isStandardPremiumUser) getShopSettings();

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setEntitlements([]);
        });
    }
  }, [memberCode]);

  return (
    <View
      style={[
        {
          backgroundColor: backgroundColors.sideCar,
        },
      ]}
    >
      <View
        style={[
          tw("px-3 py-3 flex flex-row items-center justify-between"),
          { backgroundColor: backgroundColors.primary },
        ]}
      >
        <View
          style={[
            tw("flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading2, ...tw("text-white pr-4") }}>
            Shop #{memberCode}
          </Text>
          {!hasPageAccess && loading && (
            <ActivityIndicator color={colors.activityIndicator} />
          )}
        </View>

        <View>
          <TouchableOpacity
            onPress={() => {
              onComplete(false);
            }}
            testID="close"
          >
            <Image
              style={{ width: 20, height: 20 }}
              resizeMode="cover"
              source={IMAGES["close"]}
            />
          </TouchableOpacity>
        </View>
      </View>

      {hasPageAccess && (
        <PageAccess loading={loading} entitlements={entitlements} />
      )}

      {/* showCreditCardSettings = true;  If Role is FTD_ADMIN, FTD_POWER_USER, FTD_VIEW_ONLY */}
      {isNonCoreUser && showCreditCardSettings && (
        <>
          <CreditCardSettings
            memberCode={memberCode}
            loading={cardSettingsLoading}
            setLoading={setCardSettingsLoading}
            creditCardAccess={creditCardAccess}
            setCreditCardAccess={setCreditCardAccess}
            auditMsg={auditMsg}
            getShopSettings={getShopSettings}
            processResponse={processResponse}
          />
        </>
      )}

      <ContactSyncSettings
        memberCode={memberCode}
        preferences={preferences}
        saveSettings={saveSettings}
      />
    </View>
  );
};

export default ShopPreferences;
