import React from "react";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { Platform } from "react-native";
import { isAndroid, isFirefox } from "react-device-detect";
import { ToasterHandler } from "components/elements";

export const PrintIframeAction = (
  htmlData,
  printPreferences = {},
  callBackFunc,
  frameId = "iframeId",
  successCallBackFunc
) => {
  if (isAndroid && isFirefox) {
    ToasterHandler(
      "error",
      "Firefox does not support printing from this device."
    );
    return false;
  }
  if (isEmpty(htmlData)) return false;

  const iframe = document.frames
    ? document.frames[frameId]
    : document.getElementById(frameId);
  const iframeWindow = iframe.contentWindow || iframe;
  iframeWindow.document.open();
  iframeWindow.document.write(`${htmlData}`);
  iframeWindow.document.close();
  iframe.focus();

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;

  const handleMessage = (event) => {
    const action =
      frameId === "iframeId" ? "iframe-loaded" : `${frameId}-loaded`;
    if (event.data.action === action) {
      if (!isElectronApp) {
        iframeWindow.print();
      } else {
        const { printType = "", preferences = {} } = printPreferences;

        const paper_size = [
          "orderDetails",
          "outboundOrderDetails",
          "pickupManifest",
          "designerWorksheet",
          "orderMessages",
          "orderInboundMessages",
          "orderOutboundMessages",
        ].includes(printType)
          ? "Letter"
          : printType === "invoice"
          ? "Legal"
          : "";

        if (isEmpty(preferences) || preferences.macAddress !== macAddress) {
          iframeWindow.print();
        } else {
          try {
            const { ipcRenderer } = window.require("electron");
            const parsedContent = encodeURIComponent(htmlData);
            console.log("print content -- ", frameId, parsedContent);
            ipcRenderer.send(
              "passPrintContent",
              parsedContent,
              JSON.stringify({
                ...preferences,
                paper_size,
              }),
              printType
            );
            successCallBackFunc && successCallBackFunc();
          } catch (error) {
            console.log("Failed to Print: ", error);
          }
        }
      }

      window.removeEventListener("message", handleMessage);
      if (!isNil(callBackFunc)) callBackFunc();
    }
  };

  window.addEventListener("message", handleMessage);

  return false;
};

export const PrintIframeRenderer = ({
  id = "iframeId",
  testID = "iframeTestId",
}) => {
  return (
    <>
      <iframe
        id={id}
        style={{ display: "none" }}
        title="Mercury HQ"
        data-test-id={testID}
      />
    </>
  );
};
