import React, { useContext, useEffect, useRef } from "react";
import { View, Text, Platform } from "react-native";
import tw from "tailwind-rn";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Form } from "components/elements/forms";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { FormFieldPicker } from "components/elements/forms";
import { fonts, colors } from "styles/theme";
import QuillEditor from "components/elements/react-quill-editor";
import { SaveCancelButtons } from "components/wrappers";
import {
  selectSideCar,
  selectShopDetails,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import { useSelector } from "react-redux";
import { isValidJSON } from "library/utils/formatter";
import { setSideCar } from "library/sagas/views/home/drawer/shop-settings/common/slice";

const OrderInvoiceActions = ({ saveSettings, loadingKey }) => {
  const { messages, Localise } = useContext(I18NContext);
  const shopSettings = useSelector(selectShopPreferences);
  const shopDetails = useSelector(selectShopDetails);
  const reactQuillOrderInvoiceHeaderRef = useRef(null);
  const careInstructionsQuillRef = useRef(null);
  const [isSubmit, setIsSubmit] = useStateIfMounted(true);
  const dispatch = useDispatch();
  const sideCar = useSelector(selectSideCar);
  const [shopMessageData, setShopMessageData] = useStateIfMounted("");
  const [careInstructionsData, setCareInstructionsData] = useStateIfMounted("");
  const [error, setError] = useStateIfMounted("");
  const [careInsError, setCareInsError] = useStateIfMounted("");

  let orderInvoiceSettings =
    shopSettings["invoice_print_card_section3_settings"];
  let loadDefaultJDEFLoristContact = false;
  let floristShopContactSettings =
    shopSettings["florist_contact_information_settings"];
  if (!isValidJSON(orderInvoiceSettings) || orderInvoiceSettings == "") {
    orderInvoiceSettings =
      '{"shopMessage":"","careInstructions":"","storeAddressLocation":"","section3Content":"","section4Content":"","shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":""}';
  }

  if (
    (!isValidJSON(floristShopContactSettings) &&
      floristShopContactSettings === "JDE") ||
    floristShopContactSettings == ""
  ) {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
    loadDefaultJDEFLoristContact = true;
  }

  let {
    shopName: shopMessageContact = loadDefaultJDEFLoristContact
      ? shopDetails.businessName
      : shopName,
    streetAddress: streetAddressContact = loadDefaultJDEFLoristContact
      ? shopDetails.address.addressLine1
      : streetAddress,
    aptSuite: aptSuiteContact = aptSuite,
    city: cityContact = loadDefaultJDEFLoristContact
      ? shopDetails.address?.city
      : city,
    state: stateContact = loadDefaultJDEFLoristContact
      ? shopDetails.address?.state
      : state,
    zip: zipContact = loadDefaultJDEFLoristContact
      ? shopDetails.address?.zip
      : zip,
    phone: phoneContact = loadDefaultJDEFLoristContact
      ? shopDetails.phone
      : phone,
    email: emailContact = loadDefaultJDEFLoristContact
      ? shopDetails.email
      : email,
    printed = [],
  } = JSON.parse(floristShopContactSettings);

  if (!printed.includes("shopName")) {
    shopMessageContact = shopDetails.businessName;
  }
  if (!printed.includes("streetAddress")) {
    streetAddressContact = shopDetails.address.addressLine1;
    cityContact = shopDetails.address?.city;
    stateContact = shopDetails.address?.state;
    zipContact = shopDetails.address?.zip;
  }
  if (!printed.includes("phone")) {
    phoneContact = shopDetails.phone ? shopDetails.phone : "";
  }
  if (!printed.includes("email")) {
    emailContact = shopDetails.email ? shopDetails.email : "";
  }

  const defaultCareInstructions =
    "Care Instructions. For long–lasting blooms, replace the water daily. We suggest trimming the stems every couple days. Place your plants in full sun to partial shade. Water thoroughly when the surface is dry to the touch. Keep evenly moist, but avoid over-watering.";

  let {
    shopMessage = "",
    careInstructions = "",
    storeAddressLocation = "",
    section3Content = "",
    section4Content = "",
    shopName = "",
    streetAddress = "",
    aptSuite = "",
    city = "",
    state = "",
    zip = "",
    phone = "",
    email = "",
  } = JSON.parse(orderInvoiceSettings);

  careInstructions =
    careInstructions == "" ? defaultCareInstructions : careInstructions;

  const handleQuillEditorChange = (value) => {
    if (value !== shopMessageData) {
      setShopMessageData(value);
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
  };
  const handleCareInstQuillEditorChange = (value) => {
    if (value !== careInstructionsData) {
      setCareInstructionsData(value);
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
  };

  const onQuillEditorCancel = () => {
    setShopMessageData(shopMessage);
    setCareInstructionsData(careInstructions);
    setIsSubmit(true);
  };

  const handleQuillRef = (quill) => {
    if (quill) {
      reactQuillOrderInvoiceHeaderRef.current = quill.getEditor();
    }
  };
  const handleCareInstQuillRef = (quill) => {
    if (quill) {
      careInstructionsQuillRef.current = quill.getEditor();
    }
  };

  useEffect(() => {
    setShopMessageData(shopMessage);
    setCareInstructionsData(careInstructions);
  }, [shopMessage, careInstructions]);

  useEffect(() => {
    if (!isEmpty(sideCar) && sideCar.type === "order-invoice-card")
      getInvoicePreviewData();
  }, [shopDetails.memberCode]);

  const initValus = {
    shopMessage: shopMessage,
    careInstructions: careInstructions,
    storeAddressLocation: storeAddressLocation,
    section3Content: section3Content,
    section4Content: section4Content,
    shopName: shopMessageContact,
    streetAddress: streetAddressContact,
    aptSuite: aptSuiteContact,
    city: cityContact,
    state: stateContact,
    zip: zipContact,
    phone: phoneContact,
    email: emailContact,
  };

  const storeAddressLocationOptions = [
    { label: "Center", value: "Center" },
    {
      label: "Left",
      value: "Left",
    },
    {
      label: "Do not Print",
      value: "doNotPrint",
    },
  ];
  const sectionContentOptions = [
    { label: "Shop Message", value: "shopMessage" },
    { label: "Shop Information", value: "shopInformation" },
    { label: "Care Instructions", value: "careInstructions" },
    { label: "Blank", value: "blank" },
  ];

  const saveOrderInvoiceInfo = (values) => {
    const {
      storeAddressLocation = "",
      section3Content = "",
      section4Content = "",
      shopName = "",
      streetAddress = "",
      aptSuite = "",
      city = "",
      state = "",
      zip = "",
      phone = "",
      email = "",
    } = values;

    const saveFloristContSettings = {
      shopMessage: shopMessageData,
      careInstructions: careInstructionsData,
      storeAddressLocation,
      section3Content,
      section4Content,
      shopName,
      streetAddress,
      aptSuite,
      city,
      state,
      zip,
      phone,
      email,
    };
    const payload = {
      preferences: [
        {
          id: "invoice_print_card_section3_settings",
          values: [JSON.stringify(saveFloristContSettings)],
        },
      ],
    };
    saveSettings(payload);
    setIsSubmit(true);
  };

  const modules = {
    toolbar: [
      [{ font: ["arial", "georgia", "impact", "verdana", "tahoma", "times"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
    ],
  };

  const getInvoicePreviewData = () => {
    const shopData =
      initValus.shopName +
      "\n" +
      initValus.streetAddress +
      ", " +
      initValus.aptSuite +
      "\n" +
      initValus.city +
      " ," +
      initValus.state +
      " ," +
      initValus.zip +
      "\n" +
      initValus.phone +
      "\n" +
      initValus.email;
    const invoicePreviewData = {
      orderInvocieSection3Content: "",
      orderInvocieSection4Content: "",
    };
    if (initValus.section3Content == "shopMessage") {
      invoicePreviewData["orderInvocieSection3Content"] = initValus.shopMessage;
    } else if (initValus.section3Content == "shopInformation") {
      invoicePreviewData["orderInvocieSection3Content"] = shopData;
    } else if (initValus.section3Content == "careInstructions") {
      invoicePreviewData["orderInvocieSection3Content"] =
        initValus.careInstructions;
    }
    if (initValus.section4Content == "shopMessage") {
      invoicePreviewData["orderInvocieSection4Content"] = initValus.shopMessage;
    } else if (initValus.section4Content == "shopInformation") {
      invoicePreviewData["orderInvocieSection4Content"] = shopData;
    } else if (initValus.section4Content == "careInstructions") {
      invoicePreviewData["orderInvocieSection4Content"] =
        initValus.careInstructions;
    }

    dispatch(
      setSideCar({
        type: "order-invoice-card",
        orderInvoiceData: invoicePreviewData,
      })
    );
  };

  return (
    <React.Fragment>
      {Platform.OS !== "web" && <View style={tw("my-2 flex-1")} />}
      <View style={{ ...tw("flex flex-row"), width: "100%" }}>
        <View style={{ width: "30%" }}>
          <Text style={[fonts.heading3, tw("mb-2")]}>
            {Localise(messages, "Order Invoices")}
          </Text>
        </View>
        <View style={{ width: "70%" }}>
          <Text
            style={[
              fonts.link1,
              tw("mb-4 flex flex-row-reverse"),
              { textAlign: "right" },
            ]}
            onPress={() => {
              getInvoicePreviewData();
            }}
          >
            {Localise(messages, "Preview Order Invoice Card")}
          </Text>
        </View>
      </View>
      <View style={tw("flex w-full flex-row")}>
        <View style={{ ...tw("flex flex-col"), width: "100%" }}>
          <Form
            initialValues={initValus}
            onSubmit={(values) => {
              saveOrderInvoiceInfo(values);
            }}
            render={() => {
              return (
                <View>
                  <View style={[tw("flex flex-row")]}>
                    <Text style={[fonts.heading5, tw("mb-3")]}>
                      {Localise(messages, "Shop Message")}
                    </Text>
                  </View>
                  <View style={[tw("flex flex-row"), { zIndex: 9999 }]}>
                    <View
                      style={[
                        tw("w-full"),
                        {
                          width: isEmpty(sideCar) ? 700 : "100%",
                        },
                        fonts.style1,
                      ]}
                    >
                      <QuillEditor
                        key="shopMessage"
                        testID="shopMessage"
                        accessibilityLabel="shopMessage"
                        placeholder={"Please Enter Shop Message here"}
                        value={shopMessageData}
                        onEditorChange={handleQuillEditorChange}
                        handleRef={handleQuillRef}
                        modules={modules}
                        error={error}
                        setError={setError}
                        maxLength={150}
                      />
                    </View>
                  </View>
                  <View style={tw("my-2")}></View>
                  <View style={[tw("flex flex-row")]}>
                    <Text style={[fonts.heading5, tw("mb-3")]}>
                      {Localise(messages, "Care Instructions")}
                    </Text>
                  </View>
                  <View style={[tw("flex flex-row")]}>
                    <View>
                      <Text style={[tw("mb-2"), { fontStyle: "italic" }]}>
                        {Localise(
                          messages,
                          "For sympathy orders, this section will include Customer Name and Address instead of instructions."
                        )}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: isEmpty(sideCar) ? 700 : "100%",
                      zIndex: 9998,
                    }}
                  >
                    <QuillEditor
                      key="careInstructions"
                      testID="careInstructions"
                      accessibilityLabel="careInstructions"
                      placeholder={"Please Enter Care Instructions here"}
                      value={careInstructionsData}
                      onEditorChange={handleCareInstQuillEditorChange}
                      handleRef={handleCareInstQuillRef}
                      modules={modules}
                      error={careInsError}
                      setError={setCareInsError}
                      maxLength={150}
                    />
                  </View>
                  <View style={tw("my-2")}></View>
                  <View style={tw("my-2")}>
                    <Text style={[fonts.heading5, tw("mb-3")]}>
                      {Localise(messages, "Store Address Location")}
                    </Text>
                  </View>
                  <View>
                    <FormFieldPicker
                      placeholder={{
                        label: "Select Store Address Location",
                        value: "",
                      }}
                      containerStyle={{ width: 200, paddingLeft: 0 }}
                      data={storeAddressLocationOptions}
                      testID="storeAddressLocation"
                      accessibilityLabel="storeAddressLocation"
                      name="storeAddressLocation"
                    />
                  </View>
                  <View style={tw("my-2")}></View>
                  <View style={tw("my-2")}>
                    <Text style={[fonts.heading5, tw("mb-2")]}>
                      {Localise(messages, "Card Layout")}
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: 400,
                    }}
                  >
                    <View style={[tw("flex flex-row w-full")]}>
                      <Text
                        style={{
                          borderWidth: 1,
                          borderColor: colors.grayScaleLight,
                          fontSize: "9",
                          textAlign: "center",
                          padding: 10,
                          width: 200,
                        }}
                      >
                        {Localise(messages, "Card Message ")}
                      </Text>

                      <Text
                        style={{
                          borderWidth: 1,
                          borderColor: colors.grayScaleLight,
                          fontSize: "9",
                          textAlign: "center",
                          padding: 10,
                          width: 200,
                        }}
                      >
                        {Localise(messages, "Delivery Address")}
                      </Text>
                    </View>

                    <View style={[tw("flex flex-row w-full")]}>
                      <Text
                        style={{
                          borderWidth: 1,
                          borderColor: colors.grayScaleLight,
                          fontSize: "9",
                          textAlign: "center",
                          width: 200,
                          padding: 10,
                        }}
                      >
                        {Localise(messages, "3")}
                      </Text>

                      <Text
                        style={{
                          borderWidth: 1,
                          borderColor: colors.grayScaleLight,
                          fontSize: "9",
                          textAlign: "center",
                          width: 200,
                          padding: 10,
                        }}
                      >
                        {Localise(messages, "4")}
                      </Text>
                    </View>
                  </View>
                  <View style={tw("my-2")}></View>
                  <View style={tw("my-2")}>
                    <Text style={[fonts.heading5, tw("mb-3")]}>
                      {Localise(messages, "Section 3 Content")}
                    </Text>
                  </View>
                  <View>
                    <FormFieldPicker
                      placeholder={{
                        label: "Select Section3 Content",
                        value: "",
                      }}
                      containerStyle={{ width: 200, paddingLeft: 0 }}
                      data={sectionContentOptions}
                      name="section3Content"
                      testID="section3Content"
                      accessibilityLabel="section3Content"
                    />
                  </View>
                  <View style={tw("my-2")}></View>
                  <View style={tw("my-2")}>
                    <Text style={[fonts.heading5, tw("mb-3")]}>
                      {Localise(messages, "Section 4 Content")}
                    </Text>
                  </View>
                  <View>
                    <FormFieldPicker
                      placeholder={{
                        label: "Select Section4 Content",
                        value: "",
                      }}
                      testID="section4Content"
                      accessibilityLabel="section4Content"
                      containerStyle={{ width: 200, paddingLeft: 0 }}
                      data={sectionContentOptions}
                      name="section4Content"
                    />
                  </View>
                  <SaveCancelButtons
                    buttonTitle={Localise(messages, "Save")}
                    showCancelAlways={!isSubmit}
                    disableOnDirty={isSubmit}
                    disableOnErrors={!!error || !!careInsError}
                    onCancel={onQuillEditorCancel}
                    cancelTestId="clear_order_invocie_form"
                    saveTestId="save_order_invocie"
                  />
                </View>
              );
            }}
          />
        </View>
      </View>
    </React.Fragment>
  );
};

export default OrderInvoiceActions;
