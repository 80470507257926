import { createStyles, minWidth } from "react-native-media-queries";
import { colors, backgroundColors } from "styles/theme";

export default createStyles(
  {
    mainContainer: {
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
      borderRadius: 3,
      marginBottom: 15,
    },
    preferenceTiles: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 10,
    },
    recordContainer: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.grayScaleLight,
      borderRadius: 3,
      minHeight: 40,
      marginVertical: 10,
      padding: 5,
    },
    record: {
      flexDirection: "column",
      justifyContent: "center",
      borderLeftWidth: 8,
      width: "100%",
      paddingVertical: 6,
      paddingLeft: 5,
    },
    Suspended: {
      borderColor: "#D94E4E",
    },
    Active: {
      borderColor: "#80C777",
    },
    red: {
      color: "#D94E4E",
    },
    suspendsHeader: {
      backgroundColor: backgroundColors.navBar,
      flexDirection: "row",
    },
    suspendsList: {
      padding: 8,
    },
  },
  minWidth(1100, {
    record: {
      justifyContent: "center",
      paddingVertical: 3,
      width: "100%",
      alignItems: "stretch",
    },
    suspendsList: {
      padding: 16,
    },
  }),
  minWidth(370, {
    suspendsList: {
      minWidth: 370,
    },
  })
);
