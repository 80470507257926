import React, { memo, useContext } from "react";
import { Text, Platform } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, minWidth } from "react-native-media-queries";
import { backgroundColors, colors } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { CustomModal } from "components/elements";
import { setLargeRouteModal } from "library/sagas/views/home/drawer/delivery/slice";
import { selectLargeRouteModalVisible } from "library/sagas/views/home/drawer/delivery/selector";
import { CFR_MIN_COUNT } from "./config";

export const LargeRouteModal = memo(() => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const { modalVisible } = useSelector(selectLargeRouteModalVisible) || {};

  const modalMessage = `Cannot optimize more than ${CFR_MIN_COUNT} stops. Contact your shop owner to enable the Advanced Route Optimization Add-On Feature for large route optimization.`;
  const modalContent = {
    content: (
      <Text
        style={[
          tw("p-4"),
          { fontSize: 15, color: colors.highlighter, textAlign: "center" },
        ]}
      >
        {Localise(messages, modalMessage)}
      </Text>
    ),
    buttons: [{ type: "primary", title: Localise(messages, "OK") }],
  };

  return (
    <>
      <CustomModal
        modalVisible={modalVisible}
        modalContent={modalContent}
        primaryhandler={() =>
          dispatch(setLargeRouteModal({ modalVisible: false }))
        }
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, width: "100%" },
        ]}
        modalStyle={styles.modalStyle}
      />
    </>
  );
});

const styles = createStyles(
  {
    modalStyle:
      Platform.OS !== "web"
        ? {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        : { width: "100%" },
  },
  minWidth(767, {
    modalStyle:
      Platform.OS !== "web"
        ? {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#00000070",
            color: "#FFFFFF",
          }
        : { width: "35%" },
  })
);
