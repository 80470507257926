import React, { memo, useContext } from "react";
import { View, Platform } from "react-native";
import { Text, Input } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { createStyles, minWidth } from "react-native-media-queries";
import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import { showOrderNumberModalSelector } from "library/sagas/views/home/drawer/delivery/selector";
import { setShowONAddtoRouteModal } from "library/sagas/views/home/drawer/delivery/slice";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { CustomModal, ToasterHandler } from "components/elements";

const AddStopByOrderId = ({ ordersList, setRouteMoveAction = () => {} }) => {
  const dispatch = useDispatch();
  const { isMobile } = useContext(DeviceContext);
  const { messages, Localise } = useContext(I18NContext);
  const showModal = useSelector(showOrderNumberModalSelector);
  const [orderNumber, setOrderNumber] = useStateIfMounted("");
  const [loading, setLoading] = useStateIfMounted(false);

  const closeModal = () => {
    dispatch(setShowONAddtoRouteModal(false));
    setOrderNumber("");
  };

  const searchOrder = () => {
    setLoading(true);
    const { ordersInRoute = [], nonRoutedOrders = [] } = ordersList;
    const searchPredicate = (order = {}) => {
      const { messageNumber = "", orderItemId = "" } = order;
      return [messageNumber.toLowerCase(), orderItemId.toLowerCase()].includes(
        orderNumber.toLowerCase()
      );
    };
    const isInNonRouted = nonRoutedOrders.some(searchPredicate);
    const isInRouted = ordersInRoute.some(searchPredicate);
    if (isInNonRouted) {
      const orderId = nonRoutedOrders.find(searchPredicate)?.orderItemId;
      setRouteMoveAction({ action: "add", id: orderId });
      ToasterHandler(
        "success",
        Localise(messages, "Order added to Route. Please proceed to save")
      );
    } else if (isInRouted) {
      ToasterHandler(
        "error",
        Localise(messages, "Order is already part of Route")
      );
    } else {
      ToasterHandler("error", Localise(messages, "Order not found. Adjust the filter to add an order from a different date or shop."));
    }
    setTimeout(() => {
      setLoading(false);
      closeModal();
    }, 0);
  };

  return (
    <CustomModal
      modalVisible={showModal}
      modalContent={{
        content: (
          <View style={[tw("flex flex-row flex-wrap items-center mt-3 mx-3")]}>
            <Text style={[fonts.heading4, styles.orderNumber]}>
              {Localise(messages, "Order Number")}:
            </Text>
            <Input
              style={{
                padding: 10,
                fontFamily: fonts.fontFamily.default,
                fontSize: 15,
                height: 35,
              }}
              onChange={(e) =>
                setOrderNumber(e.nativeEvent.text?.toUpperCase())
              }
              value={orderNumber}
              placeholder={"Enter Order Number"}
              testID="orderNumber_add_to_route"
              accessibilityLabel="orderNumber_add_to_route"
              containerStyle={{
                height: 35,
                marginLeft: 0,
                marginBottom: 10,
                ...(isMobile ? { width: "100%" } : { flex: 1 }),
              }}
              inputContainerStyle={{ height: 35 }}
              rightIcon={
                orderNumber?.length ? (
                  <Text
                    style={{ paddingRight: 5, fontWeight: "bold" }}
                    onPress={() => setOrderNumber("")}
                  >
                    X
                  </Text>
                ) : undefined
              }
            />
          </View>
        ),
        buttons: [
          { type: "secondary", title: Localise(messages, "Cancel") },
          ...(orderNumber?.length
            ? [
                {
                  type: "primary",
                  title: Localise(messages, "Add To Route"),
                  testID: "popup_add_to_route",
                  loader: loading,
                },
              ]
            : []),
        ],
      }}
      primaryhandler={searchOrder}
      secondaryhandler={closeModal}
      contentStyle={[
        tw("border border-black p-2"),
        {
          backgroundColor: backgroundColors.secondary,
          textAlign: "center",
          width: "100%",
        },
      ]}
      modalStyle={styles.modalStyle}
    />
  );
};

export default memo(AddStopByOrderId);

const mobileStyles = {
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  backgroundColor: "#00000070",
  color: "#FFFFFF",
};

const styles = createStyles(
  {
    modalStyle: Platform.OS !== "web" ? mobileStyles : { width: "100%" },
    orderNumber: {
      width: 120,
      fontSize: 15,
      marginBottom: 10,
      marginLeft: 5,
      textAlign: "left",
    },
  },
  minWidth(767, {
    modalStyle: Platform.OS !== "web" ? mobileStyles : { width: "45%" },
  }),
  minWidth(1300, {
    modalStyle: Platform.OS !== "web" ? mobileStyles : { width: "35%" },
  })
);
