import { Platform } from "react-native";
import messaging from "@react-native-firebase/messaging";
import PushNotificationIOS from "@react-native-community/push-notification-ios";
import { PERMISSIONS, check, request } from "react-native-permissions";

let PushNotification;
if (Platform.OS !== "web") {
  PushNotification = require("react-native-push-notification");
}

class FCMService {
  register = (onRegister, onNotification) => {
    // FCM Registration
    this.checkPermission(onRegister, onNotification);

    if (Platform.OS === "android" && PushNotification) {
      const channel_id = "mhq_notifications";

      PushNotification.channelExists(channel_id, function (exists) {
        if (!exists) {
          PushNotification.createChannel({
            channelId: channel_id, // (required)
            channelName: "Mercury HQ Notifications", // (required)
            channelDescription: "Mercury HQ Notifications channel", // (optional) default: undefined
            playSound: true, // (optional) default: true
            soundName: "default", // (optional)
            vibrate: true, // (optional) default: true
          });
        }
      });
    }
  };

  showLocalNotification = (id, title, message, data = {}, options = {}) => {
    if (PushNotification) {
      PushNotification.localNotification({
        ...this.buildNotificationPayload(id, data, options),
        title: title || "", // (optional)
        message: message || "", // (required)
        userInfo: {}, // (optional)
        playSound: true, // (optional) default: true
        soundName: "default", // (optional)
        userInteraction: false,
      });
    }
  };

  buildNotificationPayload = (id, data = {}, options = {}) => {
    return Platform.OS === "android"
      ? {
          id: id,
          channelId: "mhq_notifications",
          autoCancel: true, // (optional) default: true
          largeIcon: "ic_launcher", // (optional)
          smallIcon: "ic_notification", // (optional)
          vibrate: true, // (optional)
          vibration: 300, // vibration length in milliseconds
          priority: "high", // (optional)
          importance: options.importance || "high",
          data: data,
          color: "#114258",
        }
      : {
          alertAction: options.alertAction || "view",
          category: options.category || "",
          userInfo: {
            id: id,
            item: data,
          },
        };
  };

  clearAllNotifications = () => {
    if (PushNotification) {
      PushNotification.cancelAllLocalNotifications();
      PushNotification.removeAllDeliveredNotifications();
    }

    if (Platform.OS === "ios" && PushNotificationIOS) {
      PushNotificationIOS.removeAllDeliveredNotifications();
    }
  };

  removeAllDeliveredNotificationByID = (notificationID) => {
    if (PushNotification) {
      PushNotification.cancelLocalNotifications({ id: `${notificationID}` });
    }
  };

  checkPermission = async (onRegister, onNotification) => {
    try {
      // Check if the app is running on Android 13 (API Level 33) or higher
      const isAndroid13OrHigher =
        Platform.OS === "android" && (Platform.Version || 0) >= 33;

      if (isAndroid13OrHigher) {
        const postNotificationPermission =
          PERMISSIONS.ANDROID.POST_NOTIFICATIONS;
        const postNotificationStatus = await check(postNotificationPermission);
        console.log(
          "Post Notification Permission Status:",
          postNotificationStatus
        );

        // Handle undefined or unexpected values for postNotificationStatus
        if (!postNotificationStatus || postNotificationStatus !== "granted") {
          const permissionRequestResult = await request(
            postNotificationPermission
          );
          console.log("Permission Request Result:", permissionRequestResult);

          // Handle undefined or unexpected values for permissionRequestResult
          if (
            !permissionRequestResult ||
            permissionRequestResult !== "granted"
          ) {
            onRegister({
              status: messaging.AuthorizationStatus.DENIED,
              fcmToken: null,
            });
            return;
          }
        }
      }

      const authorizationStatus = await messaging().hasPermission();
      console.log("Authorization Status:", authorizationStatus); // Debugging log

      if (
        authorizationStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authorizationStatus === messaging.AuthorizationStatus.PROVISIONAL
      ) {
        this.getToken(onRegister, onNotification);
      } else if (authorizationStatus === messaging.AuthorizationStatus.DENIED) {
        onRegister({
          status: messaging.AuthorizationStatus.DENIED,
          fcmToken: null,
        });
      } else {
        const permissionGranted = await messaging().requestPermission({
          alert: true,
          sound: true,
          announcement: true,
          badge: true,
          provisional: false,
          carPlay: true,
        });

        if (
          !permissionGranted ||
          permissionGranted === messaging.AuthorizationStatus.DENIED
        ) {
          onRegister({
            status: messaging.AuthorizationStatus.DENIED,
            fcmToken: null,
          });
        } else {
          this.getToken(onRegister, onNotification);
        }
      }
    } catch (error) {
      console.error("Permission Check Error:", error); // Error logging for production issues
      onRegister({
        status: messaging.AuthorizationStatus.DENIED,
        fcmToken: null,
      });
    }
  };

  getToken = async (onRegister, onNotification) => {
    try {
      // After permission granted register device for sending remote notification
      await messaging().registerDeviceForRemoteMessages();
      messaging()
        .getToken()
        .then((fcmToken) => {
          if (fcmToken) {
            onRegister({
              status: messaging.AuthorizationStatus.AUTHORIZED,
              fcmToken,
            });
            this.createNotificationListeners(onRegister, onNotification);
          } else {
            onRegister({
              status: messaging.AuthorizationStatus.DENIED,
              fcmToken: null,
            });
          }
        })
        .catch((error) => {
          console.error("FCM Token Retrieval Error:", error);
          onRegister({
            status: messaging.AuthorizationStatus.DENIED,
            fcmToken: null,
          });
        });
    } catch (error) {
      console.error("Device Registration Error:", error);
      onRegister({
        status: messaging.AuthorizationStatus.DENIED,
        fcmToken: null,
      });
    }
  };

  getDeviceToken = async () => {
    const authorizationStatus = await messaging().hasPermission();

    if (authorizationStatus === messaging.AuthorizationStatus.DENIED) {
      return ""; // Need to enable notification permission in settings to receive notifications.
    } else {
      try {
        const fcmToken = await messaging().getToken();
        return fcmToken || "";
      } catch (error) {
        console.error("Get Device Token Error:", error);
        return "";
      }
    }
  };

  onNotificationOpenedListener = (onOpenNotification) => {
    messaging().onNotificationOpenedApp((remoteMessage) => {
      if (remoteMessage) {
        onOpenNotification(remoteMessage);
      }
    });

    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (remoteMessage && remoteMessage.notification) {
          onOpenNotification(remoteMessage.notification);
        }
      });

    if (PushNotification) {
      PushNotification.configure({
        onNotification: function (notification) {
          if (notification.foreground && notification.userInteraction) {
            onOpenNotification(notification);
          }
        },
      });
    }
  };

  createNotificationListeners = (onRegister, onNotification) => {
    messaging().onMessage(async (remoteMessage) => {
      if (remoteMessage) {
        const notification = remoteMessage.notification;
        onNotification(notification);
      }
    });

    messaging().onTokenRefresh((fcmToken) => {
      onRegister({
        status: messaging.AuthorizationStatus.AUTHORIZED,
        fcmToken,
      });
    });
  };

  unRegister = () => {
    if (PushNotification) {
      PushNotification.unregister();
    }
  };
}

export const fcmService = new FCMService();
