import React, { memo, useContext } from "react";
import { Text } from "react-native-elements";
import { Platform } from "react-native";
import { createStyles } from "react-native-media-queries";
import { CustomModal, ToasterHandler } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { colors, backgroundColors } from "styles/theme";
import { ERROR_MESSAGES } from "library/constants";

const ReopenModal = ({
  triggerAction,
  showReOpenModal,
  setShowReOpenModal,
  setFieldValue,
}) => {
  const { messages, Localise } = useContext(I18NContext);

  return (
    <CustomModal
      modalVisible={showReOpenModal}
      modalContent={{
        content: (
          <Text
            style={{
              ...tw("p-4"),
              fontSize: 15,
              color: colors.highlighter,
            }}
          >
            {Localise(
              messages,
              `Reopen this order? Status will change to 'Accepted,' allowing you to resume processing and fulfillment.`
            )}
          </Text>
        ),
        buttons: [
          { type: "secondary", title: Localise(messages, "Cancel") },
          { type: "primary", title: Localise(messages, "Continue") },
        ],
      }}
      primaryhandler={() => {
        triggerAction({
          action: "reopen",
          reject: () => {
            setShowReOpenModal(false);
            ToasterHandler(
              "uh oh",
              Localise(messages, ERROR_MESSAGES.GENERAL_ERROR)
            );
          },
        });
        setShowReOpenModal(false);
        setFieldValue("actionItem", "");
      }}
      secondaryhandler={() => {
        setShowReOpenModal(false);
        setFieldValue("actionItem", "");
      }}
      contentStyle={[
        tw("border border-black p-2"),
        {
          backgroundColor: backgroundColors.secondary,
          textAlign: "center",
        },
      ]}
      modalStyle={styles.modalStyle}
    />
  );
};

export default memo(ReopenModal);

const styles = createStyles({
  modalStyle:
    Platform.OS !== "web"
      ? {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      : { width: "35%" },
});
