import React from "react";
import RenderPicker from "./render";
import { View, Image } from "react-native";
import { Text } from "react-native-elements";
import { theme } from "styles/theme";

const Picker = ({
  items,
  errorMsg,
  label,
  labelFieldSet,
  placeholder,
  labelStyle,
  containerStyle,
  onValueChange,
  value,
  name,
  innerContainerStyle = {},
  disabled,
  pickerRef,
  customDisabledStyle = false,
  ...otherProps
}) => {
  return (
    <View style={[theme.Picker.containerStyle, containerStyle]}>
      {label ? (
        <View style={theme.Picker.labelFieldSetStyle}>
          <Text style={[theme.Picker.labelStyle, labelStyle]}>{label}</Text>
          {labelFieldSet}
        </View>
      ) : null}
      <View style={[theme.Picker.innerContainerStyle, innerContainerStyle]}>
        <RenderPicker
          placeholder={placeholder}
          useNativeAndroidPickerStyle={false}
          value={value}
          name={name}
          style={{
            ...theme.Picker.inputStyle,
            ...(disabled && customDisabledStyle
              ? theme.Picker.disabledInputStyle
              : {}),
            iconContainer: theme.Picker.iconContainerStyle,
          }}
          onValueChange={onValueChange}
          items={items}
          Icon={() => (
            <Image
              style={{
                width: 10,
                height: 10,
              }}
              source={require("static/assets/collapsed-section.png")}
            />
          )}
          disabled={disabled}
          pickerRef={pickerRef}
          {...otherProps}
        />
      </View>
      {errorMsg ? (
        <Text style={theme.Picker.errorStyle}>{errorMsg}</Text>
      ) : null}
    </View>
  );
};

export default Picker;
