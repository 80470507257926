import Storage from "library/utils/storage";
import get from "lodash/get";
import set from "lodash/set";
import { Platform } from "react-native";

const PageContextCache = new Storage("PageContextCache", {
  enableLocal: false,
  enableSession: Platform.OS === "web" ? true : false,
  throttleWait: 0,
});

const load = () => PageContextCache.load();

const clear = () => {
  PageContextCache.clear();
};

const clearPageData = (pageName) => PageContextCache.delete(pageName);

const setOrdersPageData = (value) => PageContextCache.put("Orders", value);

const getOrdersPageActions = (key) => {
  const data = PageContextCache.get("Orders") || {};
  if (!key) {
    return data;
  }
  return get(data, key, "");
};

const setOrdersPageActions = (key, value = "") => {
  const currentData = { ...getOrdersPageActions() };
  const updatedData = set(currentData, key, value);
  PageContextCache.put("Orders", updatedData);
};

const setNewOrdersPageActions = (key = "", value = {}) => {
  PageContextCache.put(key, value);
};

const getNewOrdersPageActions = (key = "") => {
  return PageContextCache.get(key) || {};
};

const setShowNewOrdersPage = (key = "", value = false) => {
  PageContextCache.put(key, value);
};

const getShowNewOrdersPage = (key = "") => {
  return PageContextCache.get(key) || false;
};

export default {
  clear,
  load,
  clearPageData,
  setOrdersPageData,
  setOrdersPageActions,
  getOrdersPageActions,
  setNewOrdersPageActions,
  getNewOrdersPageActions,
  setShowNewOrdersPage,
  getShowNewOrdersPage,
};
