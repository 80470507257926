import React, { useEffect, useRef } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Image,
  Platform,
} from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
  formatPrice,
  getMaskedPhysicalGiftCard,
  trimSpecialChars,
} from "library/utils/formatter";

import tw from "tailwind-rn";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import get from "lodash/get";
import isNil from "lodash/isNil";

import { theme, colors, fonts, shapes } from "styles/theme";

import IMAGES from "static/assets/images";

import {
  FormFieldPicker,
  FormFieldAutoComplete,
  FormField,
  FormFieldRemoveIcon,
} from "components/elements/forms";
import { ToasterHandler, Tooltip } from "components/elements";

import I18NContext from "library/contexts/i18N";
import AppSettingsContext from "library/contexts/appSettings";

import { request } from "library/utils/request";
import {
  giftCardProducts,
  digitalGiftCardProducts,
  physicalGiftCardProducts,
} from "library/utils/giftCardProducts";
import {
  processProductResponse,
  processAddonsResponse,
  filterFromList,
  getPrice,
  getDiscount,
  processQuickPicks,
} from "library/utils/createOrder";
import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  Entitlements,
  isCoreMember,
  isMHQNonCoreMember,
  isCoreConnectMember,
} from "library/utils/entitlements";
import Environment from "library/utils/environment";
import { isOrderTypeWired, isMolOrder } from "library/utils/payment-options";
import UserProfileStorage from "library/storage/userProfile";

import { getProductObj, getPriceObj } from "../helper";

import {
  fetchProduct,
  fetchMOLAddons,
  fetchlocalAddons,
} from "library/sagas/ongoing/global-data/slice";
import { selectIsEditOrder } from "library/sagas/views/home/drawer/create-order/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import {
  selectSelectedProducts,
  selectCurrentProduct,
  selectCurrentAddon,
} from "library/sagas/ongoing/global-data/selector";
import { CustomModal } from "components/elements";

const ITEM_MAX_QUANTITY = "99";
const MAX_ITEMS_PER_ORDER = 50;

const ProductInfo = ({
  isSmallScreen,
  index,
  title,
  orderInfo,
  setSideCarOpen,
  addons,
  sendingMemberCode,
  isFromSubscription = false,
  isAutoFill,
  canModifyOrder = true,
  isMultiOrderEligible,
  submitCount = 0,
}) => {
  const { values, setValues, setFieldValue, setFieldTouched } =
    useFormikContext();
  const dispatch = useDispatch();
  const { messages, Localise } = React.useContext(I18NContext);
  const { permissions } = React.useContext(AppSettingsContext);
  const isEditOrder = useSelector(selectIsEditOrder);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const selectedProducts = useSelector(selectSelectedProducts);
  const currentproduct = useSelector(selectCurrentProduct);
  const currentAddon = useSelector(selectCurrentAddon);
  const [products, setProducts] = useStateIfMounted([]);
  const [quickProducts, setQuickProducts] = useStateIfMounted([]);
  const [localAddOns, setLocalAddOns] = useStateIfMounted([]);
  const [productQuery, setProductQuery] = useStateIfMounted("");
  const [qtyWarning, setQtyWarning] = useStateIfMounted("");
  const productPricePath = `orderItems.${index}.price`;
  const productsCount =
    (isArray(orderInfo.lineItems) && orderInfo.lineItems.length) || 0;
  const orderHasProducts = productsCount > 0;
  const productInfoPath = `orderItems.${index}`;
  const path = `orderItems.${index}.lineItems`;
  const recipientInfoPath = `orderItems.${index}.recipientInfo`;

  const prevDeps = useRef([]);
  const scrollViewRef = useRef();
  const finalValues = cloneDeep(values);
  const [loading, setLoading] = useStateIfMounted(false);

  const orderSource = get(values, `${productInfoPath}.orderSource`, "");

  const isMHQNonCoreUser = isMHQNonCoreMember(sendingMemberCode);
  const isCoreUser = isCoreMember(sendingMemberCode);
  const isCoreConnectUser = isCoreConnectMember(sendingMemberCode);

  const selectedShopPermissions = get(permissions, sendingMemberCode, {});
  const hasFuneralLogEntitlement = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.FUNERAL_LOG);
  const showFuneralLog = Environment.get("SHOW_FUNERAL_LOG", false);

  const { gift_cards = "false" } =
    UserProfileStorage.getShopPreferences(sendingMemberCode);

  const isGiftCardsEnabled =
    Environment.get("GIFT_CARDS_ENABLED", false) &&
    gift_cards === "true" &&
    !isFromSubscription;

  const productFirstChoiceCode = get(
    values,
    `orderItems.${index}.lineItems.0.productFirstChoiceCode`,
    ""
  );

  const isProductGiftCard = giftCardProducts.includes(productFirstChoiceCode);

  const multiProductEligible = get(
    orderDetailsResponse,
    `orderItems.${index}.multiProductEligible`,
    true
  );

  const allowMultipleProducts = isEditOrder
    ? multiProductEligible
    : !isCoreUser && !isCoreConnectUser && !isFromSubscription;

  const [occasions, setOccasions] = useStateIfMounted([]);

  const selectedOccasion = get(
    values,
    `orderItems.${index}.deliveryInfo.occasion`,
    ""
  );

  const updateProductDetails = ({
    productName = "",
    index: productIndex,
    actualPrice,
    category,
  }) => {
    if (productName && orderHasProducts) {
      setTimeout(() => {
        setFieldValue(
          `orderItems.${index}.lineItems.${productIndex}.productFirstChoiceDescription`,
          `${productName}`
        );
        setFieldValue(
          `orderItems.${index}.lineItems.${productIndex}.suggestedRetailPrice`,
          `${actualPrice}`
        );
        setFieldValue(
          `orderItems.${index}.lineItems.${productIndex}.category`,
          `${category}`
        );
      }, 100);
    }
  };

  // Only product description and design notes is editable for outgoing wired orders having payment method; MSOL- 19803
  const totalDiscountAmount = get(
    orderDetailsResponse,
    `orderItems.0.totalDiscountAmount`,
    ""
  );
  const direction = get(orderDetailsResponse, `orderItems.0.direction`, "");
  const { paymentDetails = {}, orderType = "" } = orderDetailsResponse;

  const refundDetails =
    !isEmpty(paymentDetails) &&
    get(
      paymentDetails,
      `paymentMethod.0.paymentMethodDetails.refundDetails`,
      []
    );

  const isRefundOrDiscountApplied =
    totalDiscountAmount > 0 || refundDetails?.length > 0;

  const isOrderMOL = isMolOrder(orderSource);
  const isWiredOrder = isOrderTypeWired(orderType);
  const eligibleToEditProducts =
    isWiredOrder && !isOrderMOL && !isRefundOrDiscountApplied;
  const canModifyProducts = canModifyOrder || eligibleToEditProducts;
  const canEditDescriptionAndNotes =
    canModifyOrder || (isWiredOrder && !isOrderMOL);

  useEffect(() => {
    request("get-occasions", {}, undefined, true).then((res) => {
      if (res) {
        setOccasions(res.occasions);
      }
    });
    request("get-quickPicks").then((res) => {
      const products = processQuickPicks(res);
      setQuickProducts(products);
    });
  }, []);

  useEffect(() => {
    if (isProductGiftCard) {
      set(values, `orderItems.${index}.deliveryInfo.deliveryMethod`, "WALK_IN");
      set(values, `orderItems.${index}.deliveryMethod`, "WALK_IN");
    }
  }, [productFirstChoiceCode]);

  useEffect(() => {
    // if (productQuery.trim().length >= 3 && !orderHasProducts) {
    if (productQuery.trim().length >= 3 && canModifyProducts) {
      setLoading(true);
      request("get-local-addons", {
        query: productQuery,
        ...(isMHQNonCoreUser && {
          memberCode: sendingMemberCode,
          siteId: `ftd,${sendingMemberCode}`,
          includeOverrides: true,
        }),
      })
        .then((response) => {
          const addons = [
            ...processAddonsResponse(response && response?.productSummaries),
          ];
          setLocalAddOns(addons);
        })
        .catch((error) => {
          console.log("error: " + error);
          setLoading(false);
        });

      request("get-products", {
        query: productQuery,
        ...(isMHQNonCoreUser && {
          memberCode: sendingMemberCode,
          siteId: `fsg,${sendingMemberCode}`,
          includeOverrides: true,
        }),
      })
        .then((res) => {
          const products = [
            ...filterFromList(quickProducts, productQuery),
            ...processProductResponse(res && res.records),
          ];

          // Removing gift-card from search list for non-premium member and if products are already selected
          const formattedProductsList =
            !isGiftCardsEnabled || productFirstChoiceCode?.length > 0
              ? products.filter(
                  (eachProduct) => !giftCardProducts.includes(eachProduct?.pid)
                )
              : products;
          setProducts(formattedProductsList);
        })
        .finally(() => setLoading(false));
    } else {
      setProducts([]);
      setLocalAddOns([]);
    }
  }, [productQuery]);

  useEffect(() => {
    const productIndex = orderInfo.lineItems.findIndex(
      (prod) => prod.productFirstChoiceCode === currentproduct.productId
    );

    if (!isEmpty(currentproduct) && !!orderInfo.lineItems.length) {
      let product = cloneDeep(currentproduct);

      const prevProductDescription =
        orderInfo?.lineItems?.length > 0 &&
        orderInfo?.lineItems[productIndex]?.productFirstChoiceDescription;

      updateProductDetails({
        productName: prevProductDescription || product.productName,
        index: productIndex,
        actualPrice: 50 || 0,
        category: product.category,
      });
    }
  }, [currentproduct]);

  useEffect(() => {
    if (!isEmpty(currentAddon) && !!orderInfo.lineItems.length) {
      let addon = cloneDeep(currentAddon);
      const addonIndex = orderInfo.lineItems.findIndex(
        (prod) => prod.productFirstChoiceCode === currentAddon?.pid
      );
      setFieldValue(`${path}.${addonIndex}.category`, addon.category);
    }
  }, [currentAddon]);

  useEffect(() => {
    setProductQuery("");

    const lineItems = orderInfo?.lineItems || [];

    if (lineItems.length) {
      let updatedProducts = [...selectedProducts];
      // To load all products details like name, images etc. and MOL add-ons details (if any) when we did copy order
      lineItems.map((each, index) => {
        const {
          productFirstChoiceCode: productId,
          siteId = "",
          type = "Product",
          isImageLoaded = false,
          productFirstChoiceDescription = "",
        } = each;

        // Map over updatedProducts to update product names
        updatedProducts = updatedProducts.map((product) => {
          if (productId === product?.productId) {
            return {
              ...product,
              productName: productFirstChoiceDescription, // Update productName
            };
          }

          return product; // Return unchanged product if no match
        });

        // Find current product using a helper function to trim special characters
        const currentProduct = updatedProducts.find((product) => {
          const updatedProdId = trimSpecialChars(productId?.toLowerCase(), ".");
          return (
            trimSpecialChars(product.refNumberId?.toLowerCase()) ===
              updatedProdId ||
            trimSpecialChars(product.productId?.toLowerCase()) === updatedProdId
          );
        });

        if (currentProduct) {
          let product = cloneDeep(currentProduct);
          updateProductDetails({
            productName: product.productName,
            index: index,
            actualPrice: 50 || 0,
            category: product.category,
          });
        } else {
          if (!isImageLoaded) {
            if (type === "Product") {
              productId &&
                dispatch(
                  fetchProduct({
                    productId: productId,
                    siteId: sendingMemberCode,
                    orderSource: siteId === "fsg" ? "FLORIST" : "LOCAL",
                  })
                );
            } else {
              if (productId && isOrderMOL) {
                dispatch(
                  fetchMOLAddons({ accessoryId: productId, sendingMemberCode })
                );
              } else if (productId) {
                productId &&
                  dispatch(
                    fetchlocalAddons({
                      addonId: productId,
                      siteId: sendingMemberCode,
                      orderSource: siteId === "ftd" ? "FLORIST" : "LOCAL",
                    })
                  );
              }
            }
          }

          setFieldValue(`${path}.${index}.isImageLoaded`, true);
        }
      });

      prevDeps.current = cloneDeep(orderInfo.lineItems);
    }
  }, [orderInfo.lineItems.length]);

  useEffect(() => {
    if (qtyWarning.length > 0) {
      const timeId = setTimeout(() => {
        setQtyWarning("");
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [qtyWarning]);

  useEffect(() => {
    if (showFuneralLog && isMHQNonCoreUser && hasFuneralLogEntitlement) {
      selectedOccasion === "FUNERAL" && setSideCarOpen(`funeral_log`, {});
    }
  }, [selectedOccasion]);

  const data = allowMultipleProducts
    ? products.length > 0 || productQuery.length >= 3 || localAddOns.length > 0
      ? filterFromList([...products, ...addons, ...localAddOns], productQuery)
      : filterFromList(quickProducts, productQuery)
    : !orderHasProducts
    ? products.length > 0
      ? products
      : filterFromList(quickProducts, productQuery)
    : filterFromList([...addons, ...localAddOns], productQuery);

  return (
    <View>
      <View
        style={[
          {
            marginTop: 15,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>
          {loading && (
            <ActivityIndicator
              style={{ marginLeft: 5, marginTop: -5 }}
              color={colors.activityIndicator}
            />
          )}

          <TouchableOpacity
            style={{
              marginLeft: "auto",
              opacity: canModifyProducts && !isProductGiftCard ? 1 : 0.7,
              pointerEvents: canModifyProducts ? "auto" : "none",
            }}
            onPress={() => {
              canModifyProducts && !isProductGiftCard
                ? setSideCarOpen(`product_selection-${index}`, {})
                : null;
            }}
            testID="product_catalog"
            accessibilityLabel="product_catalog"
          >
            <Text style={{ ...fonts.link1, fontWeight: "400" }}>
              {Localise(messages, "Product Catalog")}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={[tw("flex flex-row flex-wrap")]}>
          <FormFieldPicker
            placeholder={{
              label: "Occasion",
            }}
            containerStyle={{
              width: isSmallScreen ? "100%" : "40%",
              pointerEvents: isAutoFill ? "none" : "auto",
            }}
            data={occasions}
            name="occasion"
            path={`orderItems.${index}.deliveryInfo`}
            disabledFieldTouch={true}
          />
          <FormFieldAutoComplete
            autoCapitalize="none"
            autoCorrect={false}
            name="productInfo"
            data={isProductGiftCard ? [] : canModifyProducts ? data : []}
            showOnFocus={true}
            onChangeText={(text) => {
              setProductQuery(text);
              text
                ? setFieldValue("isProductSearched", true)
                : setFieldValue("isProductSearched", false);
            }}
            placeholder={"Search and select product"}
            listDisplayValues={["refNumberId", "name", "price"]}
            outerContainerStyle={{
              zIndex: 1,
              width: isSmallScreen ? "100%" : "60%",
            }}
            path={productInfoPath}
            popperPlacement={"bottom"}
            editable={!isProductGiftCard && canModifyProducts}
            onSelect={(selectedValue, setFieldValue) => {
              const {
                pid,
                name,
                price,
                img = "",
                refNumberId,
                siteId = "",
                quantity = "1",
                type = "Product",
                category,
              } = selectedValue;

              const products = get(finalValues, path);

              // Identifier for the product search to trigger tax calls in the edit order flow.
              setFieldTouched("productInfo");

              if (
                allowMultipleProducts &&
                products.length === MAX_ITEMS_PER_ORDER
              ) {
                setQtyWarning(
                  Localise(
                    messages,
                    `You've reached the maximum items allowed for this order.`
                  )
                );
              } else {
                const newProduct = getProductObj(
                  pid,
                  name,
                  refNumberId,
                  img,
                  price,
                  quantity,
                  type,
                  siteId,
                  category
                );

                const updatedProducts = [...products, newProduct];
                set(finalValues, path, updatedProducts);
                const actualCustomerInfo = get(
                  finalValues,
                  "actualCustomerInfo"
                );

                if (!isNil(productPricePath)) {
                  const newPrice = getPriceObj(
                    price,
                    quantity,
                    actualCustomerInfo?.discountPercentage
                  );

                  const prices = get(finalValues, productPricePath);
                  const updatedPrices = [
                    ...prices,
                    {
                      ...newPrice,
                      ...(isEditOrder && {
                        newPrice: newPrice.discountedValue,
                      }),
                    },
                  ];
                  set(finalValues, productPricePath, updatedPrices);
                }
              }
              setFieldValue("");
              setValues(finalValues);
            }}
            showErrorOnTouched={true}
            submitCount={submitCount}
          />
        </View>

        {qtyWarning.length > 0 && (
          <View>
            <Text style={{ ...theme.Input.errorStyle, zIndex: -1 }}>
              {qtyWarning}
            </Text>
          </View>
        )}
      </View>

      {orderHasProducts && (
        <View
          style={{
            marginTop: 5,
            zIndex: -1,
            opacity: canModifyProducts || canEditDescriptionAndNotes ? 1 : 0.7,
          }}
          pointerEvents={
            canModifyProducts || canEditDescriptionAndNotes ? "auto" : "none"
          }
        >
          <ScrollView
            contentContainerStyle={
              (!isSmallScreen && tw("flex flex-row flex-wrap justify-start")) ||
              {}
            }
            horizontal={false}
            ref={scrollViewRef}
          >
            {orderInfo.lineItems.map((product, idx) => {
              const cartItems =
                orderInfo.lineItems.filter((each) => !each.isRemoved) || [];

              return (
                <ProductDetails
                  key={idx}
                  product={product}
                  productPath={path}
                  productPricePath={productPricePath}
                  idx={idx}
                  isLast={productsCount - 1 === idx}
                  isSmallScreen={isSmallScreen}
                  messages={messages}
                  Localise={Localise}
                  allowMultipleProducts={allowMultipleProducts}
                  productsCount={cartItems.length}
                  isProductGiftCard={isProductGiftCard}
                  recipientInfoPath={recipientInfoPath}
                  canModifyProducts={canModifyProducts}
                  canEditDescriptionAndNotes={canEditDescriptionAndNotes}
                  isWiredOrder={isWiredOrder}
                  direction={direction}
                />
              );
            })}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const Quantity = ({ path, messages, Localise, canModifyProducts }) => {
  const quantityOptions = [];
  const maxQuantity = ITEM_MAX_QUANTITY;

  for (var i = 1; i <= maxQuantity; i++) {
    quantityOptions.push({ label: i.toString(), value: i.toString() });
  }
  return (
    <View style={[tw("flex"), { marginTop: 5 }]}>
      <Text style={{ marginBottom: 5 }}>{Localise(messages, "Qty")}</Text>
      <FormFieldPicker
        name={`quantity`}
        containerStyle={{
          paddingHorizontal: 0,
          width: 85,
        }}
        placeHolderExists={false}
        data={quantityOptions}
        path={path}
        disabled={!canModifyProducts}
      />
    </View>
  );
};

const ProductDetails = ({
  product,
  idx,
  productPath,
  productPricePath,
  messages,
  Localise,
  allowMultipleProducts,
  isSmallScreen,
  productsCount,
  isProductGiftCard = false,
  recipientInfoPath,
  canModifyProducts,
  canEditDescriptionAndNotes,
  isWiredOrder = false,
  direction = "",
}) => {
  if (!product) return null;
  // eslint-disable-next-line
  const isEditOrder = useSelector(selectIsEditOrder);
  // eslint-disable-next-line
  const { values, setFieldValue } = useFormikContext();
  // eslint-disable-next-line
  const prevProducts = useSelector(selectSelectedProducts);
  // eslint-disable-next-line
  const [isAmtHigh, setIsAmtHigh] = useStateIfMounted(false);
  // eslint-disable-next-line
  const [giftAmount, setGiftAmount] = useStateIfMounted(0);
  // eslint-disable-next-line
  const amountRef = useRef(null);
  // eslint-disable-next-line
  const digitalCardRef = useRef(null);
  // eslint-disable-next-line
  const physicalCardRef = useRef(null);

  const {
    productFirstChoiceCode = "",
    productFirstChoiceRefNumberId = "",
    productFirstChoiceDescription = "",
    img = "",
    type = "",
    actualPrice,
    suggestedRetailPrice,
    isRemoved = false,
    canRemove = allowMultipleProducts,
    newlyAdded = false,
    discountedAmount = "",
    refundedAmount = "",
  } = product;

  const isDigitalGiftCard = digitalGiftCardProducts.includes(
    productFirstChoiceCode
  );

  const isPhysicalGiftCard = physicalGiftCardProducts.includes(
    productFirstChoiceCode
  );

  const currentProductImg =
    prevProducts?.find((each) => each?.productId === productFirstChoiceCode)
      ?.primaryImg || img;

  if (isRemoved) return null;

  const imgUri = currentProductImg.includes("http")
    ? currentProductImg
    : `https:${currentProductImg}`;

  const isProduct = type === "Product";
  const srPrice = getPrice(actualPrice) || suggestedRetailPrice;
  const showRemove = isEditOrder
    ? canRemove && canModifyProducts
      ? productsCount > 1
      : newlyAdded
    : true;
  const showDiscountApplied =
    isEditOrder &&
    discountedAmount > 0 &&
    isWiredOrder &&
    direction === "OUTBOUND";
  const showRefundApplied =
    isEditOrder &&
    refundedAmount > 0 &&
    isWiredOrder &&
    direction === "OUTBOUND";

  const modalContent = {
    content: (
      <Text
        style={{
          ...tw("p-4"),
          fontSize: 15,
          color: colors.highlighter,
          textAlign: "center",
        }}
      >
        {`${Localise(
          messages,
          "Please confirm Gift Card value of $"
        )}${giftAmount}`}
      </Text>
    ),
    buttons: [
      {
        type: "primary",
        title: Localise(messages, "Confirm"),
      },
      { type: "secondary", title: Localise(messages, "Change") },
    ],
  };

  const handleConfirm = () => {
    setIsAmtHigh(false);
    if (isDigitalGiftCard) {
      digitalCardRef.current.focus();
    } else {
      physicalCardRef.current.focus();
    }
  };

  const handleChange = () => {
    setIsAmtHigh(!isAmtHigh);
    if (isAmtHigh && amountRef.current) {
      amountRef.current.focus();
    }
  };

  return (
    <View
      key={idx}
      style={{
        ...shapes.sectionBorder,
        width: isSmallScreen ? "100%" : isProductGiftCard ? "100%" : 310,
        marginRight: 10,
        marginTop: 10,
      }}
    >
      <View
        style={[
          tw("flex mb-4"),
          { paddingBottom: isSmallScreen && isProductGiftCard ? 20 : 0 },
        ]}
      >
        <View
          testID={productFirstChoiceRefNumberId}
          style={[tw("flex flex-row justify-between")]}
        >
          {canModifyProducts || canEditDescriptionAndNotes ? (
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              name={"productFirstChoiceDescription"}
              placeholder="Product Description"
              containerStyle={{
                ...tw("mb-1"),
                paddingHorizontal: 0,
                width: 220,
              }}
              inputContainerStyle={{
                padding: 6,
              }}
              path={`${productPath}.${idx}`}
              errorStyle={{ paddingBottom: 0 }}
              inputStyle={{ ...fonts.sectionHeading }}
            />
          ) : (
            <Text
              style={[
                tw("mb-2"),
                fonts.sectionHeading,
                {
                  width: "90%",
                  minHeight: 32,
                },
              ]}
              numberOfLines={2}
            >
              {productFirstChoiceDescription}
            </Text>
          )}

          {showRemove && (
            <Tooltip
              text={Localise(messages, "Remove")}
              renderForWebOnly={true}
            >
              <FormFieldRemoveIcon
                name={`product_${idx}`}
                productPricePath={productPricePath}
                path={productPath}
                index={idx}
                canRemoveAnyItem={allowMultipleProducts}
                markAsRemove={isEditOrder}
                containerStyle={{
                  position: "absolute",
                  top: -7,
                  right: -7,
                  width: isProductGiftCard
                    ? isSmallScreen
                      ? "10%"
                      : "5%"
                    : "10%",
                }}
              />
            </Tooltip>
          )}
        </View>
        <Text style={{ paddingVertical: 2 }}>
          {`${Localise(
            messages,
            "Product Code"
          )}: ${productFirstChoiceRefNumberId}`}
        </Text>
        <View style={[tw("flex flex-row mt-2"), { height: 140 }]}>
          <Image
            style={{
              width: 130,
              height: 130,
              borderWidth: 1,
              borderColor: colors.light,
            }}
            resizeMode="cover"
            source={currentProductImg ? { uri: imgUri } : IMAGES[`quick-pick`]}
          />

          <View
            style={{
              marginLeft: isSmallScreen ? 10 : 30,
              marginBottom: 5,
            }}
          >
            <View
              style={{
                ...tw("flex flex-col"),
                height: 58,
                marginBottom: 5,
                ...(isProductGiftCard && {
                  paddingHorizontal: 5,
                }),
              }}
            >
              <Text style={{ marginBottom: 5 }}>
                {Localise(
                  messages,
                  !isProductGiftCard ? "Item Price" : "Amount"
                )}
              </Text>
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name={"value"}
                placeholder=""
                containerStyle={{
                  ...tw("mb-1"),
                  paddingHorizontal: 0,
                  width: 85,
                }}
                isPrice={true}
                keyboardType="numeric"
                iconType="material-community"
                iconName="currency-usd"
                iconSize={14}
                inputContainerStyle={{
                  padding: 6,
                }}
                leftIconContainerStyle={{ paddingRight: 0 }}
                path={`${productPricePath}.${idx}`}
                errorStyle={{ paddingBottom: 0 }}
                inputRef={amountRef}
                handleOnBlur={(updatedPrice = "") => {
                  const priceObj = get(values, `${productPricePath}.${idx}`);
                  const {
                    value = 0,
                    discount = 0,
                    discountType,
                  } = priceObj || {};
                  const quantity = get(
                    values,
                    `${productPath}.${idx}.quantity`
                  );
                  if (updatedPrice >= 1000) {
                    setIsAmtHigh(true);
                    setGiftAmount(updatedPrice);
                  } else {
                    setIsAmtHigh(false);
                  }
                  if (updatedPrice.length > 0 && updatedPrice >= 0) {
                    if (updatedPrice !== value) {
                      const productPrice = parseFloat(
                        formatPrice(getPrice(updatedPrice) * parseInt(quantity))
                      );
                      const productDiscount = getDiscount(
                        discount,
                        updatedPrice,
                        discountType,
                        quantity
                      );
                      const priceAfterDiscount = formatPrice(
                        getPrice(productPrice - productDiscount)
                      );

                      setFieldValue(
                        `${productPath}.${idx}.newPrice`,
                        priceAfterDiscount
                      );
                      setFieldValue(
                        `${productPath}.${idx}.updatedItemPrice`,
                        formatPrice(updatedPrice)
                      );
                    }
                  }
                }}
                editable={canModifyProducts}
                grayedOutOnDisable={!canModifyProducts}
              />
            </View>

            {isProduct && srPrice > 0 && !isProductGiftCard && (
              <Text
                style={{
                  marginTop: 7,
                  marginBottom: 5,
                }}
              >
                {Localise(messages, "SRP")} ${srPrice}
              </Text>
            )}

            {allowMultipleProducts && !isProductGiftCard && (
              <Quantity
                path={`${productPath}.${idx}`}
                messages={messages}
                Localise={Localise}
                canModifyProducts={canModifyProducts}
              />
            )}

            {isDigitalGiftCard && (
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name="email"
                path={recipientInfoPath}
                label={Localise(
                  messages,
                  "Who should receive the gift card email?"
                )}
                labelStyle={{ fontWeight: "normal" }}
                containerStyle={{
                  width: isSmallScreen ? "55%" : "150%",
                  marginTop: 10,
                }}
                errorStyle={{ paddingBottom: 0 }}
                placeholder={Localise(messages, "Recipient Email")}
                inputRef={digitalCardRef}
              />
            )}
            {isPhysicalGiftCard && (
              <FormField
                name="giftCardId"
                label={Localise(messages, "Gift Card #")}
                labelStyle={{ fontWeight: "normal" }}
                placeholder={Localise(messages, "Enter Gift Card Number")}
                fsClass="fs-exclude"
                containerStyle={{
                  width: isSmallScreen ? "55%" : "150%",
                  marginTop: 10,
                }}
                errorStyle={{ paddingBottom: 0 }}
                path={recipientInfoPath}
                iconName="credit-card"
                iconPosition={false}
                handleOnBlur={(gcNum) => {
                  const giftCardId = get(
                    values,
                    `orderItems.0.recipientInfo.giftCardId`,
                    ""
                  );
                  if (gcNum !== giftCardId) {
                    setFieldValue(
                      `${recipientInfoPath}.giftCardId`,
                      getMaskedPhysicalGiftCard(gcNum)
                    );
                    request("validate-physical-giftCard", {
                      requestPayload: { giftCardId: gcNum },
                      shopCode: values?.sendingMember,
                    })
                      .then((res) => {
                        set(values, `${productPath}.0.giftCardId`, res?.source);
                      })
                      .catch((error) => {
                        ToasterHandler("uh oh", Localise(messages, error));
                        setFieldValue(`${recipientInfoPath}.giftCardId`, "");
                      });
                  }
                }}
                inputRef={physicalCardRef}
              />
            )}
          </View>
        </View>
      </View>

      {!isProductGiftCard && (
        <FormField
          label={"Design Notes (optional)"}
          labelStyle={{ fontWeight: "normal" }}
          autoCapitalize="none"
          autoCorrect={false}
          name={`designNotes`}
          placeholder=""
          containerStyle={[tw("px-0")]}
          errorStyle={[tw("pb-0 m-0")]}
          path={`${productPath}.${idx}`}
          multiline={true}
          numberOfLines={2}
          editable={canEditDescriptionAndNotes}
        />
      )}
      {showDiscountApplied ? (
        <View style={tw("flex flex-row justify-between my-1 mt-2")}>
          <Text>{Localise(messages, "Discount Applied: ")}</Text>
          <Text
            style={{
              color: colors.red,
            }}
          >
            {`$${discountedAmount}`}
          </Text>
        </View>
      ) : (
        <></>
      )}
      {showRefundApplied ? (
        <View style={tw("flex flex-row justify-between my-1 mt-2")}>
          <Text>{Localise(messages, "Refund Applied: ")}</Text>
          <Text
            style={{
              color: colors.red,
            }}
          >
            {`$${refundedAmount}`}
          </Text>
        </View>
      ) : (
        <></>
      )}
      {isProductGiftCard && isAmtHigh && (
        <CustomModal
          modalVisible={isProductGiftCard}
          modalContent={modalContent}
          primaryhandler={() => handleConfirm()}
          secondaryhandler={() => handleChange()}
          contentStyle={[
            tw("border border-black p-4"),
            { backgroundColor: "white" },
          ]}
          modalStyle={
            Platform.OS !== "web" && {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: colors.secondary,
            }
          }
          isReduceBtnSpacing={true}
        ></CustomModal>
      )}
    </View>
  );
};

export default ProductInfo;
