import React from "react";
import { View } from "react-native";
import { Form, CustomFormField } from "components/elements/forms";
import {
  ContactSyncFormFields,
  getContactSyncFormSchema,
  getContactSyncSettings,
} from "./config";
import I18NContext from "library/contexts/i18N";
import { SaveCancelButtons } from "components/wrappers";
import SettingsHOC from "./settings-hoc";
import Environment from "library/utils/environment";

const ContactSyncForm = (props) => {
  const { preferences = [], saveSettings } = props;
  const { Localise, messages } = React.useContext(I18NContext);
  const initialValues = getContactSyncSettings(preferences);

  const handleSave = (values, formikBag) => {
    let preferences = [
      {
        id: "contact_sync_settings",
        values: [
          JSON.stringify({
            ctct_accountInfo: {
              userName: values.userName,
              accountId: "",
            },
            contactSync: values.contactSync,
          }),
        ],
      },
    ];
    saveSettings(preferences, "Contact Sync settings");
  };

  const renderFormContent = () => (
    <View
      style={{
        marginTop: 15,
        width: "100%",
        display: "flex",
      }}
    >
      {ContactSyncFormFields?.map((data, index) => {
        return <CustomFormField data={data} key={index} />;
      })}

      <SaveCancelButtons
        buttonTitle={Localise(messages, "Save")}
        disableOnDirty={true}
        customContainerStyles={{
          width: 100,
          marginLeft: 0,
        }}
        cancelTestId="cancel_contact_sync"
        saveTestId="save_contact_sync"
      />
    </View>
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        handleSave(values, formikBag);
      }}
      validationSchema={getContactSyncFormSchema(Localise, messages)}
      validateOnChange={false}
      validateOnBlur={false}
      render={() => renderFormContent()}
    />
  );
};

const ContactSyncSettings = (props) => {
  const showSettings = Environment.get("SHOW_CONTACT_SYNC_SETTINGS", false);
  if (!showSettings) return null;

  return (
    <SettingsHOC
      title={"Contact Sync Settings"}
      Child={ContactSyncForm}
      childProps={props}
    />
  );
};

export default ContactSyncSettings;
