import React, { useContext, memo } from "react";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { useFormikContext } from "formik";

import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";

import { Tooltip } from "components/elements";
import { FormFieldSwitch } from "components/elements/forms";

import { colors } from "styles/theme";
import tw from "tailwind-rn";

import { featureNames } from "../ui-config";
import { defaultAddonsEligibleRoles } from "components/views/drawer/shop-settings/addons/ui-config";

const FeatureToggle = ({
  featureName,
  settingsEditable,
  featureEnabled,
  setFeatureEnabled,
  featureEnabledInCurrentMonth,
  displayName,
  featurePricing,
  setFeatureConfirmModal,
  showFeatureLoader,
}) => {
  const { Localise, messages } = useContext(I18NContext);
  const { isDesktop } = useContext(DeviceContext);
  const { values, setFieldValue } = useFormikContext();

  const handleFeatureToggle = (val) => {
    setFeatureEnabled(val);
    setFieldValue("eligibleRoles", defaultAddonsEligibleRoles);
    let confirmMessage;
    if (val) {
      confirmMessage =
        featureName === featureNames.ADV_ROUTE_OPTIMISE
          ? `${Localise(messages, `By turning on`)} ${displayName}, ${Localise(
              messages,
              `you agree to a subscription fee of`
            )} ${featurePricing} ${Localise(
              messages,
              `for each optimization of routes with more than 25 stops`
            )}. ${Localise(
              messages,
              `Billing will occur monthly on your FTD statement.`
            )}`
          : `${Localise(messages, `By turning on`)} ${displayName}, ${Localise(
              messages,
              `you agree to a monthly subscription fee of`
            )} ${featurePricing}. ${Localise(
              messages,
              `This fee will be prorated based on the number of days the feature is active in a month.`
            )} ${Localise(
              messages,
              `Billing will occur monthly on your FTD statement.`
            )} \n\n ${Localise(
              messages,
              `Please note: This feature cannot be disabled within the same month it was activated in.`
            )}`;
    } else {
      confirmMessage = `Are you sure you want to turn off this feature?`;
    }

    setFeatureConfirmModal({
      values,
      showModal: true,
      message: confirmMessage,
      styles: val ? { width: "35%" } : {},
    });
  };

  return (
    <View
      style={[
        tw(`flex flex-row items-center mr-5 my-2`),
        {
          ...(Platform.OS == "web" &&
          (!settingsEditable || featureEnabledInCurrentMonth)
            ? { cursor: "not-allowed" }
            : { cursor: "pointer" }),
        },
      ]}
    >
      <Tooltip
        text={`${
          featureEnabledInCurrentMonth
            ? Localise(
                messages,
                "You cannot disable the feature in the same month you activate it."
              )
            : ``
        }`}
        height={90}
        isSmallScreen={!isDesktop}
      >
        <FormFieldSwitch
          value={featureEnabled}
          disabled={!settingsEditable || featureEnabledInCurrentMonth}
          onValueChange={handleFeatureToggle}
          testID={`${displayName}_setting_toggle`}
        />
      </Tooltip>
      <View style={{ paddingLeft: 20, maxWidth: 200 }}>
        <Text testID={`${displayName}_status_text`}>
          {Localise(messages, `Feature ${featureEnabled ? `On` : `Off`}`)}
        </Text>
      </View>
      {showFeatureLoader ? (
        <ActivityIndicator
          color={colors.activityIndicator}
          style={{ marginLeft: 10 }}
          testID={`${displayName}_loader`}
        />
      ) : null}
    </View>
  );
};

export default memo(FeatureToggle);
