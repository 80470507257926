import React from "react";
import { useFormikContext } from "formik";
import {
  FormField as FormTextField,
  FormFieldSwitch,
} from "components/elements/forms";
import { View } from "react-native";
import tw from "tailwind-rn";
import { Text } from "react-native-elements";
import { fonts } from "styles/theme";

const TextField = (props) => {
  const { title, name, isUpdateOnChange = false } = props;
  const placeholder = props.placeholder || `Enter ${title}`;

  return (
    <View
      style={{
        ...tw(`flex flex-row justify-start items-center pb-2`),
      }}
    >
      <Text
        style={{
          height: 40,
          width: "30%",
          ...fonts.heading5,
        }}
      >
        {title}
      </Text>
      <View
        style={[
          tw("flex flex-row justify-start items-center pb-2 flex-wrap"),
          { width: "70%" },
        ]}
      >
        <FormTextField
          name={name}
          placeholder={placeholder}
          isUpdateOnChange={isUpdateOnChange}
          testID={name}
        />
      </View>
    </View>
  );
};

const ToggleField = ({ name, title }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <View style={[tw(`flex flex-row justify-start items-center pb-3`)]}>
      <Text
        style={[
          fonts.heading5,
          {
            width: "30%",
          },
        ]}
      >
        {title}
      </Text>
      <View style={[{ paddingHorizontal: 5 }]}>
        <FormFieldSwitch
          name={name}
          value={values[name]}
          onValueChange={(val) => {
            setFieldValue(name, val);
          }}
          testID={name}
        />
      </View>
    </View>
  );
};

export const CustomFormField = ({ data = {} }) => {
  const { type, ...props } = data;

  switch (type) {
    case "Text":
      return <TextField {...props} />;
    case "Toggle":
      return <ToggleField {...props} />;
    default:
      return null;
  }
};

export default CustomFormField;
