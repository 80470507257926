import { createSlice } from "@reduxjs/toolkit";
import { Platform } from "react-native";
import { Entitlements, memberEntitlements } from "library/utils/entitlements";
import UserProfileStorage from "library/storage/userProfile";
import Environment from "library/utils/environment";

import { SLICE_NAME as sliceName } from "./constants";
import { SLICE_NAME as parentSlice } from "../constants";
import { setReset as setProductReset } from "library/sagas/views/home/drawer/product-catalog/product/slice";

import get from "lodash/get";
import set from "lodash/set";
import startCase from "lodash/startCase";
import {
  isEnabledForMember,
  hasBirthdayReminderFeature,
  hasAnniversaryReminderFeature,
  hasDesignCenterFeature,
  hasPromoCodeFeature,
} from "library/utils/featureAvailability";
import { addonSettingsEligibleRoles } from "components/views/drawer/shop-settings/addons/ui-config";

export const initialState = {
  status: "progress",
  activeTab: "",
  activeNotificationTab: "",
  activeAccordian: "",
  sideCar: {},
  sideCarTitle: "",
  tabs: [
    { key: "mercury-network", title: "Mercury Network" },
    { key: "shop-details", title: "Shop Details" },
    { key: "order-settings", title: "Orders" },
    { key: "delivery-pickup", title: "Delivery, Pickup & Fees" },
    { key: "payments", title: "Payments" },
    { key: "catalog-settings", title: "Catalog" },
    { key: "notifications", title: "Notifications" },
    { key: "addon", title: "Add-On Features" },
    { key: "marketing", title: "Marketing" },
  ],
  notificationTabs: [
    {
      key: "anniversary_occasion_notification_settings",
      title: "Anniversary Reminder",
    },
    {
      key: "birthday_occasion_notification_settings",
      title: "Birthday Reminder",
    },
    {
      key: "credit_card_saved_notification_settings",
      title: "Credit Card Saved",
    },
    {
      key: "order_dd_changed_notification_settings",
      title: "Delivery Date Changed",
    },
    {
      key: "designed_image_preview_notification_settings",
      title: "Design Preview",
    },
    {
      key: "email_ha_invoices_notification_settings",
      title: "Email House Account Invoice",
    },
    // {
    //   key: "onDemand_invoice_notification_settings",
    //   title: "Email Invoice",
    // },
    {
      key: "event_proposal_notification_settings",
      title: "Event Proposal",
    },
    {
      key: "gift_cards_notification_settings",
      title: "Gift Cards",
    },
    {
      key: "house_account_statement_notification_settings",
      title: "House Account Statement",
    },
    {
      key: "order_confirmation_notification_settings",
      title: "Order Confirmation",
    },
    { key: "order_delivered_notification_settings", title: "Order Delivered" },

    {
      key: "order_not_delivered_notification_settings",
      title: "Order Not Delivered",
    },
    {
      key: "order_ready_for_pickup_notification_settings",
      title: "Order Ready for Pickup",
    },
    { key: "order_started_notification_settings", title: "Order Started" },

    {
      key: "order_out_for_delivery_notification_settings",
      title: "Out for Delivery",
    },
    {
      key: "pay_later_notification_settings",
      title: "Pay Later",
    },
    { key: "order_pickedup_notification_settings", title: "Picked up" },
    {
      key: "pickup_confirmation_notification_settings",
      title: "Pickup Confirmation",
    },
    {
      key: "pick_up_date_changed_notification_settings",
      title: "Pickup Date Changed",
    },
    {
      key: "walkin_delivered_notification_settings",
      title: "Walkin Delivered",
    },
  ],
  shopCode: "",
  shopDetails: {},
  shopPreferences: {},
  shopLogo: {},
  shopGroupMembers: {},
  activeShopGroupMembers: {},
  shopHours: {},
  quickBooksAuthorizationStatus: false,
  notificationSettings: [],
  defaultOLCNotifications: [],
  openTaxAccordian: false,
  autoScroll: { value: false, scrollYTo: 0, screen: 1 },
  replyEmailVerificationStatus: "",
  fromEmailVerificationStatus: "",
  marketingEmail: "", //Used to show Reply Email Verification popup.
  promoCodesList: {},
};

const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state, action) => {
      state.status = "progress";
      state.tabs = initialState.tabs;
      state.notificationTabs = initialState.notificationTabs;

      // Verifying the permission for tabs based on entitlements
      const permissions = get(action, "payload.permissions", {});
      const selectedShopPermissions = get(permissions, state.shopCode, {});

      const entitlement = UserProfileStorage.getMemberEntitlement(
        state.shopCode
      );
      const showEventsPlanner = isEnabledForMember(
        "ENABLE_EVENTS_PLANNER",
        state.shopCode
      );
      const isElectronApp =
        Platform.OS === "web" &&
        document.getElementById("isElectronApp").value === "true";
      if (
        state.shopCode &&
        !(Entitlements.MERCURY_SETTINGS in selectedShopPermissions)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "mercury-network");
      }
      if (
        state.shopCode &&
        !(Entitlements.PRODUCT_CATALOG in selectedShopPermissions)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "catalog-settings");
      }
      if (entitlement === memberEntitlements.MERCURY_ONLINE) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "payments");
      }

      if (
        ![
          memberEntitlements.MHQ_STANDARD,
          memberEntitlements.MHQ_STANDARD_MERCURY_ONLINE,
          memberEntitlements.MHQ_PREMIUM,
          memberEntitlements.MHQ_PREMIUM_MERCURY_ONLINE,
          memberEntitlements.MHQ_ESSENTIALS,
          memberEntitlements.MHQ_ESSENTIALS_MERCURY_ONLINE,
        ].includes(entitlement)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "payments");
      }
      if (entitlement === memberEntitlements.DELIVERY_SERVICE) {
        state.tabs = state.tabs.filter((tab) =>
          ["order-settings", "delivery-pickup"].includes(tab.key)
        );
      }

      if (
        !isElectronApp &&
        [memberEntitlements.DELIVERY_SERVICE].includes(entitlement)
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "order-settings");
      }

      if (
        ![
          memberEntitlements.MHQ_STANDARD,
          memberEntitlements.MHQ_STANDARD_MERCURY_ONLINE,
          memberEntitlements.MHQ_PREMIUM,
          memberEntitlements.MHQ_PREMIUM_MERCURY_ONLINE,
          memberEntitlements.MHQ_ESSENTIALS,
          memberEntitlements.MHQ_ESSENTIALS_MERCURY_ONLINE,
        ].includes(entitlement) ||
        Platform.OS !== "web"
      ) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "notifications");
      }
      if (!showEventsPlanner) {
        state.notificationTabs = initialState.notificationTabs.filter(
          (subTab) => subTab.key !== "event_proposal_notification_settings"
        );
      }

      const showUploadDesignedImage = Environment.get(
        "SHOW_UPLOAD_DESIGNED_IMAGE",
        false
      );
      if (
        !hasDesignCenterFeature(permissions, state.shopCode) ||
        !showUploadDesignedImage
      ) {
        state.notificationTabs = state.notificationTabs.filter(
          (subTab) =>
            subTab.key !== "designed_image_preview_notification_settings"
        );
      }

      const isOccasionReminderEnabled =
        isEnabledForMember("ENABLE_OCCASION_REMINDER", state.shopCode) &&
        Entitlements.OCCASION_REMINDERS in selectedShopPermissions;

      if (!isOccasionReminderEnabled) {
        state.notificationTabs = state.notificationTabs.filter(
          (subTab) =>
            ![
              "birthday_occasion_notification_settings",
              "anniversary_occasion_notification_settings",
            ].includes(subTab.key)
        );
      } else {
        if (!hasBirthdayReminderFeature(permissions, state.shopCode)) {
          state.notificationTabs = state.notificationTabs.filter(
            (subTab) =>
              !["birthday_occasion_notification_settings"].includes(subTab.key)
          );
        }

        if (!hasAnniversaryReminderFeature(permissions, state.shopCode)) {
          state.notificationTabs = state.notificationTabs.filter(
            (subTab) =>
              !["anniversary_occasion_notification_settings"].includes(
                subTab.key
              )
          );
        }
      }

      const userRoles = UserProfileStorage.getUserRoles();
      const showAddonsSettings =
        Environment.get("SHOW_ADDONS_SETTINGS", false) &&
        userRoles.some((role) =>
          addonSettingsEligibleRoles.includes(startCase(role.toLowerCase()))
        ) &&
        Entitlements.ADDON_SUBSCRIPTIONS in selectedShopPermissions;
      if (!showAddonsSettings) {
        /* Hide Addon Tab if Addons not enabled from [configMap, permission] */
        state.tabs = state.tabs.filter((tab) => tab.key !== "addon");
      }

      const hasMarketingTab = hasPromoCodeFeature(permissions, state.shopCode);
      const showPromoCode = Environment.get("SHOW_PROMO_CODE_FEATURE", false);

      if (!hasMarketingTab || !showPromoCode) {
        state.tabs = state.tabs.filter((tab) => tab.key !== "marketing");
      }

      if (!state.tabs.find((e) => e.key === state.activeTab)) {
        state.activeTab = state.tabs[0].key;
      }
      if (
        !state.notificationTabs.find(
          (e) => e.key === state.activeNotificationTab
        )
      ) {
        state.activeNotificationTab = state.notificationTabs[0].key;
      }
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setSuccess: (state) => {
      state.status = "done";
    },
    setFailure: (state) => {
      state.status = "fail";
    },
    setApiResponse: (state, action) => {
      const { path, content, isPatch, contentType } = action.payload;
      if (isPatch) {
        if (contentType === "object") {
          const prevContent = get(state, `${path}.content`, {});
          set(state, `${path}.content`, {
            ...prevContent,
            ...content,
          });
        } else {
          const prevContent = get(state, `${path}.content`, []);
          set(state, `${path}.content`, [...prevContent, ...content]);
        }
      } else {
        set(state, `${path}.content`, content);
      }
    },
    setApiError: (state, action) => {
      const { path, error } = action.payload;
      set(state, `${path}.error`, error);
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.activeAccordian = "";
      state.sideCar = {};
    },
    setTaxAccordian: (state, action) => {
      state.openTaxAccordian = action.payload;
    },
    setActiveAccordion: (state, action) => {
      state.activeAccordian = action.payload;
    },
    setactiveNotificationTab: (state, action) => {
      state.activeNotificationTab = action.payload;
      state.sideCar = {};
    },
    setSideCar: (state, action) => {
      state.sideCar = action.payload;
    },
    setSideCarTitle: (state, action) => {
      state.sideCarTitle = action.payload;
    },
    setShopCode: (state, action) => {
      state.shopCode = action.payload;
    },
    setNotificationSettings: (state, action) => {
      state.notificationSettings = action.payload;
    },
    setDefaultOLCNotifications: (state, action) => {
      state.defaultOLCNotifications = action.payload;
    },
    setAutoScroll: (state, action) => {
      state.autoScroll = { ...action.payload };
    },
    setPromoCodesList: (state, action) => {
      state.promoCodesList = action.payload;
    },
    fetchShopHours: () => {},
    saveShopHours: (state) => {
      state.shopHours.error = "";
    },
    fetchLogoImages: () => {},
    setShopLogoData: (state, action) => {
      state.shopLogo = action.payload;
    },
    saveLogoImages: () => {},
    fetchShopSettings: () => {},
    fetchShopPaymentSettings: () => {},
    fetchDefaultNotifications: () => {},
    fetchQuickBooksAuthorizationStatus: () => {},
    setQuickBooksAuthorizationStatus(state, action) {
      state.quickBooksAuthorizationStatus = action.payload;
    },

    fetchShopGroupMembers: () => {},
    saveShopGroupMembers: (state, action) => {
      state.shopGroupMembers = { ...action.payload };
    },
    saveActiveShopGroupMembers: (state, action) => {
      state.activeShopGroupMembers = [...action.payload];
    },
    saveShopSettings: () => {},
    allShopsUpdateShopSettings: () => {},
    getTerminalActivationCodes: () => {},

    setMarketingEmail: (state, action) => {
      state.marketingEmail = action.payload;
    },
    setReplyEmailVerificationStatus: (state, action) => {
      state.replyEmailVerificationStatus = action.payload;
    },
    setFromEmailVerificationStatus: (state, action) => {
      state.fromEmailVerificationStatus = action.payload;
    },
    fetchMarketingEmails: () => {},
    addMarketingEmail: () => {},
    savePromoCode: () => {},
    fetchPromoCodes: () => {},
    updatePromoCode: () => {},
    deletePromoCode: () => {},
    getShopSettings: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setProductReset, (state) => {
      state.shopCode = "";
    });
  },
});

export const {
  setInit,
  setReset,
  setSuccess,
  setFailure,
  setApiResponse,
  setApiError,
  setActiveTab,
  setactiveNotificationTab,
  setActiveAccordion,
  setSideCar,
  setSideCarTitle,
  setShopCode,
  setNotificationSettings,
  setDefaultOLCNotifications,
  setAutoScroll,
  saveShopHours,
  setTaxAccordian,
  fetchShopHours,
  fetchShopSettings,
  fetchShopPaymentSettings,
  fetchDefaultNotifications,
  fetchShopGroupMembers,
  saveShopGroupMembers,
  saveActiveShopGroupMembers,
  saveShopSettings,
  allShopsUpdateShopSettings,
  setShopLogoData,
  saveLogoImages,
  fetchQuickBooksAuthorizationStatus,
  setQuickBooksAuthorizationStatus,
  fetchLogoImages,
  getTerminalActivationCodes,
  setReplyEmailVerificationStatus,
  setFromEmailVerificationStatus,
  setMarketingEmail,
  fetchMarketingEmails,
  addMarketingEmail,
  setPromoCodesList,
  savePromoCode,
  fetchPromoCodes,
  updatePromoCode,
  deletePromoCode,
  getShopSettings,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
