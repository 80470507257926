import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { View, Text } from "react-native";
import tw from "tailwind-rn";
import { FormField, FormFieldCheckBox } from "components/elements/forms";
import { SaveCancelButtons } from "components/wrappers";
import isEmpty from "lodash/isEmpty";
import { EmailVerification } from "../../notifications/email-verification";
import Environment from "library/utils/environment";
import I18NContext from "library/contexts/i18N";
import { fonts } from "styles/theme";
import { Form } from "components/elements/forms";

import { addMarketingEmail } from "library/sagas/views/home/drawer/shop-settings/common/slice";
import {
  selectShopCode,
  selectSideCar,
  selectShopDetails,
  selectShopPreferences,
} from "library/sagas/views/home/drawer/shop-settings/common/selector";
import {
  isValidJSON,
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";
import LabelWithHelpIcon from "components/wrappers/label-with-help-icon";
import { activateOccasionReminder } from "../../notifications/email-settings";
import { hasBirthdayReminderFeature } from "library/utils/featureAvailability";
import { AppSettingsContext } from "library/contexts/appSettings";
import {
  resetOccasionReminderSettings,
  isOccasionReminderOn,
} from "../../helper";

const ContactInformationActions = ({ saveSettings }) => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const { permissions } = useContext(AppSettingsContext);

  const shopCode = useSelector(selectShopCode);
  const shopSettings = useSelector(selectShopPreferences);
  const shopDetails = useSelector(selectShopDetails);
  const sideCar = useSelector(selectSideCar);
  const marketingEmailRef = useRef(null);

  let floristShopContactSettings =
    shopSettings["florist_contact_information_settings"];
  let displayContactInfo = shopSettings["florist_display_contact_info"];
  let loadDefaultJDEFLoristContact = false;

  if (
    (!isValidJSON(floristShopContactSettings) &&
      floristShopContactSettings === "JDE") ||
    floristShopContactSettings == ""
  ) {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
    loadDefaultJDEFLoristContact = true;
  }

  const showMarketingEmail = hasBirthdayReminderFeature(permissions, shopCode);

  const marketingFields = showMarketingEmail
    ? { marketingEmail: "Marketing Email" }
    : {};

  const floristContactFields = {
    shopName: "Shop Name",
    streetAddress: "Street Address",
    aptSuite: "Apt/Suite/Unit",
    city: "City",
    state: "State",
    zip: "Zip",
    phone: "Phone",
    email: "Email",
    ...marketingFields,
    website: "Website",
    contactName: "Contact Name",
    printed: [],
    web: [],
    network: [],
  };

  let {
    shopName = "",
    streetAddress = "",
    aptSuite = "",
    city = "",
    state = "",
    zip = "",
    phone = "",
    email = "",
    marketingEmail = "",
    website = "",
    additionalMessage = "",
    contactName = "",
    printed = [],
    web = [],
    network = [],
    ctctAccountId = "",
  } = JSON.parse(floristShopContactSettings);

  const displayContact = displayContactInfo.split(",");
  const initValus = {
    shopName: loadDefaultJDEFLoristContact
      ? shopDetails.businessName
      : shopName,
    streetAddress: loadDefaultJDEFLoristContact
      ? shopDetails.address.addressLine1
      : streetAddress,
    aptSuite: aptSuite,
    city: loadDefaultJDEFLoristContact ? shopDetails.address?.city : city,
    state: loadDefaultJDEFLoristContact ? shopDetails.address?.state : state,
    zip: loadDefaultJDEFLoristContact ? shopDetails.address?.zip : zip,
    phone: loadDefaultJDEFLoristContact
      ? phoneNumberFormatter(shopDetails.phone)
      : phoneNumberFormatter(phone),
    email: loadDefaultJDEFLoristContact ? shopDetails.email : email,
    marketingEmail: loadDefaultJDEFLoristContact
      ? shopDetails.marketingEmail
      : marketingEmail,
    ctctAccountId,
    website: website,
    additionalMessage: additionalMessage,
    contactName: loadDefaultJDEFLoristContact
      ? shopDetails.contactName
      : contactName,
    printed: printed,
    web: web,
    network: network,
    shopNamePrinted: printed.includes("shopName") ? true : false,
    shopNameWebsite: web.includes("shopName") ? true : false,
    shopNameNetwork: displayContact.includes("shopName") ? true : false,

    streetAddressPrinted: printed.includes("streetAddress") ? true : false,
    streetAddressWebsite: web.includes("streetAddress") ? true : false,

    phonePrinted: printed.includes("phone") ? true : false,
    phoneWebsite: web.includes("phone") ? true : false,
    phoneNetwork: displayContact.includes("Phone") ? true : false,

    emailPrinted: printed.includes("email") ? true : false,
    emailWebsite: web.includes("email") ? true : false,
    emailNetwork: displayContact.includes("Email") ? true : false,

    websitePrinted: printed.includes("website") ? true : false,
    websiteWebsite: web.includes("website") ? true : false,
    websiteNetwork: network.includes("website") ? true : false,

    additionalMessagePrinted: printed.includes("additionalMessage")
      ? true
      : false,
    additionalMessageWebsite: web.includes("additionalMessage") ? true : false,
    contactNameNetwork: displayContact.includes("Name") ? true : false,
  };

  const saveFloristContactInfo = async (values) => {
    let {
      shopName = "",
      streetAddress = "",
      aptSuite = "",
      city = "",
      state = "",
      zip = "",
      phone = "",
      email = "",
      marketingEmail = "",
      website = "",
      additionalMessage = "",
      contactName = "",
      printed = [],
      web = [],
      network = [],
    } = values;

    //We are overriding existing accountId (as we are chaning different patters in test phase). In prod, it wont.
    let ctctAccountId = values.ctctAccountId;
    if (showMarketingEmail && marketingEmail) {
      const accountId = await activateOccasionReminder({ shopCode });
      if (accountId) {
        ctctAccountId = accountId;

        //Add from Email to the account.
        dispatch(
          addMarketingEmail({
            email: Environment.get(
              "MARKETING_FROM_EMAIL",
              "florist@ema.floristmail.com"
            ),
            accountId: ctctAccountId,
            isFromEmail: true,
          })
        );
      }
    }

    printed = [];
    web = [];
    network = [];

    displayContactInfo = [];

    if (values["shopNamePrinted"] === true) {
      printed.push("shopName");
    }
    if (values["shopNameWebsite"] === true) {
      web.push("shopName");
    }
    if (values["shopNameNetwork"] === true) {
      network.push("shopName");
      displayContactInfo.push("shopName");
    }

    if (values["streetAddressPrinted"] === true) {
      printed.push("streetAddress");
    }
    if (values["streetAddressWebsite"] === true) {
      web.push("streetAddress");
    }

    if (values["phonePrinted"] === true) {
      printed.push("phone");
    }
    if (values["phoneWebsite"] === true) {
      web.push("phone");
    }
    if (values["phoneNetwork"] === true) {
      network.push("phone");
      displayContactInfo.push("Phone");
    }

    if (values["emailPrinted"] === true) {
      printed.push("email");
    }
    if (values["emailWebsite"] === true) {
      web.push("email");
    }
    if (values["emailNetwork"] === true) {
      network.push("email");
      displayContactInfo.push("Email");
    }

    if (values["websitePrinted"] === true) {
      printed.push("website");
    }
    if (values["websiteWebsite"] === true) {
      web.push("website");
    }
    if (values["websiteNetwork"] === true) {
      network.push("website");
    }

    if (values["additionalMessagePrinted"] === true) {
      printed.push("additionalMessage");
    }
    if (values["additionalMessageWebsite"] === true) {
      web.push("additionalMessage");
    }
    if (values["contactNameNetwork"] === true) {
      network.push("contactName");
      displayContactInfo.push("Name");
    }

    if (displayContactInfo == "") {
      displayContactInfo.push("EMPTY");
    }

    const saveFloristContSettings = {
      shopName,
      streetAddress,
      aptSuite,
      city,
      state,
      zip,
      phone: formatPhoneForPayload(phone),
      email,
      marketingEmail,
      ctctAccountId,
      website,
      additionalMessage,
      contactName,
      printed,
      web,
      network,
    };

    const updatedOccasionReminderSettings = !marketingEmail
      ? resetOccasionReminderSettings(shopSettings)
      : [];

    let payload = {
      preferences: [
        ...updatedOccasionReminderSettings,
        {
          id: "florist_contact_information_settings",
          values: [JSON.stringify(saveFloristContSettings)],
        },
        {
          id: "florist_display_contact_info",
          values: [`${displayContactInfo.join()}`],
        },
      ],
    };

    //Add ReplyTo Email to CTCT account.
    saveSettings(payload, true, false, async () => {
      if (!showMarketingEmail || !marketingEmail) return;
      if (initValus.marketingEmail !== marketingEmail) {
        dispatch(
          addMarketingEmail({
            email: marketingEmail,
            accountId: ctctAccountId,
          })
        );
      }
    });
  };

  const floristContactInformationSchema = Yup.object().shape({
    shopName: Yup.string().required(
      Localise(messages, "Please enter Shop Name")
    ),
    streetAddress: Yup.string().required(
      Localise(messages, "Please enter Street Address")
    ),
    city: Yup.string().required(Localise(messages, "Please enter City")),
    state: Yup.string().required(Localise(messages, "Please enter State")),
    zip: Yup.string().required(Localise(messages, "Please enter Zip Code")),
    phone: Yup.string()
      .required(Localise(messages, "Please enter Phone Number"))
      .matches(
        new RegExp(
          "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
        ),
        Localise(messages, "Please enter a valid phone number")
      ),
    email: Yup.string()
      .required(Localise(messages, "Please enter Email"))
      .matches(
        /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
        Localise(messages, "Invalid Email address")
      ),
    marketingEmail: Yup.string().matches(
      /^\w+[-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
      Localise(messages, "Invalid Email address")
    ),
  });

  const canSkip = (key) => {
    return ["aptSuite", "state", "zip", "marketingEmail"].includes(key);
  };

  return (
    <Form
      initialValues={initValus}
      validationSchema={floristContactInformationSchema}
      onSubmit={(values) => {
        saveFloristContactInfo(values);
      }}
      render={({ values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (marketingEmailRef?.current) {
            marketingEmailRef.current?.focus();
          }
        }, []);

        const showMarketingEmailRemovalWarning =
          !values.marketingEmail && isOccasionReminderOn(shopSettings);

        return (
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: isEmpty(sideCar) ? "50%" : "80%",
              }}
            >
              <Text style={[fonts.heading3, tw("mb-2")]}>
                {Localise(messages, "Contact Information")}
              </Text>
              <Text>
                {Localise(
                  messages,
                  "Edit and select the information you would like to display on printed materials and to the Network."
                )}
              </Text>
              <Text style={[tw("mb-3")]}>
                {Localise(
                  messages,
                  "Edits here do not change your FTD Billing Information."
                )}
              </Text>
              <View style={[tw("flex flex-row")]}>
                <View style={{ width: "60%" }}></View>
                <View style={{ width: "20%" }}></View>
                <View style={{ width: "10%" }}>
                  <Text
                    style={{
                      fontWeight: "500",
                      minWidth: 55,
                    }}
                  >
                    {Localise(messages, "Printed")}
                  </Text>
                </View>
                <View style={{ width: "10%" }}>
                  <Text
                    style={{
                      fontWeight: "500",
                      minWidth: 55,
                    }}
                  >
                    {Localise(messages, "Network")}
                  </Text>
                </View>
              </View>
              {Object.entries(floristContactFields).map(([key, value]) => {
                const isMarketingField = key === "marketingEmail";
                if (!canSkip(key) && key === "streetAddress") {
                  return (
                    <View key={key} style={[tw("flex flex-row")]}>
                      <FormField
                        name="streetAddress"
                        testID="streetAddress"
                        accessibilityLabel="streetAddress"
                        label={Localise(messages, "Street Address")}
                        containerStyle={{ width: "40%" }}
                      />
                      <FormField
                        name="aptSuite"
                        testID="streetAddress"
                        accessibilityLabel="streetAddress"
                        label={Localise(messages, "Apt / Suite")}
                        containerStyle={{ width: "20%" }}
                      />
                      <View style={{ width: "20%" }}></View>
                      <FormFieldCheckBox
                        iconRight={false}
                        testID={`${key}_Printed`}
                        accessibilityLabel={key + "Printed"}
                        name={key + "Printed"}
                        size={20}
                        title={""}
                        isChecked={printed.includes(key) ? true : false}
                        containerStyle={{
                          width: "10%",
                          ...tw("flex justify-center"),
                        }}
                        errorStyle={{ paddingBottom: 0 }}
                      />
                    </View>
                  );
                } else if (!canSkip(key) && key === "city") {
                  return (
                    <View key={key} style={[tw("flex flex-row")]}>
                      <FormField
                        name="city"
                        testID="city"
                        accessibilityLabel="city"
                        label={Localise(messages, "City")}
                        containerStyle={{ width: "30%" }}
                      />
                      <FormField
                        name="state"
                        testID="State"
                        accessibilityLabel="State"
                        label={Localise(messages, "State")}
                        containerStyle={{ width: "15%" }}
                      />
                      <FormField
                        name="zip"
                        testID="zip"
                        accessibilityLabel="zip"
                        label={Localise(messages, "zip")}
                        containerStyle={{ width: "15%" }}
                      />
                    </View>
                  );
                } else {
                  if (
                    key != "printed" &&
                    key != "web" &&
                    key != "network" &&
                    key != "aptSuite" &&
                    key != "state" &&
                    key != "zip"
                  ) {
                    return (
                      <React.Fragment>
                        {isMarketingField ? (
                          <LabelWithHelpIcon
                            labelText={value}
                            tooltipText={Localise(
                              messages,
                              'The marketing email is the "Reply To" address to send the reminders'
                            )}
                          />
                        ) : null}
                        <View key={key} style={[tw("flex flex-row")]}>
                          <FormField
                            name={key}
                            testID={key}
                            accessibilityLabel={key}
                            label={isMarketingField ? "" : value}
                            containerStyle={{ width: "60%" }}
                            inputRef={
                              isMarketingField ? marketingEmailRef : null
                            }
                            isUpdateOnChange={true}
                          />
                          <View style={{ width: "20%" }}></View>
                          {!["contactName", "marketingEmail"].includes(key) && (
                            <FormFieldCheckBox
                              iconRight={false}
                              name={key + "Printed"}
                              testID={`${key}_Printed`}
                              accessibilityLabel={key + "Printed"}
                              size={20}
                              title={""}
                              isChecked={printed.includes(key) ? true : false}
                              containerStyle={{
                                width: "10%",
                                ...tw("flex justify-center"),
                              }}
                              errorStyle={{ paddingBottom: 0 }}
                            />
                          )}
                          {key == "contactName" && (
                            <View style={{ width: "10%" }}></View>
                          )}
                          {![
                            "additionalMessage",
                            "website",
                            "marketingEmail",
                          ].includes(key) && (
                            <FormFieldCheckBox
                              iconRight={false}
                              name={key + "Network"}
                              testID={`${key}_Network`}
                              accessibilityLabel={key + "Network"}
                              size={20}
                              title={""}
                              isChecked={network.includes(key) ? true : false}
                              containerStyle={{
                                width: "10%",
                                ...tw("flex justify-center"),
                              }}
                              errorStyle={{ paddingBottom: 0 }}
                            />
                          )}
                        </View>
                      </React.Fragment>
                    );
                  }
                }
              })}
            </View>
            <EmailVerification />
            <SaveCancelButtons
              buttonTitle={Localise(messages, "Save")}
              cancelTestId="clear_contact_information_form"
              saveTestId="save_contact_information"
              showWarningOnSave={showMarketingEmailRemovalWarning}
              warningText={`Removing marketing emails will also turn off Birthday and Anniversary reminders. Cancel will not save contact information.`}
            />
          </View>
        );
      }}
    />
  );
};

export default ContactInformationActions;
