import { roundTheFees } from "library/sagas/views/home/drawer/shop-settings/delivery-pickup/sections/fee-coverage";

export const RulesMapping = {
  ADDITIONAL_FEE: "additionalFeeInfo",
  DO_NOT_DELIVER: "doNotDeliverInfo",
  IN_STORE_PICKUP_ONLY: "inStorePickUpInfo",
  DO_NOT_SHIP: "donotshipInfo",
  OVERWRITE_DELIVERY_FEE: "overWriteDeliveryFeeInfo",
};

export const FormFieldProps = {
  autoCapitalize: "none",
  autoCorrect: false,
  placeholder: "",
  iconName: "currency-usd",
  iconType: "material-community",
  iconSize: 14,
  containerStyle: {
    width: 110,
  },
  inputStyle: {
    fontSize: 13,
    maxWidth: 50,
  },
  leftIconContainerStyle: {
    paddingRight: 0,
  },
  inputContainerStyle: {
    backgroundColor: "#fff",
    padding: 5,
    height: 35,
  },
  keyboardType: "numeric",
  transformText: roundTheFees,
};

const GlobalFeesFieldProps = {
  formFieldType: "Text",
  formFieldProps: {
    ...FormFieldProps,
  },
};

const GlobalFeesSwitchProps = {
  labelOn: "Taxable",
  labelOff: "Not Taxable",
};

export const FeesFields = (isDeliveryPickupTab, Localise, messages) => [
  ...(isDeliveryPickupTab
    ? [
        {
          name: "localFee",
          title: "Local Delivery Fee",
          ...GlobalFeesFieldProps,
          customOnChange: true,
          customOnChangeTitle: "Cities & Zip Codes",
          children: [
            {
              name: "localDeliveryFeeTax",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "local_delivery_fee_tax_toggle",
                accessibilityLabel: "local_delivery_fee_tax_toggle",
                ...GlobalFeesSwitchProps,
              },
            },
          ],
        },
        {
          name: "outsideLocalFee",
          title: "Outside Local Delivery Fee",
          ...GlobalFeesFieldProps,
          children: [
            {
              name: "outsideLocalDeliveryFeeTax",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "outside_local_delivery_fee_tax_toggle",
                accessibilityLabel: "outside_local_delivery_fee_tax_toggle",
                ...GlobalFeesSwitchProps,
              },
            },
          ],
        },
        {
          name: "serviceFee",
          title: "Service Fee",
          ...GlobalFeesFieldProps,
          children: [
            {
              name: "serviceFeeTax",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "service_fee_tax_toggle",
                accessibilityLabel: "service_fee_tax_toggle",
                ...GlobalFeesSwitchProps,
              },
            },
          ],
        },
        {
          name: "relayFee",
          title: "Relay Fee",
          ...GlobalFeesFieldProps,
          children: [
            {
              name: "relayFeeTax",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "relay_fee_tax_toggle",
                accessibilityLabel: "relay_fee_tax_toggle",
                ...GlobalFeesSwitchProps,
              },
            },
          ],
        },
        {
          name: "enableRetailDeliveryFee",
          title: "Retail Delivery Fee",
          formFieldType: "Switch",
          formFieldProps: {
            testID: "enableRetailDeliveryFee",
            accessibilityLabel: "enableRetailDeliveryFee",
            labelOn: "Active",
            labelOff: "Inactive",
          },
        },
      ]
    : []),
  {
    name: "rushDeliveryInfos",
    title: "Rush Delivery Fee",
    ...GlobalFeesFieldProps,
    timePickerProps: {
      timeIntervals: 30,
      iconName: "clock",
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeCaption: Localise(messages, "Time"),
      alignIconRight: false,
      dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
      containerStyle: { width: 140 },
      dateFormat: "h:mm a",
      popperPlacement: "top",
    },
    children: [
      {
        name: "enableRushFee",
        formFieldType: "Switch",
        formFieldProps: {
          testID: "enableRushFee",
          accessibilityLabel: "enableRushFee",
          labelOn: "Active",
          labelOff: "Inactive",
        },
      },
    ],
  },
  // {
  //   name: "enableRushFee",
  //   title: "Rush Delivery Fee",
  //   formFieldType: "Switch",
  //   formFieldProps: {
  //     testID: "enableRushFee",
  //     accessibilityLabel: "enableRushFee",
  //     labelOn: "Active",
  //     labelOff: "Inactive",
  //   },
  // },
  // {
  //   name: "retailDeliveryFee",
  //   title: "Retail Delivery Fee",
  //   formFieldType: "Text",
  //   formFieldProps: {
  //     ...FormFieldProps,
  //     editable: false,
  //     grayedOutOnDisable: true,
  //   },
  // },
  // {
  //   name: "taxRetailDeliveryFee",
  //   title: "Charge Tax",
  //   formFieldType: "Switch",
  //   formFieldProps: {
  //     disabled: true,
  //   },
  // },
  ...(isDeliveryPickupTab
    ? [
        {
          name: "groundFees",
          title: Localise(messages, "Ground Shipping"),
          ...GlobalFeesFieldProps,
          children: [
            {
              name: "enableGroundFees",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "enableGroundFees",
                accessibilityLabel: "enableGroundFees",
                labelOn: "Active",
                labelOff: "Inactive",
              },
            },
          ],
        },
        {
          name: "expeditedFees",
          title: Localise(messages, "Expedited Shipping"),
          ...GlobalFeesFieldProps,
          children: [
            {
              name: "enableExpeditedFees",
              title: "",
              formFieldType: "Switch",
              formFieldProps: {
                testID: "enableExpeditedFees",
                accessibilityLabel: "enableExpeditedFees",
                labelOn: "Active",
                labelOff: "Inactive",
              },
            },
          ],
        },
        {
          name: "dailyCutoffTime",
          title: Localise(messages, "Shipping Daily Cut-off"),
          formFieldType: "timepicker",
          formFieldProps: {
            name: "dailyCutoffTime",
            testID: "daily_cutoff_time",
            accessibilityLabel: "daily_cutoff_time",
            title: Localise(messages, "Submitted By"),
            placeholder: Localise(messages, "Submitted By"),
            timeIntervals: 30,
            iconName: "clock",
            showTimeSelect: true,
            showTimeSelectOnly: true,
            timeCaption: Localise(messages, "Time"),
            alignIconRight: true,
            dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
            containerStyle: { width: 140, marginTop: 5 },
            errorStyle: {
              paddingVertical: 0,
              paddingBottom: 0,
              fontSize: 0,
              height: 0,
            },
            dateFormat: "h:mm a",
            popperPlacement: "top",
          },
        },
      ]
    : []),
];

export const NewCoverageAreaConfig = [
  {
    name: "countryId",
    title: "Country",
    formFieldProps: FormFieldProps,
    formFieldType: "Picker",
    label: "Select Country",
  },
  {
    name: "state",
    title: "State",
    formFieldProps: FormFieldProps,
    formFieldType: "Picker",
    label: "Select State",
  },
  {
    name: "city",
    title: "City",
    formFieldProps: FormFieldProps,
    formFieldType: "Picker",
    label: "Select City",
  },
  {
    name: "fee",
    title: "Fee",
    formFieldProps: FormFieldProps,
    formFieldType: "Text",
    customOnChange: true,
  },
  {
    name: "zipcodecoverage",
    title: "Zip Code(s)",
    formFieldProps: FormFieldProps,
    formFieldType: "ZipcodeFields",
  },
];

export const getExceptionsConfig = (Localise, messages, productType) => {
  return {
    criteriaDropdown: {
      placeholder: {
        label: Localise(messages, "Select Criteria"),
        value: "",
      },
      data: [
        {
          label: Localise(messages, "All Areas"),
          value: "ALL_AREAS",
        },
        {
          label: Localise(messages, "All Local"),
          value: "ALL_LOCAL",
        },
        {
          label: Localise(messages, "Outside Local"),
          value: "OUTSIDE_LOCAL",
        },
        {
          label: Localise(messages, "City/Zip Code"),
          value: "CITY_ZIP_CODE",
        },
        {
          label: Localise(messages, "Address"),
          value: "ADDRESS",
        },
      ],
    },
    cityDropdown: {
      placeholder: {
        label: Localise(messages, "Select City"),
        value: "",
      },
    },
    ruleDropdown: {
      placeholder: {
        label: Localise(messages, "Select Rule"),
        value: "",
      },
      data: [
        {
          label: Localise(messages, "Do Not Deliver"),
          value: "DO_NOT_DELIVER",
        },
        {
          label: Localise(messages, "Additional Fee"),
          value: "ADDITIONAL_FEE",
        },
        {
          label: Localise(messages, "In-Store Pickup Only"),
          value: "IN_STORE_PICKUP_ONLY",
        },
        ...(productType === "dropship"
          ? [
              {
                label: Localise(messages, "Do Not Ship"),
                value: "DO_NOT_SHIP",
              },
            ]
          : []),
        {
          label: Localise(messages, "Overwrite Delivery Fee"),
          value: "OVERWRITE_DELIVERY_FEE",
        },
      ],
    },
    productTypeDropdown: {
      placeholder: {
        label: Localise(messages, "Select Product Type"),
        value: "",
      },
      data: [
        { label: "Florist Delivered", value: "floral" },
        { label: "Dropship", value: "dropship" },
      ],
    },
  };
};
