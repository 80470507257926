import React, { memo, useMemo, useContext, useCallback } from "react";
import { Text, Image } from "react-native-elements";
import { View, Platform } from "react-native";
import { colors, fonts, backgroundColors, theme } from "styles/theme";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { getListOfRouteActions } from "./config";
import IMAGES from "static/assets/images";
import { Picker } from "components/elements";
import { handleActionPress } from "components/views/drawer/delivery/routes-dashboard/upsert-route/take-action/helper";
import AddStopToRoute from "./add-order";

const TouchableOpacity =
  Platform.OS === "web"
    ? require("react-native").TouchableOpacity
    : require("react-native-gesture-handler").TouchableOpacity;

//eslint-disable-next-line
const TakeAction = memo(
  ({
    loading = false,
    routeData = {},
    actionHandler,
    disableSave,
    isPastRoute,
    driverAssignment,
    localPermissions = {},
    CFROptimise,
    disableOptimize = false,
    selectedAction,
    setSelectedAction,
    isEditRoute,
    routeHasEdits,
    addStopAction = () => {},
    isAddStopFormOpen,
    setIsAddStopFormOpen,
    routeEditAllowed,
    setAutoScroll,
    addStopViewRef,
    setAddStopYCoordinate,
  }) => {
    const { messages, Localise } = useContext(I18NContext);
    const { isDesktop, isMobile } = useContext(DeviceContext);
    const isSmallScreen = !isDesktop;
    const {
      status = "DEFAULT",
      manifestPrinted = false,
      ordersCount,
    } = routeData;

    const routeActions = useMemo(
      () =>
        getListOfRouteActions({
          status,
          localPermissions,
          CFROptimise,
          isEditRoute,
        }) || [],
      [status, localPermissions, CFROptimise, isEditRoute]
    );

    const isActionEnabled = useCallback(
      ({ key, actionType } = {}) => {
        return !(actionType.includes("save")
          ? disableSave
          : ["OPTIMIZE"].includes(key)
          ? disableOptimize || loading
          : !driverAssignment || routeHasEdits
          ? ["PLANNED", "INPROGRESS", "COMPLETED"].includes(key)
          : false);
      },
      [disableSave, disableOptimize, driverAssignment, routeHasEdits, loading]
    );

    const showPrintManifest = useMemo(
      () => localPermissions.printManifest && ordersCount > 0,
      [localPermissions.printManifest, ordersCount]
    );

    if (!routeActions.length || isPastRoute) return null;

    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: colors.grayScaleLight,
          marginBottom: 10,
          paddingVertical: 5,
          paddingHorizontal: 10,
        }}
      >
        <View
          style={{
            ...tw("flex flex-row items-center justify-between"),
            paddingVertical: 5,
          }}
        >
          {/* Actions */}
          <View
            style={{
              ...tw("flex-row items-center flex-wrap"),
              width: showPrintManifest ? "90%" : "100%",
            }}
          >
            {!!routeActions.length && !isMobile && (
              <View style={tw("mr-2 mt-1")}>
                <Text
                  style={{
                    ...fonts.heading4,
                    color: backgroundColors.primary,
                  }}
                >
                  {Localise(messages, "Actions")}:
                </Text>
              </View>
            )}
            {!!routeActions.length && isMobile ? (
              <>
                <Picker
                  containerStyle={{
                    width: 155,
                    height: "auto",
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  items={routeActions
                    .filter((action) => action.key !== "PRINTROUTE")
                    .map((eachAction) => {
                      const { enabled = true } = eachAction;
                      const isEnabled = enabled && isActionEnabled(eachAction);
                      if (!isEnabled) return;
                      return {
                        label: eachAction.label,
                        ...(Platform.OS === "ios"
                          ? {
                              value: isEnabled
                                ? JSON.stringify(eachAction)
                                : null,
                            }
                          : { value: JSON.stringify(eachAction) }),
                        enabled: isEnabled,
                      };
                    })
                    .filter(Boolean)}
                  placeholder={{ label: "Actions", value: "" }}
                  value={selectedAction}
                  togglePickerOnValChange
                  onValueChange={(action = {}) => {
                    if (!action) return;
                    setSelectedAction(JSON.stringify(action));
                    actionHandler({
                      action: JSON.parse(action).key,
                      routeId: routeData.routeId,
                      actionType: JSON.parse(action).actionType,
                    });
                  }}
                />
              </>
            ) : (
              <>
                {routeActions
                  .filter((action) => action.key !== "PRINTROUTE")
                  .map((eachAction, index) => (
                    <View
                      key={index}
                      style={[
                        tw("flex flex-row justify-end"),
                        !isActionEnabled(eachAction) && {
                          opacity: 0.5,
                        },
                      ]}
                    >
                      <TouchableOpacity
                        testID={`${eachAction.label}_action`}
                        accessibilityLabel={`${eachAction.label}_action`}
                        onPress={() =>
                          handleActionPress(
                            eachAction,
                            actionHandler,
                            routeData,
                            isActionEnabled
                          )
                        }
                        disabled={!isActionEnabled(eachAction)}
                      >
                        <View
                          style={{
                            ...theme.Button.secondaryButtonStyle,
                            paddingVertical: 10,
                            paddingHorizontal: 10,
                            height: 35,
                            margin: 5,
                            ...(!isActionEnabled(eachAction) && {
                              backgroundColor: "#d3d3d380",
                            }),
                          }}
                        >
                          <Text
                            style={{
                              ...theme.Button.secondaryTitleStyle,
                              ...(!isActionEnabled(eachAction) && {
                                color: "#808080",
                              }),
                            }}
                          >
                            {Localise(messages, eachAction.label)}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  ))}
              </>
            )}

            {/* Add To Route By Order Number Modal */}
            {!loading && routeEditAllowed ? (
              <AddStopToRoute
                {...{
                  addStopAction,
                  localPermissions,
                  isAddStopFormOpen,
                  setIsAddStopFormOpen,
                  setAutoScroll,
                  addStopViewRef,
                  setAddStopYCoordinate,
                }}
              />
            ) : null}
          </View>

          {/* Print Route Manifest */}
          {showPrintManifest ? (
            <View
              style={{
                ...(isSmallScreen && { width: "10%" }),
                opacity: 1,
                alignItems: "flex-end",
              }}
            >
              {routeActions
                .filter((action) => action.key === "PRINTROUTE")
                .map((eachAction, index) => (
                  <TouchableOpacity
                    key={index}
                    activeOpacity={0.5}
                    onPress={() => {
                      actionHandler({
                        action: eachAction.key,
                        routeId: routeData.routeId,
                        actionType: eachAction.actionType,
                      });
                    }}
                    testID="print_route_manifest"
                    accessibilityLabel={
                      manifestPrinted
                        ? "manifest_printed"
                        : "manifest_not_printed"
                    }
                  >
                    <Image
                      style={{
                        width: 26,
                        height: 26,
                      }}
                      source={IMAGES[manifestPrinted ? "printAgain" : "print"]}
                    />
                  </TouchableOpacity>
                ))}
            </View>
          ) : null}
        </View>
      </View>
    );
  }
);

export default TakeAction;
